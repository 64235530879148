import React, { useEffect, useMemo, useState } from "react";
import BarChart from "../../components/BarChart";
import {
  dashboardInfo,
  dashboardInfoForTeam,
  getNotification,
  getNotification_admin,
  getNotification_employee,
  getTotalIncomeExpense,
  SendToSeenNotification,
} from "../../config/config";
import {
  adminKey,
  customerKey,
  empKey,
  subadmin,
} from "../../config/RoleConfig";
import ApiService from "../../services/ApiService";
import {
  getRole,
  getToken,
  isCust,
  isEmp,
  superAccess,
} from "../../services/useLocalStorage";
import Loader from "react-js-loader";
import toast from "react-hot-toast";
import {
  addDaysFromDays,
  GetDate,
  GetTime,
  parseDate,
  todayDate,
} from "../../services/DateAndTimeService";
import { defaultuserpic, FileUrl, host_name } from "../../config/file_url";
import { ReactSVG } from "react-svg";
import $ from "jquery";
import { image2svg, numberCounter } from "../../UtilsComponent/UtilFunctions";
import NumberCounter from "number-counter";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import {
  CustRoute,
  EmpRoute,
  FilesRoute,
  TodosRoute,
} from "../../config/RouteConfig";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthHeader } from "../../services/FormCommon";
import { restrictTo } from "../../config/CommonVariables";

const Dashboard = () => {
  ChartJS.register(ArcElement, Tooltip, Legend);

  const [loading, setLoading] = useState(false);
  const header = {
    Authorization: `Bearer ${getToken()}`,
    ["Content-Type"]: "application/json",
  };

  const navigate = useNavigate();
  const today = new Date();
  const formattedDate = today.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
 
  });

  const [cardData, setCardData] = useState({
    totalEmployee: 0,
    totalFiles: 0,
    totalCompleteProject: 0,
    totalClient: 0,
    totaltask:0,
    totalcompletedtask:0,
    totaltaskpercentage:0,
  });

  //    labels: ["Income", "Expense"],
  //    datasets: [{
  //        data: [82, 18], // Specify the data values array
  //        backgroundColor: ['#3D441F', '#887549'], // Add custom color background (Points and Fill)
  //    }]

  const [chartData, setChartData] = useState({
    labels: ["Income", "Expense"],
    datasets: [
      {
        labels: "Income & Expense",
        data: [1, 1],
        backgroundColor: ["#3D441F", "#887549"],
      },
    ],
  });

  const [notifications, setNotifications] = useState([]);
  const [todoNotification, setTodoNotification] = useState([]);
  const [todoNotification_forOthers, setTodoNotification_forOthers] = useState(
    []
  );
  const defaultUserImgPath = useMemo(() => `${host_name}/${defaultuserpic}`);

  const fetchChartData = async () => {
    try {
      const res = await axios({
        method: `${getTotalIncomeExpense.method}`,
        url: `${getTotalIncomeExpense.url}`,
        headers: AuthHeader(),
      });
      console.log("res---->", res.data);

      if (!res?.data?.error) {
        const payload = res?.data?.data;
        var total_income = payload?.total_income ? payload?.total_income : 0;
        var total_expense = payload?.total_expense ? payload?.total_expense : 0;

        setChartData((prevState) => {
          return {
            ...prevState,
            datasets: [
              {
                ...prevState?.datasets[0],
                data: [total_income, total_expense],
              },
            ],
          };
        });
      } else {
      }
    } catch (err) {
      console.log(err);
      toast.error(`${err?.response?.data?.message}`);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      // const res = await ApiService(null,0,header,dashboardInfo?.method,dashboardInfo?.url);

      let apiObj = {
        method: dashboardInfo.method,
        url: `${dashboardInfo.url}`,
      };

      if (isEmp()) {
        apiObj = {
          method: dashboardInfoForTeam.method,
          url: `${dashboardInfoForTeam.url}`,
        };
      }

      const res = await axios({
        method: apiObj.method,
        url: `${apiObj.url}`,
        headers: AuthHeader(),
      });

      if (!res?.data?.error) {
        const payload = res?.data?.data;
        if (payload) {
          setCardData({
            totalEmployee: payload?.total_employees,
            totalFiles: payload?.total_files,
            totalCompleteProject: payload?.total_completed_file,
            totalClient: payload?.total_clients,
            totalTask:payload?.total_tasks_made_today,
            totalCompletedTask:payload?.totalcompletedtask?? 0,
            totalTaskPercentage:payload?.totaltaskpercentage?? 0,
          });
        }
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(`${res.data?.message}`);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.error(`${err?.response?.data?.message}`);
    }
  };

  const notification = async () => {
    try {
      const res = await ApiService(
        null,
        0,
        header,
        getNotification.method,
        getNotification.url
      );
      if (!res?.data?.error) {
        const data1 = res?.data?.data;
        if (Array.isArray(data1) && data1?.length) {
          const arr = [];
          for (let d of data1) {
            if (validJson(d?.json_data)) {
              const obj = {
                id: d?.id,
                name: d?.name,
                json_data: JSON.parse(d?.json_data),
                profileImage: defaultUserImgPath,
                assign_to: d?.assign_to,
                office_file_no: d?.office_file_no,
                file_name: d?.file_name,
                code: d?.code,
                is_seen: d?.is_seen,
                seen_at: d?.seen_at,
                created_by: d?.created_by,
                created_at: d?.created_at,
              };
              arr.push(obj);
            }
          }
          setNotifications(arr);
        }
      } else {
      }
    } catch (err) {
      console.log(err);
      toast.error(`${err?.response?.data?.message}`);
    }
  };

  const notificationTodo = async () => {
    try {
      if (superAccess([adminKey, subadmin])) {
        const res = await ApiService(
          null,
          0,
          header,
          getNotification_admin.method,
          getNotification_admin.url
        );
        if (!res?.data?.error) {
          const data1 = res?.data?.data;
          if (Array.isArray(data1) && data1?.length) {
            const arr = [];
            for (let d of data1) {
              const data = {
                id: d?.id,
                json_data: validJson(d?.json_data)
                  ? JSON.parse(d?.json_data)
                  : {},
                table_name: d?.table_name,
                fullname: d?.fullname,
                child_id: d?.child_id,
                created_at: d?.created_at,
              };

              arr.push(data);
            }
            setTodoNotification(arr);
          }
        } else {
        }
      }

      if (isEmp()) {
        const res = await ApiService(
          null,
          0,
          header,
          getNotification_employee.method,
          getNotification_employee.url
        );
        if (!res?.data?.error) {
          const data1 = res?.data?.data;
          if (Array.isArray(data1) && data1?.length) {
            const arr = [];
            for (let d of data1) {
              const data = {
                id: d?.id,
                json_data: validJson(d?.json_data)
                  ? JSON.parse(d?.json_data)
                  : {},
                table_name: d?.table_name,
                child_id: d?.child_id,
                created_at: d?.created_at,
              };

              arr.push(data);
            }
            setTodoNotification_forOthers(arr);
          }
        } else {
        }
      }
    } catch (err) {
      console.log(err);
      toast.error(`${err?.response?.data?.message}`);
    }
  };

  const validJson = (jsonString) => {
    try {
      JSON.parse(jsonString);
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const seenNotification = (route, data = null, fireApi = false) => {
    if (route) {
      if (fireApi) {
        ApiService(
          data,
          0,
          header,
          SendToSeenNotification.method,
          SendToSeenNotification.url
        );
      }
      navigate(`${route}`);
    }
  };

  const checkData = () => {
    // console.log(`${addDaysFromDays(todayDate(),7)}`);
    console.log(
      "Date after 7 days " + parseDate(addDaysFromDays(todayDate(), 7))
    );
  };

  useEffect(() => {
    if (superAccess()) {
      fetchChartData();
    }
    fetchData();
    notification();
    if ([adminKey, empKey].includes(getRole())) {
      notificationTodo();
    }
    numberCounter();
    image2svg();
  }, []);

  const previewLoader = () => {
    return (
      <>
        <Loader
          type="bubble-scale"
          bgColor={"#000000"}
          title={""}
          color={"#000000"}
          size={25}
        />
      </>
    );
  };

  const previewNumWithCounter = (val = 0) => {
    try {
      return (
        <>
          <h2 className="counter h1 text-white mb-0">
            <NumberCounter end={val} start={0} />
          </h2>
        </>
      );
    } catch (err) {
      console.log(err);
      return (
        <>
          <h2 className="counter h1 text-white mb-0">
            <NumberCounter end={0} start={0} />
          </h2>
        </>
      );
    }
  };

  console.log("craddafa==>",cardData.totalTask);

  return (
    <>
      <div className="content__wrapper">
        <section className="page-content">
          <div className="page-title mobile-title">
            <h1 className="h4 mb-4">Dashboard</h1>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-12">
                  <div className="revenue-chart card">
                    <div className="row  align-items-center">
                      <div className="col-md-6">
                        {/* <canvas id="revenueChart"></canvas> */}
                        <Doughnut data={chartData} />
                      </div>
                      <div className="col-md-6">
                        <ul className="chart-info-list list-unstyled">
                          <li className="chart-info-item d-flex">
                            <span className="info-bg  turtle-green d-block"></span>
                            <div className="chart-info-des ml-2">
                              <h5 className="mb-2">Income</h5>
                              <p className="mb-0 small">
                                {" "}
                                {superAccess()
                                  ? `Rs ${new Intl.NumberFormat("en-IN", {
                                      maximumSignificantDigits: 3,
                                    }).format(chartData?.datasets[0]?.data[0])}`
                                  : ""}{" "}
                              </p>
                            </div>
                          </li>
                          <li className="chart-info-item d-flex">
                            <span className="info-bg shadow-yellow d-block"></span>
                            <div className="chart-info-des ml-2">
                              <h5 className="mb-2">Expense</h5>
                              <p className="mb-0 small">
                                {superAccess()
                                  ? `Rs ${new Intl.NumberFormat("en-IN", {
                                      maximumSignificantDigits: 3,
                                    }).format(chartData?.datasets[0]?.data[1])}`
                                  : ""}
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div> 
                  </div>
                </div>
                <div className="col-lg-6 dashboard_first_box">
                  <div className="revenue-chart card">
                      <p className="percentage_text">{cardData.totalTaskPercentage}%</p>
                      <p className="number_text">{cardData.totalCompletedTask}/{cardData.totalTask}</p>
                      <p className="task_text">Task Done</p>
                      <p className="progress_text">Task Progress for{formattedDate}</p>
                  </div> 
                </div>
                <div className="col-lg-6 dashboard_first_box">
                  <div className="revenue-chart card justify-content-end"> 
                    <div>
                      <p className="large_text">{cardData.totalTask}</p>
                      <p className="task_text">Tasks Assigned</p>
                      <p className="progress_text">Assigned task on {formattedDate}</p>
                    </div>
                  </div> 
                </div>
              </div>
              {/* <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="dashboard__box card">
                                    <div className="task__progress d-flex justify-content-end">
                                        <div className="progress d-flex align-items-center justify-content-center" role="progressbar" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <div className="dashboard__box--details">
                                        <h5 className="mb-1">7/12</h5>
                                        <h5 className="mb-1">Task Done</h5>
                                        <p className="small mb-0">Task Progress for Oct 21</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="dashboard__box card">
                                    <div className="dashboard__box--icon text-right">
                                        <img src={`${FileUrl}/img/icons/activity.svg`} className="in__svg" alt="Activity"/>
                                    </div>
                                    <div className="dashboard__box--details">
                                        <h2 className="counter mb-0">12</h2>
                                        <h4 className="mb-0">Tasks Assigned</h4>
                                        <p className="small mb-0">Assigned task on Oct 21</p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="dashboard__stats card turtle-green">
                    <div className="stats__icon text-right">
                      <img
                        src={`${FileUrl}/img/icons/work.svg`}
                        className="in__svg"
                        alt="Work"
                      />
                    </div>
                    <div className="stats__details">
                      {/* <h2 className="counter h1 text-white mb-0">0</h2> */}
                      {!loading ? (
                        previewNumWithCounter(
                          parseInt(cardData.totalCompleteProject)
                        )
                      ) : (
                        <h2 className="counter h1 text-white mb-0">0</h2>
                      )}

                      <p className="text-white mb-0">Completed Projects</p>
                    </div>
                  </div>
                </div>
                {isEmp() ? (
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card dashboard__stats shadow-yellow">
                      <div className="stats__icon text-right">
                        <img
                          src={`${FileUrl}/img/icons/activity.svg`}
                          className="in__svg"
                          alt="Employees"
                        />
                      </div>
                      <div className="stats__details">
                        {!loading ? (
                          previewNumWithCounter(
                            parseInt(cardData.totalEmployee)
                          )
                        ) : (
                          <h2 className="counter h1 text-white mb-0">0</h2>
                        )}
                        <p className="text-white mb-0">Employees</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <Link to={`/${EmpRoute.list}`}>
                      <div className="card dashboard__stats shadow-yellow">
                        <div className="stats__icon text-right">
                          <img
                            src={`${FileUrl}/img/icons/activity.svg`}
                            className="in__svg"
                            alt="Employees"
                          />
                        </div>
                        <div className="stats__details">
                          {!loading ? (
                            previewNumWithCounter(
                              parseInt(cardData.totalEmployee)
                            )
                          ) : (
                            <h2 className="counter h1 text-white mb-0">0</h2>
                          )}
                          <p className="text-white mb-0">Employees</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                )}
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <Link to={`/${FilesRoute.listFileOnline}`}>
                    <div className="card dashboard__stats pine-tree-dark">
                      <div className="stats__icon text-right">
                        <img
                          src={`${FileUrl}/img/icons/folder.svg`}
                          className="in__svg"
                          alt="Employees"
                        />
                      </div>
                      <div className="stats__details">
                        {!loading ? (
                          previewNumWithCounter(parseInt(cardData.totalFiles))
                        ) : (
                          <h2 className="counter h1 text-white mb-0">0</h2>
                        )}
                        <p className="text-white mb-0">Total project files</p>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <Link to={`/${CustRoute.list}`}>
                    <div className="card dashboard__stats nordic-dark-teal">
                      <div className="stats__icon text-right">
                        <img
                          src={`${FileUrl}/img/icons/profile.svg`}
                          className="in__svg"
                          alt="Employees"
                        />
                      </div>
                      <div className="stats__details">
                        {!loading ? (
                          previewNumWithCounter(parseInt(cardData.totalClient))
                        ) : (
                          <h2 className="counter h1 text-white mb-0">0</h2>
                        )}
                        <p className="text-white mb-0">Clients</p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="row">
                {/* <div className="col-lg-4">
                                <div className="activity-block card height-auto">
                                    <div className="card-header p-0 d-flex justify-content-between align-items-center bg-transparent border-0">
                                        <h6 className="mb-0">Employee Activity</h6>
                                        <img src={`${FileUrl}/img/icons/right-arrow.svg`}  alt="Employees"/>
                                    </div>
                                    <div className="card-body p-0">
                                        <ul className="activity__list list-unstyled mt-4">
                                            <li className="activity-item d-flex">
                                                <div className="user-avatar">
                                                    <img src={`${FileUrl}/img/user.png`}  alt="Employees"/>
                                                </div>
                                                <div className="activity-details">
                                                    <p>File TPS No 07 VESU MAGDALLA FP No 10 SUBDIVISION added by Chintu Patel</p>
                                                    <a href="#">
                                                        View details <img src={`${FileUrl}/img/icons/right-arrow-group.svg`} alt="Employees"/>
                                                    </a>
                                                </div>
                                            </li>
                                            <li className="activity-item d-flex">
                                                <div className="user-avatar">
                                                    <img src={`${FileUrl}/img/user.png`}  alt="Employees"/>
                                                </div>
                                                <div className="activity-details">
                                                    <p>File TPS No 07 VESU MAGDALLA FP No 10 SUBDIVISION added by Chintu Patel</p>
                                                    <a href="#">
                                                        View details <img src={`${FileUrl}/img/icons/right-arrow-group.svg`}  alt="Employees"/>
                                                    </a>
                                                </div>
                                            </li>
                                            <li className="activity-item d-flex">
                                                <div className="user-avatar">
                                                    <img src={`${FileUrl}/img/user.png`}  alt="Employees"/>
                                                </div>
                                                <div className="activity-details">
                                                    <p>File TPS No 07 VESU MAGDALLA FP No 10 SUBDIVISION added by Chintu Patel</p>
                                                    <a href="#">View details <img src={`${FileUrl}/img/icons/right-arrow-group.svg`}  alt="Employees"/></a>
                                                </div>
                                            </li>
                                            <li className="activity-item d-flex">
                                                <div className="user-avatar">
                                                    <img src={`${FileUrl}/img/user.png`}  alt="Employees"/>
                                                </div>
                                                <div className="activity-details">
                                                    <p>File TPS No 07 VESU MAGDALLA FP No 10 SUBDIVISION added by Chintu Patel</p>
                                                    <a href="#">View details <img src={`${FileUrl}/img/icons/right-arrow-group.svg`}  alt="Employees"/></a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="card-footer p-0 bg-transparent border-0 text-center">
                                        <a className="small" href="#">View all Employee Activity</a>
                                    </div>
                                </div>
                            </div> */}

                {/* <li className="activity-item d-flex">
                                                <div className="user-avatar">
                                                    <img src={`${FileUrl}/img/icons/clock.svg`} className="in__svg" alt="Employees"/>
                                                </div>
                                                <div className="activity-details">
                                                    <p>File TPS No 07 VESU MAGDALLA FP No 10 SUBDIVISION added by Chintu Patel</p>
                                                    <a href="#">View details <img src={`${FileUrl}/img/icons/right-arrow-group.svg`} className="in__svg" alt="Employees"/></a>
                                                </div>
                                            </li> */}
                <div className="col-lg-4 ad">
                  <div className="activity-block card height-auto">
                    <div className="card-header p-0 d-flex justify-content-between align-items-center bg-transparent border-0">
                      <h6 className="mb-0">File Activity</h6>
                      <img
                        src={`${FileUrl}/img/icons/right-arrow.svg`}
                        className="in__svg"
                        alt="Employees"
                      />
                    </div>
                    <div className="card-body p-0">
                      <ul className="activity__list list-unstyled mt-4">
                        {/* <li className="activity-item d-flex">
                                                <div className="user-avatar">
                                                    <img src={`${FileUrl}/img/icons/clock.svg`} className="in__svg" alt="Employees"/>
                                                </div>
                                                <div className="activity-details">
                                                    <p>File TPS No 07 VESU MAGDALLA FP No 10 SUBDIVISION added by Chintu Patel</p>
                                                    <Link to={`/${FilesRoute.listFileOnline}`}>View details <img src={`${FileUrl}/img/icons/right-arrow-group.svg`} className="in__svg" alt="Employees"/></Link>
                                                </div>
                                            </li> */}

                        {notifications.length ? (
                          <>
                            {notifications.map((n, i) => {
                              return (
                                <>
                                  <li className="activity-item d-flex">
                                    <div className="user-avatar">
                                      <img
                                        src={`${FileUrl}/img/icons/clock.svg`}
                                        className="in__svg"
                                        alt="Employees"
                                      />
                                    </div>
                                    <div className="activity-details">
                                      <p>
                                        {n?.json_data?.message} {n?.name}
                                      </p>
                                      <p>
                                        {GetDate(n?.created_at, "DD-MM-YYYY")}{" "}
                                        {GetTime(n?.created_at, "hh:mm:ss A")}
                                      </p>
                                      {/* <div>{n?.json_data?.message}</div>
                                                                        <div>{GetDate(n?.created_at,"DD-MM-YYYY")} {GetTime(n?.created_at,"hh:mm:ss A")}</div>
                                                                        <div>
                                                                                        <b>{n?.name}</b><br/>
                                                                                        <b>File : {n?.file_name}</b><br/>
                                                                                        <b>Office no : {n?.office_file_no}</b>
                                                                                    </div> */}
                                      <Link
                                        to={`/${FilesRoute.listFileOnline}`}
                                      >
                                        View details{" "}
                                        <img
                                          src={`${FileUrl}/img/icons/right-arrow-group.svg`}
                                          className="in__svg"
                                          alt="Employees"
                                        />
                                      </Link>
                                    </div>
                                  </li>
                                </>
                              );
                            })}
                          </>
                        ) : (
                          ""
                        )}
                      </ul>
                    </div>
                    <div className="card-footer p-0 bg-transparent border-0 text-center">
                      <Link to={`/${FilesRoute.listFileOnline}`}>
                        View all File Activity
                      </Link>
                    </div>
                  </div>
                </div>

                {superAccess([adminKey, subadmin]) ? (
                  <div className="col-lg-8">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="activity-block card height-auto">
                          <div className="card-header p-0 d-flex justify-content-between align-items-center bg-transparent border-0">
                            <h6 className="mb-0">Todos</h6>
                            <img
                              src={`${FileUrl}/img/icons/right-arrow.svg`}
                              className="in__svg"
                              alt="Employees"
                            />
                          </div>
                          <div className="card-body p-0">
                            <ul
                              className="activity__list list-unstyled mt-4"
                              style={{ maxHeight: "300px", overflowY: "auto" }}
                            >
                              {/* <li className="activity-item d-flex">
                                                    <div className="user-avatar">
                                                        <img src={`${FileUrl}/img/icons/clock.svg`} className="in__svg" alt="Employees"/>
                                                    </div>
                                                    <div className="activity-details">
                                                        <p>File TPS No 07 VESU MAGDALLA FP No 10 SUBDIVISION added by Chintu Patel</p>
                                                        <Link to={`/${FilesRoute.listFileOnline}`}>View details <img src={`${FileUrl}/img/icons/right-arrow-group.svg`} className="in__svg" alt="Employees"/></Link>
                                                    </div>
                                                </li> */}

                              {todoNotification.length ? (
                                <>
                                  {todoNotification.map((n, i) => {
                                    return (
                                      <>
                                        <li className="activity-item">
                                          <div className="user-avatar">
                                            <img
                                              src={`${FileUrl}/img/icons/clock.svg`}
                                              className="in__svg"
                                              alt="Employees"
                                            />
                                          </div>
                                          <div className="activity-details">
                                            <div>
                                              <b>By : {n?.fullname}</b>
                                              <br />
                                            </div>
                                            <p>
                                              {GetDate(n?.created_at, "DD-MM-YYYY")}{" "}
                                              {GetTime(n?.created_at, "hh:mm:ss A")}
                                            </p>
                                            {/* <p>{n?.json_data?.action}</p> */}
                                            <p>Title: {n?.json_data?.title}</p>
                                            <hr />
                                            {/* <p>{n?.json_data?.message}</p> */}
                                            <textarea
                                              name="reason"
                                              id="reason"
                                              cols="3"
                                              // {...register("description")}
                                              value={n?.json_data?.message}
                                              disabled
                                              style={{
                                                height: "100px",
                                                width: "100%",
                                                border: "0",
                                                backgroundColor: "#fff",
                                              }}
                                              // disabled={todo?.mark_as_done == 1}
                                            ></textarea>
                                            <hr />
                                            <p>
                                              Card :{" "}
                                              <b>
                                                {n?.json_data?.card
                                                  ? n?.json_data?.card.toUpperCase()
                                                  : "-"}
                                              </b>
                                            </p>

                                            {/* <Link to={`/${TodosRoute.markTodo}/${n?.child_id}`}>View details <img src={`${FileUrl}/img/icons/right-arrow-group.svg`} className="in__svg" alt="Employees"/></Link> */}
                                            <a
                                              role="button"
                                              onClick={() =>
                                                seenNotification(
                                                  `/${TodosRoute.reviewtodo}/${n?.child_id}`,
                                                  { id: n?.id },
                                                  true
                                                )
                                              }
                                            >
                                              View details{" "}
                                              <img
                                                src={`${FileUrl}/img/icons/right-arrow-group.svg`}
                                                className="in__svg"
                                                alt="Employees"
                                              />
                                            </a>
                                          </div>
                                        </li>
                                      </>
                                    );
                                  })}
                                </>
                              ) : (
                                <>{"Nothing to do"}</>
                              )}
                            </ul>
                          </div>
                          <div className="card-footer p-0 bg-transparent border-0 text-center">
                            {/* <Link to={`/${FilesRoute.listFileOnline}`}>View all File Activity</Link> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="activity-block card height-auto">
                            <div className="card-header p-0 d-flex justify-content-between align-items-center bg-transparent border-0">
                              <h6 className="mb-0">Client Activity</h6>
                            </div>
                          </div>
                          <div className="activity-block card height-auto">
                            <div className="card-header p-0 d-flex justify-content-between align-items-center bg-transparent border-0">
                              <h6 className="mb-0">Expense Activity</h6>
                            </div>
                          </div>
                      </div>
                    </div>

                  </div>
                ) : (
                  ""
                )}

                {isEmp() ? (
                  <div className="col-lg-4">
                    <div className="activity-block card height-auto">
                      <div className="card-header p-0 d-flex justify-content-between align-items-center bg-transparent border-0">
                        <h6 className="mb-0">Your task</h6>
                        <img
                          src={`${FileUrl}/img/icons/right-arrow.svg`}
                          className="in__svg"
                          alt="Employees"
                        />
                      </div>
                      <div className="card-body p-0">
                        <ul
                          className="activity__list list-unstyled mt-4"
                          style={{ maxHeight: "300px", overflowY: "auto" }}
                        >
                          {todoNotification_forOthers.length ? (
                            <>
                              {todoNotification_forOthers.map((n, i) => {
                                return (
                                  <>
                                    <li className="activity-item">
                                      <div className="user-avatar">
                                        <img
                                          src={`${FileUrl}/img/icons/clock.svg`}
                                          className="in__svg"
                                          alt="Employees"
                                        />
                                      </div>
                                      <div className="activity-details">
                                        <p>{n?.json_data?.message}</p>
                                        <p>
                                          {GetDate(n?.created_at, "DD-MM-YYYY")}{" "}
                                          {GetTime(n?.created_at, "hh:mm:ss A")}
                                        </p>
                                        <div>
                                          <b>{n?.json_data?.title}</b>
                                          <br />
                                        </div>
                                        {/* <Link to={`/${TodosRoute.markTodo}/${n?.child_id}`}>View details <img src={`${FileUrl}/img/icons/right-arrow-group.svg`} className="in__svg" alt="Employees"/></Link> */}
                                        <a
                                          role="button"
                                          onClick={() =>
                                            seenNotification(
                                              `/${TodosRoute.markTodo}/${n?.child_id}`,
                                              { id: n?.id },
                                              true
                                            )
                                          }
                                        >
                                          View details{" "}
                                          <img
                                            src={`${FileUrl}/img/icons/right-arrow-group.svg`}
                                            className="in__svg"
                                            alt="Employees"
                                          />
                                        </a>
                                      </div>
                                    </li>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <>{"Nothing to do"}</>
                          )}
                        </ul>
                      </div>
                      <div className="card-footer p-0 bg-transparent border-0 text-center">
                        {/* <Link to={`/${FilesRoute.listFileOnline}`}>View all File Activity</Link> */}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {/* <div className="col-lg-4">
                                <div className="activity-block card height-auto">
                                    <div className="card-header p-0 d-flex justify-content-between align-items-center bg-transparent border-0">
                                        <h6 className="mb-0">Client Activity</h6>
                                        <img src={`${FileUrl}/img/icons/right-arrow.svg`} className="in__svg" alt="Employees"/>
                                    </div>
                                    <div className="card-body p-0">
                                        <ul className="activity__list list-unstyled mt-4">
                                            <li className="activity-item d-flex">
                                                <div className="user-avatar">
                                                    <img src={`${FileUrl}/img/user-2.png`} alt="Activity"/>
                                                </div>
                                                <div className="activity-details">
                                                    <p>File TPS No 07 VESU MAGDALLA FP No 10 SUBDIVISION added by Chintu Patel</p>
                                                    <a href="#">View details <img className="in__svg" src={`${FileUrl}/img/icons/right-arrow-group.svg`} alt="Right arrow"/></a>
                                                </div>
                                            </li>
                                            <li className="activity-item d-flex">
                                                <div className="user-avatar">
                                                    
                                                    <img src={`${FileUrl}/img/user-2.png`} alt="Activity"/>
                                                </div>
                                                <div className="activity-details">
                                                    <p>File TPS No 07 VESU MAGDALLA FP No 10 SUBDIVISION added by Chintu Patel</p>
                                                    <a href="#">View details <img className="in__svg" src={`${FileUrl}/img/icons/right-arrow-group.svg`} alt="Right arrow"/></a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="card-footer p-0 bg-transparent border-0 text-center">
                                        <a className="small" href="#">View all Client Activity</a>
                                    </div>
                                </div>
                                <div className="activity-block card height-auto">
                                    <div className="card-header p-0 d-flex justify-content-between align-items-center bg-transparent border-0">
                                        <h6 className="mb-0">Expense Activity</h6>
                                        <img className="" src={`${FileUrl}/img/icons/right-arrow.svg`} alt="Right arrow"/>
                                    </div>
                                    <div className="card-body p-0">
                                        <ul className="activity__list list-unstyled mt-4">
                                            <li className="activity-item d-flex">
                                                <div className="user-avatar">
                                                    <img src={`${FileUrl}/img/user-2.png`} alt="Activity"/>
                                                </div>
                                                <div className="activity-details">
                                                    <p>File TPS No 07 VESU MAGDALLA FP No 10 SUBDIVISION added by Chintu Patel</p>
                                                    <a href="#">View details <img className="in__svg" src={`${FileUrl}/img/icons/right-arrow-group.svg`} alt="Right arrow"/></a>
                                                </div>
                                            </li>
                                            <li className="activity-item d-flex">
                                                <div className="user-avatar">
                                                    <img src={`${FileUrl}/img/user-2.png`} alt="Activity"/>
                                                </div>
                                                <div className="activity-details">
                                                    <p>File TPS No 07 VESU MAGDALLA FP No 10 SUBDIVISION added by Chintu Patel</p>
                                                    <a href="#">View details <img className="in__svg" src={`${FileUrl}/img/icons/right-arrow-group.svg`} alt="Right arrow"/></a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="card-footer p-0 bg-transparent border-0 text-center">
                                        <a className="small" href="#">View all Expense Activity</a>
                                    </div>
                                </div>
                            </div> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Dashboard;
