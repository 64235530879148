import React, { useState,useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { DepositExpenseRoute } from '../../../config/RouteConfig';
import { getToken } from '../../../services/useLocalStorage';
import { displayLoader } from '../../../UtilsComponent/DisplayLoader';
import {useForm} from 'react-hook-form';
import { formclass,displayError, checkFile } from '../../../services/ValidationService';
import { todayDate } from '../../../services/DateAndTimeService';
import { floatPattern } from '../../../services/Pattern';
import { fetchActiveEmployee, fetchEmployee, insertDeposit } from '../../../config/config';
import ApiService from '../../../services/ApiService';
import toast from 'react-hot-toast';

//Deposit_Expense
const Add = () => {

    var header = {Authorization:`Bearer ${getToken()}`,['Content-type']:'application/json'};
    const navigate = useNavigate();  
    const [loading,setLoading] = useState(false);
    const [employees, setEmployees] = useState([]);

    const {register,formState:{errors},getValues,setValue,setError,clearErrors,handleSubmit} = useForm({
        mode:"onChange"
    });

    const goBack = () =>{
        navigate(`/${DepositExpenseRoute.list}`);
    }  

    const onSubmit = async (data) => {
        try
        {
            setLoading(true);
            const res = await ApiService(data,0,header,insertDeposit.method,insertDeposit.url);
            if(!res?.data?.error)
            {
                setLoading(false);
                toast.success(res?.data?.message);
                navigate(`/${DepositExpenseRoute?.list}`);
            }
            else
            {
                setLoading(false);
                toast.error(res?.data?.message);
            }
        }   
        catch(err)
        {
            setLoading(false);
            console.log(err);
            toast.error(err?.response?.data?.message ? err?.response?.data?.message : "Something went wrong");
        }
    }

    const getEmployees =async () =>{
        try{
            const header = {Authorization:`Bearer ${getToken()}`};
            const res = await ApiService(null,0,header,fetchActiveEmployee.method,fetchActiveEmployee.url);
            if(!res.data.error){
                const payload = res.data?.data;
                if(Array.isArray(payload) && payload.length){
                    setEmployees(payload);
                }
            }
        }catch(err){
            console.log(err);
        }
    }

    useEffect(()=>{
        getEmployees();
    },[]);


  return (
    <div className="content__wrapper">
            <section className="page-content">
                <div className="page-title mobile-title">
                    <h1 className="h4 mb-0">Add</h1>
                    <p className="mb-4">Deposit</p>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card p-5">
                            <div className="card-header bg-transparent border-0 mb-5 p-0">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-md-6">
                                        <h6 className="title-line text-shadow-yellow mb-0 pb-3">Add Deposit</h6>
                                    </div>
                                </div>
                            </div>
                             
                            <div className="card-body p-0">
                            {loading ? displayLoader(null)
                            :
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label for="amount">Amount*</label>
                                            <input 
                                                type="text" 
                                                name="amount" 
                                                placeholder='Amount' 
                                                id="amount" 
                                                className={formclass(errors?.amount)}
                                                {...register("amount",
                                                {
                                                    required:"Required",
                                                    pattern:{
                                                        value:floatPattern,
                                                        message:"Should be in correct form"
                                                    },
                                                    validate:(value)=>value>0||"Should be greater than zero"

                                                })}
                                            />
                                            {displayError(errors?.amount?.message)}
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label for="date">Date*</label>
                                            <input 
                                                type="date" 
                                                name="date" 
                                                placeholder='Amount' 
                                                id="date" 
                                                className={formclass(errors?.date)}
                                                {...register("date",
                                                {
                                                    required:"Required",

                                                })}
                                                defaultValue={todayDate()}
                                            />
                                            {displayError(errors?.date?.message)}
                                        </div>
                                        
                                        
                                      
                                        
                                    </div>
                                    <div className="row">
                                    <div className="form-group col">
                                            <label htmlFor="description">Description</label>
                                            <textarea 
                                                name="description" id="description" cols="30" rows="10"
                                                className={formclass(errors?.description)}
                                                {...register("description")}
                                            >

                                            </textarea>
                                            {displayError(errors?.description?.message)}
                                        </div>
                                    </div>
                                    <div className="row">
                                    <div className="form-group col">
                                            <label for="team_id">Team Member*</label>
                                            <select 
                                                name="team_id" 
                                                id="team_id" 
                                                className={formclass(errors?.team_id)}
                                                {...register("team_id",
                                                {
                                                    required:"Required"
                                                })}
                                            >
                                            <option value={""}>--Select--</option>
                                                {employees.map((data) => (
                                                    <option key={data?.id} value={data?.id}>{data?.first_name} {data?.last_name}</option>
                                                ))}     
                                            
                                            </select>
                                            {displayError(errors?.team_id?.message)}
                                        </div>
                                      
                                        
                                    </div>
                                    <div className="row">
                                        <div className="form-group mb-0 col-md-2">
                                            <button type="submit" className="btn">Submit</button>
                                        </div>
                                        <div className="form-group mb-0 col-md-4">
                                            <button type="submit" onClick={goBack} className="btn">Back</button>
                                        </div>
                                    </div>
                                </form>
                                 }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
  )
}

export default Add