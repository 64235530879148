import { isExpired, decodeToken } from "react-jwt";
import { defaultuserpic, FileUrl } from "../config/file_url";
import {
  adminKey,
  customerKey,
  empKey,
  subadmin,
  cordinator,
  HoD,
} from "../config/RoleConfig";

const setInLocalStorage = (key, value) => {
  if (key) {
    const v = JSON.stringify(value);
    localStorage.setItem(key, v);
  }
};

export const getToken = () => {
  if (localStorage.getItem("userinfo")) {
    const token = JSON.parse(localStorage.getItem("userinfo")).token;
    return token;
  }

  return "";
};

export const getUserProfilePic = () => {
  if (localStorage.getItem("userinfo")) {
    const profile_pic = JSON.parse(
      localStorage.getItem("userinfo")
    )?.profile_picture;
    if (profile_pic) {
      return `${FileUrl}/${profile_pic}`;
    } else {
      return `${FileUrl}/${defaultuserpic}`;
    }
  }

  return defaultuserpic;
};

export const getFirstName = () => {
  if (localStorage.getItem("userinfo")) {
    const first_name = JSON.parse(localStorage.getItem("userinfo"))?.first_name;
    if (first_name) {
      return first_name;
    } else {
      return "";
    }
  }
  return "";
};

export const getLastName = () => {
  if (localStorage.getItem("userinfo")) {
    const last_name = JSON.parse(localStorage.getItem("userinfo"))?.last_name;
    if (last_name) {
      return last_name;
    } else {
      return "";
    }
  }
  return "";
};

export const getFullName = () => {
  if (localStorage.getItem("userinfo")) {
    const fullname = JSON.parse(localStorage.getItem("userinfo"))?.fullname;
    if (fullname) {
      return fullname;
    } else {
      return "";
    }
  }
  return "";
};

export const getRole = () => {
  const token = getToken();

  return decodeToken(token)?.role;
};

export const getUserRoles = () => {
  const token = getToken();

  try {
    const arr = decodeToken(token)?.user_roles;
    // console.log("roles====>",arr)

    if (Array.isArray(arr)) {
      return arr;
    }

    return [];
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const superAccess = (roles_arr = [adminKey]) => {
  try {
    return getUserRoles().some((r) => roles_arr.includes(r));
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getUserId = () => {
  try {
    const token = getToken();
    return decodeToken(token)?.id;
  } catch (err) {
    return 0;
  }
};

export const isCust = () => {
  try {
    return getUserRoles().includes(customerKey);
  } catch (err) {
    return false;
  }
};

export const isAdmin = () => {
  try {
    // return [adminKey].includes(getUserRoles())
    return getUserRoles().includes(adminKey);
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const isSuperAdmin = () => {
  try {
    return getUserRoles().includes(subadmin);
  } catch (err) {
    console.log(err);
    return false;
  }
};
export const ishod = () => {
  try {
    return getUserRoles().includes(HoD);
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const isEmp = () => {
  try {
    return getUserRoles().includes(empKey);
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const Cordinator = () => {
  try {
    return getUserRoles().includes(cordinator);
  } catch (err) {
    console.log(err);
    return false;
  }
};

export default function useLocalStorage(key, value) {
  if (key !== undefined && key) {
    setInLocalStorage(key, value);
  }
}

export const shouldAllowedOfRoles = (
  roles_allowed_arr,
  roles_arr = [adminKey]
) => {
  try {
    if (Array.isArray(roles_allowed_arr)) {
      // console.log(roles_allowed_arr.some((r)=>r.includes(roles_arr)));
      return roles_allowed_arr.some((r) => roles_arr.includes(r));
    } else {
      return false;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};
