import React from 'react'


const BarChart = () => {
  return (
    <div>
      {/* <Bar 
        height={400}
        width={400}
        data={{
          labels:['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange']
        }}
      /> */}
    </div>
  )
}

export default BarChart