import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  memo,
  useRef,
} from "react";
import {
  GetEmployees,
  DeleteEmployee,
} from "../../../services/EmployeeService";
import { useNavigate, useLocation } from "react-router-dom";
import { Cordinator, getToken, isEmp } from "../../../services/useLocalStorage";
import { Link } from "react-router-dom";
import { GetDate } from "../../../services/DateAndTimeService";
import toast from "react-hot-toast";
import { EmpRoute, LeaveRoute, login } from "../../../config/RouteConfig";
import { SwalAlert } from "../../../UtilsComponent/SwalDialog";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { paginationRows } from "../../../config/CommonVariables";
import {
  exportToCsv,
  loadingTemplate,
  notFoundTemplate,
} from "../../../services/FormCommon";

import {
  useSortBy,
  useTable,
  usePagination,
  useGlobalFilter,
  useRowSelect,
} from "react-table";
import { MyLoader } from "../../../UtilsComponent/MyLoader";
import { AiOutlineWallet } from "react-icons/ai";
import { cordinator } from "../../../config/RoleConfig";

//Employee
const List = () => {
  const tableRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);

  const hname = {
    first_name: "First name",
    last_name: "Last name",
    email: "Email",
    mobile: "Mobile",
    status: "Status",
    created_at: "Created at",
    action: "action",
  };
  const handleButtonClick = (path) => {
    localStorage.setItem("path", path);
  };

  const handleExpanse = (wallet) => {
    localStorage.setItem("wallet", wallet);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "id",
        accessor: "id",
      },
      {
        Header: "First Name",
        accessor: "first_name",
      },
      {
        Header: "Last Name",
        accessor: "last_name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Mobile",
        accessor: "phone",
      },
      {
        Header: "Status",
        accessor: "active",
      },
      {
        Header: "Created at",
        accessor: "created_at",
      },
      {
        Header: "",
        accessor: "expense",
        Cell: ({ value, row }) =>
          // !Cordinator(cordinator) && (
            <Link
              to={`/${EmpRoute.deposit_expense_report}/${row?.values?.id}`}
              onClick={() =>
                handleExpanse(
                  `/${EmpRoute.deposit_expense_report}/${row?.values?.id}`
                )
              }
            >
              <AiOutlineWallet style={{ marginRight: "5px" }} />
              {value}
            </Link>
          // ),
      },
    ],
    []
  );

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "Edit",
        Header: "",
        Cell: ({ row }) =>
          !Cordinator(cordinator) && (
            <React.Fragment>
              {/* <button id={`${row?.values?.id}_click`} onClick={()=>alert("Okay")}>Test</button> */}
              <div className="dropdown">
                <button
                  className="btn btn-secondary btn_action_menu"
                  id={`${row?.value?.id}_click`}
                  type="button"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <b>
                    <i class="fas fa-ellipsis-v"></i>
                  </b>
                </button>

                <div className="dropdown-menu">
                  {/* <Link className="dropdown-item" to={`/${EmpRoute.team_profile_details}/${row?.values?.id}`} onClick={() => handleButtonClick(`/${EmpRoute.team_profile_details}/${row?.values?.id}`)}>Team Profile</Link> */}
                  <Link
                    className="dropdown-item"
                    to={`/${EmpRoute.edit}/${row?.values?.id}`}
                  >
                    Edit
                  </Link>
                  <a
                    className="dropdown-item"
                    role={"button"}
                    onClick={() => onRemoveEmployee(row?.values?.id)}
                  >
                    Remove
                  </a>
                  {/* <Link className="dropdown-item" to={`/${EmpRoute.deposit_expense_report}/${row?.values?.id}`}>Show expense</Link> */}
                </div>
              </div>
            </React.Fragment>
          ),
      },
    ]);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    page,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data: employees,
      initialState: { hiddenColumns: ["id"], pageIndex: 0, pageSize: 100 },
    },
    tableHooks,
    useGlobalFilter,
    useSortBy,
    usePagination
    // useRowSelect, // Add useRowSelect hook
    // hooks => {
    //   hooks.visibleColumns.push(columns => [
    //     // Add a checkbox column
    //     {
    //       id: 'selection',
    //       Header: ({ getToggleAllRowsSelectedProps }) => (
    //         <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
    //       ),
    //       Cell: ({ row }) => (
    //         <div>
    //           <input
    //             type="checkbox"
    //             {...row.getToggleRowSelectedProps()}
    //           />
    //         </div>
    //       ),
    //     },
    //     ...columns,
    //   ]);
    // }
  );

  const actionRender = (p) => {
    return (
      <>
        <Link
          to={`/${EmpRoute.team_profile_details}/${p?.value}`}
          style={{ color: "black" }}
        >
          <i class="fa fa-eye" aria-hidden="true"></i>
        </Link>
        &nbsp;
        <Link to={`/${EmpRoute.edit}/${p?.value}`} style={{ color: "black" }}>
          <i class="fas fa-pencil-alt" aria-hidden="true"></i>
        </Link>
        &nbsp;
        <a role={"button"} onClick={() => onRemoveEmployee(p?.value)}>
          <i class="fa fa-trash" aria-hidden="true"></i>
        </a>
        &nbsp;
        <Link
          to={`/${EmpRoute.deposit_expense_report}/${p?.value}`}
          style={{ color: "black" }}
        >
          <i class="fas fa-wallet" aria-hidden="true"></i>
        </Link>
        &nbsp;
      </>
    );
  };

  const columnDef = [
    { field: hname.first_name },
    { field: hname.last_name },
    { field: hname.email },
    { field: hname.mobile },
    { field: hname.status },
    { field: hname.created_at },
    {
      field: hname.action,
      sortable: false,
      filter: false,
      cellRenderer: memo(actionRender),
    },
  ];

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: true,
      resizable: true,
      flex: 1,
      filterParams: {
        debounsMs: 0,
        // buttons:['apply','clear','reset','cancel']
      },
    }),
    []
  );

  const getEmployeeData = async () => {
    try {
      setLoading(true);
      const res = await GetEmployees(`${getToken()}`);
      if (!res.data.error) {
        setLoading(false);
        const payload = res?.data?.data;
        if (!(payload.length > 0)) {
        }

        const rows = [];
        if (Array.isArray(payload) && payload.length > 0) {
          payload.forEach((element) => {
            rows.push({
              id: element?.id,
              first_name: (
                <Link
                  to={`/${EmpRoute.team_profile_details}/${element?.id}`}
                  onClick={() =>
                    handleButtonClick(
                      `/${EmpRoute.team_profile_details}/${element?.id}`
                    )
                  }
                >
                  {element?.first_name}
                </Link>
              ),
              last_name: element?.last_name,
              email: element?.email,
              phone: element?.phone,
              // active:element?.active ? "Active" : "Not active",
              active: (
                <span
                  className={
                    "status_btn " + (element.active ? "active" : "inactive")
                  }
                >
                  {element.active ? "Active" : "Not active"}
                </span>
              ),
              created_at: element?.created_at
                ? GetDate(element.created_at, "DD-MMM-YYYY")
                : "-",
            });
          });
          setEmployees(rows);
        }
      } else {
        setLoading(false);

        toast.error(res.data.message);
        setTimeout(() => {
          navigate(`/${login.list}`, {
            state: { from: location },
            replace: true,
          });
        }, 1500);
      }
    } catch (err) {
      setLoading(false);

      console.log(err);
      if (err.response.data.error) {
        toast.error(err.response.data.message);
        setTimeout(() => {
          navigate(`/${login.list}`, {
            state: { from: location },
            replace: true,
          });
        }, 1500);
      }
    }
  };

  const removeEmployee = async (id) => {
    try {
      const res = await DeleteEmployee(id, getToken());
      if (!res.data?.error) {
        toast.success(res.data?.message);

        setEmployees((prevState) => {
          return prevState.filter((e) => {
            return e?.id != id;
          });
        });
      } else {
        toast.error(res.data?.message);
      }
    } catch (err) {
      console.log(err);
      toast.error(
        err?.response?.data?.message
          ? err?.response?.data?.message
          : "Something went wrong"
      );
    }
  };

  const onRemoveEmployee = (id) => {
    SwalAlert({})
      .then(async (result) => {
        if (result.isConfirmed) {
          await removeEmployee(id);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    getEmployeeData();
  }, []);

  return (
    <>
      <div className="content__wrapper">
        <section className="page-content">
          <div className="page-title mobile-title">
            <h1 className="h4 mb-0">Team Member</h1>
            <p className="mb-4">Team Member</p>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="zed__table card">
                <h5 className="text-shadow-yellow px-2 mb-4">
                  All Team Members
                </h5>
                <div className="row">
                  <div className="col-lg-3 col-12">
                    <GlobalFilter
                      filter={globalFilter}
                      setFilter={setGlobalFilter}
                    />
                  </div>
                </div>
                <div className="table-responsive">
                  {loading ? (
                    <MyLoader value={false} />
                  ) : (
                    <table
                      className="table team_table datatable"
                      {...getTableProps()}
                    >
                      <thead>
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps()
                                )}
                              >
                                {column.render("Header")}
                                <span>
                                  {column.isSorted
                                    ? column.isSortedDesc
                                      ? " 🔽"
                                      : " 🔼"
                                    : ""}
                                </span>
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {/* {rows.map(row => { */}
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => {
                                return (
                                  <td {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colSpan={2}>
                          <div className="pagination justify-content-end align-items-center">
                            <button
                              className="pagination_button"
                              onClick={() => gotoPage(0)}
                              disabled={!canPreviousPage}
                            >
                              {"<<"}
                            </button>
                            <button
                              className="pagination_button"
                              onClick={() => previousPage()}
                              disabled={!canPreviousPage}
                            >
                              {"<"}
                            </button>
                            <span>
                              Page
                              <strong>
                                {pageIndex + 1} of {pageOptions.length}
                              </strong>
                            </span>
                            <button
                              className="pagination_button"
                              onClick={() => nextPage()}
                              disabled={!canNextPage}
                            >
                              {">"}
                            </button>
                            <button
                              className="pagination_button"
                              onClick={() => gotoPage(pageCount - 1)}
                              disabled={!canNextPage}
                            >
                              {">>"}
                            </button>
                          </div>
                        </td>
                      </tfoot>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default List;

const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <span>
      <input
        className="form-control"
        type={"search"}
        style={{ margin: "5px" }}
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        placeholder="Search"
      />
    </span>
  );
};
