import React, { useState, useEffect, useCallback } from "react";
import { getToken } from "../../../services/useLocalStorage";
import Loader from "react-js-loader";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { displayError, formclass } from "../../../services/ValidationService";
import { eyeButtonSvg, FileUrl } from "../../../config/file_url";
import ApiService from "../../../services/ApiService";
import {
  getProjectTypes,
  updateprojectsummery,
  getCategory,
  fetchHod,
  getDpartmentempoloyee,
  getmstProject,
} from "../../../config/config";
import architectureRoute from "../../../config/RouteConfig";
import { adminKey, HoD, empKey } from "../../../config/RoleConfig";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import jwt_decode from "jwt-decode";

//FileDetails For Project
const ProjectSummery = () => {
  const params = useParams();
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    reset,
    trigger,
  } = useForm({
    mode: "onChange",
  });

  const goBack = () => {
    navigate(`/${architectureRoute.list}`);
  };

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedImage, setSelectedImage] = useState({
    site_condition: null,
    east_side: null,
    west_side: null,
    north_side: null,
    south_side: null,
  });

  const [id, setid] = useState(0);
  const [image, setImage] = useState(null);
  const [eastimage, setEastImage] = useState(null);
  const [westimage, setWestImage] = useState(null);
  const [northimage, setNorthImage] = useState(null);
  const [southimage, setSouthImage] = useState(null);
  const [userRoles, setUserRoles] = useState([]);
  const [category, setCategory] = useState([]);
  const [hod, setHoD] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [teamId1,setTeamId1]=useState('')
  const [teamId2,setTeamId2]=useState('')
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedHOD, setSelectedHOD] = useState(null);
  const [project, setProject] = useState([]);

  const parseToken = () => {
    const token = getToken();
    if (token) {
      try {
        const decodedToken = jwt_decode(token);

        const roles = decodedToken.user_roles;
        setUserRoles(roles);
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
  };

  const setUserRoleBasedOnRoles = () => {
    console.log("userRoles:", userRoles);

    if (userRoles && userRoles.length > 0) {
      // Check if 'admin' is present in the user roles array
      const hasAdmin = userRoles.includes("admin");
      console.log("hasAdmin:", hasAdmin);

      // Check if 'Hod' is present in the user roles array
      const hasHod = userRoles.includes("Hod");
      console.log("hasHod:", hasHod);

      // If 'admin' is present, set the user role to 'admin'
      if (hasAdmin) {
        setUserRoles("admin");
        console.log("User role set to admin");
      } else if (hasHod) {
        // If 'Hod' is present, set the user role to 'Hod'
        setUserRoles("Hod");
        console.log("User role set to Hod");
      } else {
        // If neither 'admin' nor 'Hod' is present, set the user role to 'Employees'
        setUserRoles("Employees");
        console.log("User role set to Employees");
      }
    }
  };

  const onFileInput = (e, imageKey) => {
    const file = e.target.files[0];

    // Update the state based on the image key
    switch (imageKey) {
      case "site_condition":
        setSelectedImage({ ...selectedImage, site_condition: file });
        setImage(file); // Update the state with the new image
        break;
      case "east_side":
        setSelectedImage({ ...selectedImage, east_side: file });
        setEastImage(file); // Update the state with the new image
        break;
      case "west_side":
        setSelectedImage({ ...selectedImage, west_side: file });
        setWestImage(file); // Update the state with the new image
        break;
      case "north_side":
        setSelectedImage({ ...selectedImage, north_side: file });
        setNorthImage(file); // Update the state with the new image
        break;
      case "south_side":
        setSelectedImage({ ...selectedImage, south_side: file });
        setSouthImage(file); // Update the state with the new image
        break;
      default:
        break;
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const onSubmit = useCallback(
    async (data) => {
      const formData = new FormData();
      formData.append("office_no", data.office_file_no);
      formData.append("team_id1", data.team_id1);
      formData.append("team_id2", data.team_id2);
      formData.append("address", data.address);
      formData.append("google_location", data.google_location);
      formData.append("land_detail", data.land_detail);
      formData.append("process", selectedOption);
      formData.append("id", params.id);

      // Check if any image is selected, if not, append the old image
      const isAnyImageSelected = Object.values(selectedImage).some(
        (image) => image !== null
      );
      if (!isAnyImageSelected) {
        formData.append("site_condition", image); // Append old image
        formData.append("east_side", eastimage); // Append old image
        formData.append("west_side", westimage); // Append old image
        formData.append("north_side", northimage); // Append old image
        formData.append("south_side", southimage); // Append old image
      } else {
        // Append the selected images
        Object.keys(selectedImage).forEach((key) => {
          if (selectedImage[key] !== null) {
            formData.append(key, selectedImage[key]);
          }
        });
      }

      try {
        setLoading(true);
        const header = { Authorization: `Bearer ${getToken()}` };

        let res;
        res = await ApiService(
          formData,
          0,
          header,
          updateprojectsummery.method,
          updateprojectsummery.url
        );

        if (!res.data.error) {
          setLoading(false);
          toast.success(res?.data?.message);
          if (data.is_save == 1) {
            // setSubmittable(1);
          }
        } else {
          toast.error(res?.data?.message ? res?.data?.message : "Server Error");
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        toast.error(
          err?.response?.data?.message
            ? err?.response?.data?.message
            : "Server Error"
        );
      }
    },
    [params?.id, selectedImage, image, selectedOption]
  );

  const fetchCategory = async () => {
    try {
      // const res = await GetEmployees(`${getToken()}`);
      const header = { Authorization: `Bearer ${getToken()}` };
      const res = await ApiService(
        null,
        0,
        header,
        getCategory.method,
        getCategory.url
      );
      if (!res.data.error) {
        const payload = res.data?.data;

        if (Array.isArray(payload) && payload.length) {
          setCategory(payload);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchHOD = async () => {
    try {
      // const res = await GetEmployees(`${getToken()}`);
      const header = { Authorization: `Bearer ${getToken()}` };
      const res = await ApiService(
        null,
        0,
        header,
        fetchHod.method,
        fetchHod.url
      );
      if (!res.data.error) {
        const payload = res.data?.data;

        if (Array.isArray(payload) && payload.length) {
          setHoD(payload);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchDataFromAPI = async () => {
    try {
      // Replace this with your API endpoint
      const response = await axios.get(getProjectTypes.url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });

      setData(response.data.data); // Use response.data directly
    } catch (error) {
      console.error("Error fetching data from API", error);
    }
  };

  const getEmployees = async (department_id) => {
    try {
      const header = { Authorization: `Bearer ${getToken()}` };
      const res = await ApiService(
        null,
        department_id,
        header,
        getDpartmentempoloyee.method,
        getDpartmentempoloyee.url
      );
      if (!res.data.error) {
        const payload = res.data?.data;
        if (Array.isArray(payload) && payload.length) {
          setEmployees(payload);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const ProjectData = async () => {
    try {
      setLoading(true);
      const header = { Authorization: `Bearer ${getToken()}` };
      const res = await ApiService(
        null,
        parseInt(params?.id),
        header,
        getmstProject.method,
        getmstProject.url
      );

      if (!res.data.error) {
        setLoading(false);
        const data =
          Object.keys(res?.data?.data).length === 0
            ? undefined
            : res?.data?.data[0];

        if (data) {
          reset({
            category_id: data?.category_id,
            project_type: data?.project_type,
            hod: data?.hod,
            address: data?.address,
            land_detail: data?.land_detail,
            office_file_no: data?.office_no,
            google_location: data?.google_location,
            
          });
          setTeamId1(data.team_id1.toString())
          setTeamId2(data.team_id2.toString())
          setSelectedOption(data.process.toString());
          setImage(data?.site_condition);
          setEastImage(data?.east_side);
          setWestImage(data?.west_side);
          setNorthImage(data?.north_side);
          setSouthImage(data?.south_side);
        }
      } else {
        setLoading(false);
        setSubmittable(0);
      }
    } catch (err) {
      setLoading(false);
      if (err.response?.data.error) {
        toast.error(err.response.data.message);
      }
    }
  };

  useEffect(() => {
    fetchCategory();
    fetchHOD();
    parseToken();
    fetchDataFromAPI();
    ProjectData();
    getEmployees();
    setUserRoleBasedOnRoles();
  }, [userRoles]);

  useEffect(() => {
    if (hod.length > 0) {
      // Select the first HOD by default
      setSelectedHOD(hod[0]);
    }
  }, [hod]);

  useEffect(() => {
    if (selectedHOD !== null) {
      getEmployees(selectedHOD.department_id);
    }
  }, [selectedHOD]);

  const isFieldDisabled = (fieldName) => {
    if (!userRoles || userRoles.length === 0) return false; // Return false if user roles are not available yet

    // Check if any of the user roles match the condition
    // return userRoles.some((role) => {
    if (userRoles === "admin" && fieldName !== "site_condition") {
      return true; // Disable all fields except 'site_condition' for admin
    }
    if (userRoles === "Employees" && fieldName !== "site_condition") {
      return true; // Disable all fields except 'site_condition' for employees
    }
    if (
      userRoles === "hod" &&
      (fieldName === "category_id" ||
        fieldName === "project_type" ||
        fieldName === "hod")
    ) {
      return true; // Disable 'category_id', 'project_type', 'hod' for hod
    }
    return false; // Enable all other fields
    // });
  };

  const handleTeamId1Change = (selectedValue) => {
    // Do something with the selected value, such as storing it in state
    console.log("Selected Team ID:", selectedValue);
    setTeamId1(selectedValue)
    // Here you can set the selected value to state or perform any other necessary actions
  };

  const handleTeamId2Change = (selectedValues) => {
    // Do something with the selected value, such as storing it in state
    console.log("Selected Team ID:", selectedValues);
    setTeamId2(selectedValues)
    // Here you can set the selected value to state or perform any other necessary actions
  };
  

  return (
    <div class="grey-border rounded">
      <div class="card-header bg-white">
        <h5 class="mb-0 font-weight-normal">Project Summery</h5>
      </div>
      <div class="card-body bg-white rounded-bottom p-3">
        {!loading ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              name="hiddenInput"
              value="hiddenValue"
              type="hidden"
              id={id}
            />
            <fieldset id="fieldset">
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="office_file_no">Office File No</label>
                  <input
                    type="text"
                    name="office_file_no"
                    id="office_file_no"
                    className={formclass(errors?.office_file_no)}
                    placeholder="Enter Name."
                    {...register("office_file_no", {
                      required: "Required",
                    })}
                    disabled={isFieldDisabled("office_file_no")}
                  />
                  <input type="hidden" id="id" name="id" value={id} />
                  {displayError(errors?.office_file_no?.message)}
                </div>
                <div class="form-group col-md-6 col-sm-6">
                  <label htmlFor="category_id">Category*</label>
                  <select
                    name="category_id"
                    id="category_id"
                    className="form-control"
                    {...register("category_id")}
                    disabled
                  >
                    <option value="">--Select--</option>
                    {category.map((row) => (
                      <option value={row.id}>{row.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row form-group">
                <div class="form-group col-md-6 ">
                  <label htmlFor="project_type">Project Type*</label>
                  <select
                    id="project_type"
                    name="project_type"
                    // className={formclass(errors?.project_type)}
                    {...register("project_type")}
                    className="form-control"
                    disabled
                  >
                    <option value="">--Select--</option>
                    {data.map((row) => (
                      <option value={row.id}>{row.title}</option>
                    ))}
                  </select>
                  {/* {displayError(errors?.project_type?.message)} */}
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="hod">HOD</label>
                  <select
                    name="hod"
                    id="hod"
                    // className={formclass(errors?.hod)}
                    className="form-control"
                    {...register("hod")}
                    onChange={(e) => {
                      const selectedHOD = hod.find(
                        (hod) => hod.id === parseInt(e.target.value)
                      );
                      setSelectedHOD(selectedHOD);
                    }}
                    disabled
                  >
                    <option value={""}>--Select HOD--</option>
                    {hod.map((hod) => (
                      <option value={hod.id}>
                        {" "}
                        {hod.first_name} {hod.last_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="team_id1">Team Members 1</label>
                  <select
                    name="team_id1"
                    id="team_id1"
                    className={formclass(errors?.team_id1)}
                    value={teamId1}
                    {...register("team_id1", {
                      required: "Select the team",
                    })}
                    disabled={isFieldDisabled("team_id1")}
                    onChange={(e) => handleTeamId1Change(e.target.value)}
                  >
                    <option value={""}>--Select Team--</option>
                    {employees.map((data) => (
                      <option key={data.id} value={data.id}>
                        {data.first_name} {data.last_name}
                      </option>
                    ))}
                  </select>
                  {displayError(errors?.team_id1?.message)}
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="team_id1">Team Members 2</label>
                  <select
                    name="team_id2"
                    id="team_id2"
                    value={teamId2}
                    className={formclass(errors?.team_id1)}
                    {...register("team_id2", {
                      required: "Select the team2",
                    })}
                    disabled={isFieldDisabled("team_id2")}
                    onChange={(e) => handleTeamId2Change(e.target.value)}
                  >
                    <option value={""}>--Select Team--</option>
                    {employees.map((data) => (
                      <option key={data.id} value={data.id}>
                        {data.first_name} {data.last_name}
                      </option>
                    ))}
                  </select>
                  {displayError(errors?.team_id1?.message)}
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6">
                  <label>Address</label>
                  <textarea
                    className={formclass(errors?.address)}
                    placeholder="Enter Address"
                    name="address"
                    id="address"
                    rows={3}
                    {...register("address", {
                      required: "Required",
                    })}
                    disabled={isFieldDisabled("address")}
                  />
                  {displayError(errors?.address?.message)}
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="google_location">Google Location</label>
                  <div className="position-relative">
                    <textarea
                      type="text"
                      id="google_location"
                      name="google_location"
                      rows={3}
                      {...register("google_location", { required: false })}
                      className={formclass(errors?.google_location)}
                      disabled={isFieldDisabled("google_location")}
                    />
                  </div>
                  {displayError(errors?.google_location?.message)}
                </div>
              </div>

              {/*  */}

              <div className="row">
                <div className="form-group col-md-6">
                  <label>Land Detail</label>
                  <textarea
                    className={formclass(errors?.land_detail)}
                    placeholder="Enter Land Detail"
                    name="land_detail"
                    id="land_detail"
                    rows={3}
                    {...register("land_detail", {
                      required: "Required",
                    })}
                    disabled={isFieldDisabled("land_detail")}
                  />
                  {displayError(errors?.land_detail?.message)}
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="site_condtion">Site Condition</label>
                  <div className="position-relative">
                    <input
                      type="file"
                      id="site_condtion"
                      name="site_condtion"
                      onChange={(e) => onFileInput(e, "site_condition")}
                      className={formclass(errors?.site_condtion)}
                    />
                  </div>
                  {displayError(errors?.site_condtion?.message)}
                  <a
                    href={`${FileUrl}/${image}`}
                    style={{
                      position: "absolute",
                      right: "-8px",
                      top: "50%",
                    }}
                    target={"_blank"}
                  >
                    <ReactSVG
                      src={`${eyeButtonSvg}`}
                      wrapper="span"
                      beforeInjection={(svg) => {
                        // svg.classList.add('svg-class-name')
                        svg.setAttribute("style", "width: 20px");
                      }}
                    />
                  </a>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="east_side">East Side</label>
                  <div className="position-relative">
                    <input
                      type="file"
                      id="east_side"
                      name="east_side"
                      onChange={(e) => onFileInput(e, "east_side")}
                      // {...register("google_image", { required: false })}
                      className={formclass(errors?.east_side)}
                    />
                  </div>
                  {displayError(errors?.east_side?.message)}
                  <a
                    href={`${FileUrl}/${eastimage}`}
                    style={{
                      position: "absolute",
                      right: "-8px",
                      top: "50%",
                    }}
                    target={"_blank"}
                  >
                    <ReactSVG
                      src={`${eyeButtonSvg}`}
                      wrapper="span"
                      beforeInjection={(svg) => {
                        // svg.classList.add('svg-class-name')
                        svg.setAttribute("style", "width: 20px");
                      }}
                    />
                  </a>
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="west_side">West Side</label>
                  <div className="position-relative">
                    <input
                      type="file"
                      id="west_side"
                      name="west_side"
                      onChange={(e) => onFileInput(e, "west_side")}
                      // {...register("google_image", { required: false })}
                      className={formclass(errors?.west_side)}
                    />
                  </div>
                  {displayError(errors?.west_side?.message)}
                  <a
                    href={`${FileUrl}/${westimage}`}
                    style={{
                      position: "absolute",
                      right: "-8px",
                      top: "50%",
                    }}
                    target={"_blank"}
                  >
                    <ReactSVG
                      src={`${eyeButtonSvg}`}
                      wrapper="span"
                      beforeInjection={(svg) => {
                        // svg.classList.add('svg-class-name')
                        svg.setAttribute("style", "width: 20px");
                      }}
                    />
                  </a>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="north_side">North Side</label>
                  <div className="position-relative">
                    <input
                      type="file"
                      id="north_side"
                      name="north_side"
                      onChange={(e) => onFileInput(e, "north_side")}
                      // {...register("google_image", { required: false })}
                      className={formclass(errors?.north_side)}
                    />
                  </div>
                  {displayError(errors?.north_side?.message)}
                  <a
                    href={`${FileUrl}/${northimage}`}
                    style={{
                      position: "absolute",
                      right: "-8px",
                      top: "50%",
                    }}
                    target={"_blank"}
                  >
                    <ReactSVG
                      src={`${eyeButtonSvg}`}
                      wrapper="span"
                      beforeInjection={(svg) => {
                        // svg.classList.add('svg-class-name')
                        svg.setAttribute("style", "width: 20px");
                      }}
                    />
                  </a>
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="south_side">South Side</label>
                  <div className="position-relative">
                    <input
                      type="file"
                      id="south_side"
                      name="south_side"
                      onChange={(e) => onFileInput(e, "south_side")}
                      // {...register("google_image", { required: false })}
                      className={formclass(errors?.south_side)}
                    />
                  </div>
                  {displayError(errors?.south_side?.message)}
                  <a
                    href={`${FileUrl}/${southimage}`}
                    style={{
                      position: "absolute",
                      right: "-8px",
                      top: "50%",
                    }}
                    target={"_blank"}
                  >
                    <ReactSVG
                      src={`${eyeButtonSvg}`}
                      wrapper="span"
                      beforeInjection={(svg) => {
                        // svg.classList.add('svg-class-name')
                        svg.setAttribute("style", "width: 20px");
                      }}
                    />
                  </a>
                </div>
              </div>

              <div className="form-group">
                <div>
                  <label className="d-block">Process</label>
                  <div className="d-flex">
                    <label className="mr-3 d-flex mb-0">
                      <input
                        type="radio"
                        value="1"
                        className="mr-1"
                        checked={selectedOption === "1"}
                        onChange={handleOptionChange}
                      />
                      Ongoing
                    </label>
                    <label className="mr-3 d-flex mb-0">
                      <input
                        type="radio"
                        value="2"
                        className="mr-1"
                        checked={selectedOption === "2"}
                        onChange={handleOptionChange}
                      />
                      Hold
                    </label>
                    <label className="mr-3 d-flex mb-0">
                      <input
                        type="radio"
                        value="3"
                        className="mr-1"
                        checked={selectedOption === "3"}
                        onChange={handleOptionChange}
                      />
                      Completed
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    value={0}
                    {...register("is_save")}
                  >
                    Save
                  </button>
                </div>
                <div className="form-group mb-0 col-md-4">
                  <button type="button" onClick={goBack} className="btn">
                    Back
                  </button>
                </div>
              </div>
            </fieldset>
          </form>
        ) : (
          <Loader
            type="spinner-default"
            bgColor={"#000000"}
            title={"Please wait"}
            color={"#000000"}
            size={50}
          />
        )}
      </div>
    </div>
  );
};

export default ProjectSummery;
