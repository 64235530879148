import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  memo,
  useRef,
} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GetDate } from "../../../../services/DateAndTimeService";
import { getToken } from "../../../../services/useLocalStorage";
import ApiService from "../../../../services/ApiService";
import toast from "react-hot-toast";
import { SwalAlert } from "../../../../UtilsComponent/SwalDialog";
import { image2svg } from "../../../../UtilsComponent/UtilFunctions";
import { FileUrl } from "../../../../config/file_url";
import {
  deleteFileExpense,
  getFileExpenseForAdmin,
  getFileExpenseForTeam,
} from "../../../../config/config";
import { FileExpenseRoute } from "../../../../config/RouteConfig";
import {
  exportToCsv,
  loadingTemplate,
  notFoundTemplate,
  ActionButtonJsx,
  actionButtonObject,
} from "../../../../services/FormCommon";
import {
  datatableHeight,
  paginationRows,
} from "../../../../config/CommonVariables";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  useSortBy,
  useTable,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { MyLoader } from "../../../../UtilsComponent/MyLoader";

//File Expense Team List
const Team_FileExpense_List = () => {
  const [expenses, setExpenses] = useState([]);

  const [amount, setAmount] = useState(0);
  const header = {
    Authorization: `Bearer ${getToken()}`,
    ["Content-Type"]: "application/json",
  };

  const [loading, setLoading] = useState(false);

  const hname = {
    date: "Date",
    file: "File",
    amount: "Amount",
    description: "Description",
    status: "Status",
    action: "action",
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "id",
        accessor: "id",
      },
      {
        Header: "Date",
        accessor: `${hname.date}`,
      },
      {
        Header: "File",
        accessor: `${hname.file}`,
      },
      {
        Header: "Amount",
        accessor: `${hname.amount}`,
      },
      {
        Header: "Description",
        accessor: `${hname.description}`,
      },

      {
        Header: "Status",
        accessor: `${hname.status}`,
      },
    ],
    []
  );

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "Edit",
        Header: "",
        Cell: ({ row }) => (
          <React.Fragment>
            <div className="dropdown">
              <button
                className="btn btn-secondary btn_action_menu"
                type="button"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <b>
                  <ActionButtonJsx />
                </b>
              </button>
              <div className="dropdown-menu">
                <Link
                  className="dropdown-item"
                  to={`/${FileExpenseRoute.edit}/${row?.values?.id}`}
                  style={{ color: "black" }}
                >
                  {actionButtonObject.edit}
                </Link>
                <a
                  className="dropdown-item"
                  role={"button"}
                  onClick={() => onRemoveExpense(row?.values?.id)}
                >
                  {actionButtonObject.delete}
                </a>
              </div>
            </div>
          </React.Fragment>
        ),
      },
    ]);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    page,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data: expenses,
      initialState: { hiddenColumns: ["id"], pageIndex: 0, pageSize: 10 },
    },
    tableHooks,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const Formate = (dateString, format) => {
    // Implement your date formatting logic here based on the input format
    // Example: Convert "2023-11-07T18:30:00.000Z" to "07-Nov-2023"
    const dateObj = new Date(dateString);
    const day = dateObj.getDate();
    const month = dateObj.toLocaleString("default", { month: "short" });
    const year = dateObj.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const fetchExpense = async () => {
    try {
      setLoading(true);
      const res = await ApiService(
        null,
        0,
        header,
        getFileExpenseForTeam.method,
        getFileExpenseForTeam.url
      );
      if (!res?.data?.error) {
        setLoading(false);

        const payload = res?.data?.data;
        // const total_deposit = res?.data?.amount ? parseFloat(res?.data?.amount) : res?.data?.total_deposit ? parseFloat(res?.data?.total_deposit) : 0;

        const total_deposit = res?.data?.final_total_amount
          ? parseFloat(res?.data?.final_total_amount)
          : 0;

        console.log("payload", payload);

        setAmount(total_deposit);

        if (Array.isArray(payload) && payload.length > 0) {
          const rows = [];
          payload.forEach((element) => {
            rows.push({
              id: element?.id,
              [hname.date]: element?.date
                ? GetDate(element?.date, "DD-MMM-YYYY")
                : "-",
              [hname.file]: element?.file_name,
              [hname.amount]: element?.amount,
              [hname.description]: element?.description,
              // [hname.status]:element?.is_approved==1?"Approved":(element?.is_approved==0?"Pending":"Rejected"),
              [hname.status]: (
                <span
                  className={
                    element?.is_approved === 1
                      ? "approved"
                      : element?.is_approved === 0
                      ? "pending"
                      : "rejected"
                  }
                >
                  {element?.is_approved === 1
                    ? "Approved"
                    : element?.is_approved === 0
                    ? "Pending"
                    : "Rejected"}
                </span>
              ),
              [hname.action]: element?.id,
            });
          });
          setExpenses(rows);
        } else {
        }
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);

      console.log(err);
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    fetchExpense();
  }, []);

  const removeExpense = async (id) => {
    try {
      setLoading(true);

      const res = await ApiService(
        null,
        parseInt(id),
        header,
        deleteFileExpense.method,
        deleteFileExpense.url
      );
      if (!res?.data?.error) {
        setLoading(false);

        const payload = res?.data?.data;
        const final_total_amount = payload?.final_total_amount
          ? payload?.final_total_amount
          : undefined;

        if (final_total_amount) {
          setAmount(final_total_amount);
        }

        toast.success(res?.data?.message);

        setList(id);
      } else {
        setLoading(false);

        toast.error(res?.data?.message);
      }
    } catch (err) {
      setLoading(false);

      console.log(err);
    }
  };

  const setList = (id) => {
    setExpenses((prevState) => {
      return prevState.filter((v) => {
        return v?.id != id;
      });
    });
  };

  const onRemoveExpense = (id) => {
    SwalAlert({})
      .then(async (result) => {
        if (result?.isConfirmed) {
          if (parseInt(id) != NaN) {
            await removeExpense(id);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="content__wrapper">
      <section className="page-content">
        <div className="page-title mobile-title">
          <h1 className="h4 mb-0">File Expense</h1>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="zed__table card">
              <h5 className="text-shadow-yellow px-2 mb-4">All File Expense</h5>
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-3 col-12">
                    <h4 className="table_amount_box">Total amount : <span> {amount}</span></h4>
                </div>

                <div className="col-lg-3 col-12">
                  <GlobalFilter
                    filter={globalFilter}
                    setFilter={setGlobalFilter}
                  />
                </div>
              </div>
              <div className="table-responsive">
                {loading ? (
                  <MyLoader value={false} />
                ) : (
                  <table
                    className="table expense_table datatable"
                    {...getTableProps()}
                  >
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render("Header")}
                              <span>
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? " 🔽"
                                    : " 🔼"
                                  : ""}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {/* {rows.map(row => { */}
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <td colSpan={6}>
                        <div className="pagination justify-content-end align-items-center">
                          <button
                            className="pagination_button"
                            onClick={() => gotoPage(0)}
                            disabled={!canPreviousPage}
                          >
                            {"<<"}
                          </button>
                          <button
                            className="pagination_button"
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                          >
                            {"<"}
                          </button>
                          <span>
                            Page{" "}
                            <strong>
                              {pageIndex + 1} of {pageOptions.length}
                            </strong>
                          </span>
                          <button
                            className="pagination_button"
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                          >
                            {">"}
                          </button>
                          <button
                            className="pagination_button"
                            onClick={() => gotoPage(pageCount - 1)}
                            disabled={!canNextPage}
                          >
                            {">>"}
                          </button>
                        </div>
                      </td>
                    </tfoot>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Team_FileExpense_List;

const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <span>
      <input
        className="form-control"
        type={"search"}
        style={{ margin: "5px" }}
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        placeholder="Search"
      />
    </span>
  );
};
