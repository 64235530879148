import React, {
  useEffect,
  useState,
  useMemo,

} from "react";
import { deletesites, getsites, totalclientbyid } from "../../../config/config";
import ApiService from "../../../services/ApiService";
import { GetDate } from "../../../services/DateAndTimeService";
import { getToken, isEmp } from "../../../services/useLocalStorage";
import { eyeButtonSvg, FileUrl } from "../../../config/file_url";

import { Link } from "react-router-dom";
import { SiteRoute } from "../../../config/RouteConfig";
import { SwalAlert } from "../../../UtilsComponent/SwalDialog";
import toast from "react-hot-toast";
import { ReactSVG } from "react-svg";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import { getCustomers } from "../../../services/CustomerService";
import { GetFile } from "../../../services/FileService";

import useValidator from "../../../services/Validation";


//sites_Expense
const List = () => {
  var header = {
    Authorization: `Bearer ${getToken()}`,
    ["Content-type"]: "application/json",
  };
  const [sites, setSites] = useState([]);
  const [file, setFile] = useState([]);
  const [custData, setCustData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState("");
  const [totalClient, setTotalClient] = useState("");
  const [selectedFileId, setSelectedFileId] = useState("");

  const [validator, showValidationMessage] = useValidator({
    rules: {
      remarks: "required|max:255", // Add any necessary validation rules here
    },
  });

  const handleInput = (event) => {
    const selectedValue = event.target.value;
    setSelectedClient(selectedValue);
    getTotal(selectedValue);
    localStorage.setItem("selectedClient", selectedValue);
    console.log("Selected Team:", selectedValue);
  };

  const filteredData = useMemo(() => {
    let filterClient = sites;

    if (selectedClient !== "") {
      filterClient = filterClient.filter((item) => {
        return item.client_id == selectedClient;
      });
    }

    if (selectedFileId !== "") {
      filterClient = filterClient.filter((item) => {
        return item.mst_file_project_id == selectedFileId;
      });
    }
    // Sort the todos in descending order based on ID
    filterClient = filterClient.sort((a, b) => b.id - a.id);

    return filterClient;
  }, [selectedClient, sites, selectedFileId]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await ApiService(
        null,
        0,
        header,
        getsites.method,
        getsites.url
      );
      console.log("res", res);
      if (!res?.data?.error) {
        setLoading(false);

        const payload = res?.data?.data;
        setSites(payload);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);

      console.log(err);
      toast.error("Something went wrong");
    }
  };

  const removeRow = (id) => {
    try {
      setSites((prevState) => {
        return prevState.filter((d) => {
          return parseInt(d?.id) != parseInt(id);
        });
      });
    } catch (err) {
      console.log(err);
    }
  };

  const removesites = async (id) => {
    try {
      setLoading(true);

      const res = await ApiService(
        null,
        id,
        header,
        deletesites.method,
        deletesites.url
      );
      if (!res?.data?.error) {
        setLoading(false);

        removeRow(id);
        toast.success(res?.data?.message);
      } else {
        setLoading(false);

        toast.error(res?.data?.message);
      }
    } catch (err) {
      setLoading(false);

      console.log(err);
      toast.error(
        err?.response?.data?.message
          ? err?.response?.data?.message
          : "Something went wrong"
      );
    }
  };

  const getTotal = async (selectedValue) => {
    try {
      setLoading(true);

      const res = await ApiService(
        null,
        selectedValue,
        header,
        totalclientbyid.method,
        totalclientbyid.url
      );
      if (!res?.data?.error) {
        setLoading(false);
        console.log("res===>", res?.data?.data[0]?.total_entries);
        setTotalClient(res?.data?.data[0]?.total_entries);
        toast.success(res?.data?.message);
      } else {
        setLoading(false);

        toast.error(res?.data?.message);
      }
    } catch (err) {
      setLoading(false);

      console.log(err);
      toast.error(
        err?.response?.data?.message
          ? err?.response?.data?.message
          : "Something went wrong"
      );
    }
  };

  const onRemovesites = (id) => {
    console.log("iddd==>", id);
    SwalAlert({})
      .then(async (result) => {
        if (result?.isConfirmed) {
          await removesites(id);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Unable to remove");
      });
  };

  const fetchCustomers = async () => {
    try {
      const res = await getCustomers(getToken());
      if (!res.data.error) {
        const custData = res.data?.data;
        custData ? setCustData(custData) : "";
      } else {
        toast.error(res.data?.message);
      }
    } catch (err) {
      console.log(err);
      if (err.response.request.status === 501) {
        toast.error("Internal server error");
      } else if (err.response.data?.error) {
        toast.error(err.response.data?.message);
      }
    }
  };

  useEffect(() => {
    fetchData();
    fetchFiles();
    fetchCustomers();
    const storedClient = localStorage.getItem("selectedClient");
    if (storedClient) {
      setSelectedClient(storedClient);
      getTotal(storedClient);
    }
    const storedfile = localStorage.getItem("selectedFileId");
    if (storedfile) {
      setSelectedFileId(storedfile);
    }
  }, []);

  const handleClear = () => {
    setSelectedClient("");
    setTotalClient("");
    localStorage.removeItem("selectedClient");
    localStorage.removeItem("selectedFileId");
    window.location.reload();
  };

  const handleFileSelect = (event) => {
    const selectedFileId = event.target.value;
    setSelectedFileId(selectedFileId);
  };

  const fetchFiles = async () => {
    try {
      const res = await GetFile(`${getToken()}`);
      if (!res.data.error) {
        setFile(res.data.data);
      } else {
        toast.error(res.data.message);
        navigate(`/${login.page}`, {
          state: { from: location },
          replace: true,
        });
      }
    } catch (err) {
      console.log(err);
      if (err.response.data.error) {
        toast.error(err.response.data.message);
        navigate(`/${login.page}`, {
          state: { from: location },
          replace: true,
        });
      }
    }
  };

  return (
    <div className="content__wrapper">
      <section className="page-content">
        <div className="page-title mobile-title">
          <h1 className="h4 mb-0">Site Inspection</h1>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="zed__table card">
              <h5 className="text-shadow-yellow px-2 mb-4">
                All Site Inspection
              </h5>
              <div className="row">
                <div className="form-group col-lg-3 col-sm-4">
                  <select
                    name="mst_file_project_id"
                    id="mst_file_project_id"
                    className="form-control"
                    onChange={(e) => handleFileSelect(e)}
                    value={sites.mst_file_project_id}
                  >
                    <option value="">Select File Name</option>
                    {file.map((file) => (
                      <option value={file.id}>{file.file_name}</option>
                    ))}
                  </select>
                  {validator.message(
                    "File",
                    sites.mst_file_project_id,
                    "required",
                    { className: "text-danger" }
                  )}
                </div>

                <div class="form-group col-lg-3 col-sm-4">
                  <select
                    className="form-control custom-select"
                    name="client_id"
                    onChange={handleInput}
                    value={sites.client_id}
                  >
                    <option value="">--Select Client--</option>
                    {custData.map((row) => (
                      <option value={row.id}>
                        {row.first_name} {row.last_name}
                      </option>
                    ))}
                  </select>
                  <span className="text-danger">
                    {validator.message(
                      "client_id",
                      sites.client_id,
                      "required"
                    )}
                  </span>
                </div>
                <div class="form-group col-lg-3 col-sm-2">
                  {selectedClient && selectedFileId ? (
                    <button className="btn btn-danger" onClick={handleClear}>
                      Clear Selection
                    </button>
                  ) : selectedClient ? (
                    <div>
                      <h3>Total = {totalClient}</h3>
                      <button className="btn btn-danger" onClick={handleClear}>
                        Clear Selection
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
              {filteredData.map((data, index) => (
                <div className="table-responsive" key={index}>
                  <div className="main-ward-box mb-4">
                    <div className="card_row row">
                      <div className="col-lg-5">
                        <Link className="ward_title"   to={`/${SiteRoute.edit}/${data?.id}`}>
                          {data.file_name} <span>({data.full_name})</span>
                        </Link>
                      </div>
                      <div className="col-lg-4">
                        <p className="heading_title">{data.type_name}</p>
                      </div>
                      <div className="col-lg-3">
                        <p className="heading_title text-right">
                          {GetDate(data.created_at, "DD-MMM-YYYY")}
                        </p>
                      </div>
                    </div>
                    <div className="card_box row">
                      <div className="col-lg-5">
                        <p className="card_info">
                          Inspected By - <span>{data.inspect}</span>
                        </p>
                        <p className="card_info">
                          Visit Work - <span>{data.title}</span>
                        </p>
                      </div>
                      <div className="col-lg-4">
                        <p className="card_info text-center">
                          Site Incharge - <span>{data.site_incharge}</span>
                        </p>
                        <p className="card_info text-center">
                          Client Signature - <span> <a
                          href={`${FileUrl}/${data?.client_sign}`}
                           
                          target={"_blank"}
                        >
                          <ReactSVG
                            src={`${eyeButtonSvg}`}
                            wrapper="span"
                            beforeInjection={(svg) => {
                              // svg.classList.add('svg-class-name')
                              svg.setAttribute("style", "width: 20px");
                            }}
                          />
                        </a></span>
                        </p>
                      </div>
                      <div className="col-lg-3">
                        {" "}
                        <div className="dropdown text-right">
                          <button
                            className="btn btn-secondary btn_action_menu"
                            // id={`${row?.value?.id}_click`}
                            type="button"
                            data-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <b>
                              <i class="fas fa-ellipsis-v"></i>
                            </b>
                          </button>

                          <div className="dropdown-menu">
                            <Link
                              className="dropdown-item"
                              to={`/${SiteRoute.edit}/${data?.id}`}
                            >
                              Edit
                            </Link>
                            <a
                              className="dropdown-item"
                              role={"button"}
                              onClick={() => onRemovesites(data.id)}
                            >
                              Remove
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default List;
