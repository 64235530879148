import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ArchitectureRoute from "../../../config/RouteConfig";
import {
  addhighRiseDetail,
  highrisedetailbyid,
  deletehighrisedetail,
} from "../../../config/config";
import toast from "react-hot-toast";
import { getToken } from "../../../services/useLocalStorage";
import ApiService from "../../../services/ApiService";
import { SwalAlert } from "../../../UtilsComponent/SwalDialog";
import { FileUrl } from "../../../config/file_url";

function BuildingForm() {
  const params = useParams();
  const [inputValue, setInputValue] = useState("");
  const [showCode, setShowCode] = useState(false);
  const [formDataArray, setFormDataArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState(false);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  var header = {
    Authorization: `Bearer ${getToken()}`,
    ["Content-type"]: "application/json",
  };

  const ProjectData = async () => {
    try {
      setLoading(true);
      const header = {
        Authorization: `Bearer ${getToken()}`,
        ["Content-Type"]: "application/json",
      };
      const res = await ApiService(
        null,
        parseInt(params?.id),
        header,
        highrisedetailbyid.method,
        highrisedetailbyid.url
      );
      if (!res.data.error) {
        setLoading(false);
        const data = res.data.data;
        // console.log("data-->", res);
        setFormDataArray(data);
        setInputValue(data.length.toString());
        setApiResponse(true);
      } else {
        setLoading(false);
        toast.error(res.data.message);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      if (err.response.data.error) {
        toast.error(err.response.data.message);
      }
    }
  };
  useEffect(() => {
    ProjectData();
  }, []);

  useEffect(() => {
    if (apiResponse) {
      setShowCode(true);
    }
  }, [apiResponse]);

  // const handleSaveClick = () => {
  //   setShowCode(true);
  //   setFormDataArray([]);
  //   for (let i = 0; i < parseInt(inputValue); i++) {
  //     setFormDataArray((prevFormDataArray) => [
  //       ...prevFormDataArray,
  //       {
  //         building_name: "",
  //         basement_type: "",
  //         floor_type: "",
  //         how_many_floor: "",
  //         per_floor_flat: "",
  //         flat_type: "",
  //       },
  //     ]);
  //   }
  // };
  // const handleSaveClick = () => {
  //   setShowCode(true);
  //   setFormDataArray([]);
  //   for (let i = 0; i < parseInt(inputValue); i++) {
  //     setFormDataArray((prevFormDataArray) => [
  //       ...prevFormDataArray,
  //       {
  //         building_name: "",
  //         basement_type: "",
  //         floor_type: "",
  //         how_many_floor: "",
  //         per_floor_flat: "",
  //         flat_type: "",
  //       },
  //     ]);
  //   }
  // };

  const handleAddClick = () => {
    setFormDataArray((prevFormDataArray) => [
      ...prevFormDataArray,
      {
        building_name: "",
        basement_type: "",
        floor_type: "",
        how_many_floor: "",
        per_floor_flat: "",
        flat_type: "",
      },
    ]);
  };

  const handleSaveClick = () => {
    setShowCode(true);
    setFormDataArray([]);
    for (let i = 0; i < parseInt(inputValue); i++) {
      setFormDataArray((prevFormDataArray) => [
        ...prevFormDataArray,
        {
          building_name: "",
          basement_type: "",
          floor_type: "",
          how_many_floor: "",
          per_floor_flat: "",
          flat_type: "",
        },
      ]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const dataToSend = {
        inputValue: inputValue,
        projectId: params.id,
        formData: formDataArray,
      };

      console.log("Form Data:", dataToSend);
      const res = await fetch(addhighRiseDetail.url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      });

      if (res.ok) {
        const responseData = await res.json();

        if (!responseData.error) {
          setLoading(false);
          toast.success(responseData.message);
          // navigate(`/${ArchitectureRoute.list}`);
        } else {
          setLoading(false);
          toast.error(responseData.message);
        }
      } else {
        setLoading(false);
        const errorData = await res.json();
        toast.error(
          errorData?.message
            ? errorData.message
            : "Something went wrong with the request"
        );
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
      toast.error(
        err?.message ? err.message : "Something went wrong with the request"
      );
    }
  };

  const handleFormFieldChange = (fieldName, value, index) => {
    setFormDataArray((prevFormDataArray) => {
      const updatedFormDataArray = [...prevFormDataArray];
      updatedFormDataArray[index][fieldName] = value;
      return updatedFormDataArray;
    });
  };

  const handleDeleteClick = (indexToDelete, id) => {
    SwalAlert({})
      .then(async (result) => {
        if (result?.isConfirmed) {
          await removeimage(id, indexToDelete);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Unable to remove");
      });
  };

  const removeimage = async (id, index) => {
    console.log("delete id ==>", id);
    try {
      setLoading(true);

      const res = await ApiService(
        null,
        id,
        header,
        deletehighrisedetail.method,
        deletehighrisedetail.url
      );
      if (!res?.data?.error) {
        setLoading(false);

        removeRow(index, id);
        toast.success(res?.data?.message);
      } else {
        setLoading(false);

        toast.error(res?.data?.message);
      }
    } catch (err) {
      setLoading(false);

      console.log(err);
      toast.error(
        err?.response?.data?.message
          ? err?.response?.data?.message
          : "Something went wrong"
      );
    }
  };

  const removeRow = (index, id) => {
    try {
      setFormDataArray((prevState) => {
        return prevState.filter((d, i) => {
          return i !== index;
        });
      });
    } catch (err) {
      console.log(err);
    }
  };

  const renderCode = () => {
    if (inputValue === "") {
      return null;
    }

    return formDataArray.map((formData, index) => (
      <div key={index}>
        <div className="card-body bg-white rounded-bottom p-3">
          {" "}
          <div className="inner_card_body position-relative">
            {/* <button
              className="btn btn-danger card_delete_btn"
              onClick={() => handleDeleteClick(index, formData.id)} // Pass index to identify which entry to delete
            >
              Delete
            </button> */}
            <img
              src={`${FileUrl}/img/icons/delete_icon.svg`}
              className="in__svg pr-2 card_delete_btn"
              alt="project"
              onClick={() => handleDeleteClick(index, formData.id)}
            />
            <div className="row">
              <div className="form-group col-md-6 col-sm-6">
                <label>Building Name*</label>
                <input
                  type="text"
                  name="building_name"
                  id="building_name"
                  value={formData.building_name}
                  placeholder="Enter Building Name."
                  className="form-control"
                  onChange={(e) =>
                    handleFormFieldChange(
                      "building_name",
                      e.target.value,
                      index
                    )
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6 col-sm-6">
                <div className="inner_card_body">
                  <label className="d-block">Basement Details</label>
                  <div className="d-flex">
                    <label className="d-flex align-items-center mr-3">
                      <input
                        type="radio"
                        value="yes"
                        className="mr-1"
                        checked={formData.basement === "yes"}
                        onChange={(e) =>
                          handleFormFieldChange(
                            "basement",
                            e.target.value,
                            index
                          )
                        }
                      />
                      Yes
                    </label>
                    <label className="d-flex align-items-center">
                      <input
                        type="radio"
                        value="no"
                        className="mr-1"
                        checked={formData.basement === "no"}
                        onChange={(e) =>
                          handleFormFieldChange(
                            "basement",
                            e.target.value,
                            index
                          )
                        }
                      />
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-group col-md-6">
                <div className="inner_card_body">
                  <label>Basement Type</label>
                  <select
                    className="form-control"
                    id="basement_type"
                    name="basement_type"
                    value={formData.basement_type}
                    onChange={(e) =>
                      handleFormFieldChange(
                        "basement_type",
                        e.target.value,
                        index
                      )
                    }
                  >
                    <option value={""}>--Select Basement Type--</option>
                    <option value="Single">Single</option>
                    <option value="Double">Double</option>
                    <option value="Triple">Triple</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6">
                <div className="inner_card_body">
                  <label>Floor Type</label>
                  <select
                    className="form-control"
                    id="floor_type"
                    name="floor_type"
                    value={formData.floor_type}
                    onChange={(e) =>
                      handleFormFieldChange("floor_type", e.target.value, index)
                    }
                  >
                    <option value={""}>--Select Floor Type--</option>
                    <option value="Flat">Flat</option>
                    <option value="Office">Office</option>
                  </select>
                </div>
              </div>
              <div className="form-group col-md-6 col-sm-6">
                <div className="inner_card_body">
                  <label>How Many Floor*</label>
                  <input
                    type="text"
                    name="how_many_floor"
                    id="how_many_floor"
                    value={formData.how_many_floor}
                    placeholder="Enter floor."
                    className="form-control"
                    onChange={(e) =>
                      handleFormFieldChange(
                        "how_many_floor",
                        e.target.value,
                        index
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6 col-sm-6">
                <div className="inner_card_body">
                  <label>Per Floor Flat*</label>
                  <input
                    type="text"
                    id="per_floor_flat"
                    name="per_floor_flat"
                    value={formData.per_floor_flat}
                    placeholder="Enter floor."
                    className="form-control"
                    onChange={(e) =>
                      handleFormFieldChange(
                        "per_floor_flat",
                        e.target.value,
                        index
                      )
                    }
                  />
                </div>
              </div>
              <div className="form-group col-md-6 col-sm-6">
                <div className="inner_card_body">
                  <label>Flat Type</label>
                  <input
                    type="text"
                    name="flat_type"
                    id="flat_type"
                    value={formData.flat_type}
                    placeholder="Enter floor."
                    className="form-control"
                    onChange={(e) =>
                      handleFormFieldChange("flat_type", e.target.value, index)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6"></div>
        </div>
      </div>
    ));
  };

  return (
    <div class="grey-border rounded">
      <div class="card-header bg-white">
        <h5 class="mb-0 font-weight-normal">High Rise Detail</h5>
      </div>
      <div class="card-body bg-white rounded-bottom p-3">
        <div className="inner_card_body">
          <h4>Building Details</h4>
          <label>
            Enter a number:
            <input
              type="text"
              className="form-control"
              value={inputValue}
              onChange={handleInputChange}
            />
          </label>
        
          <button
            onClick={handleSaveClick}
            className="btn btn-primary mx-3 d-block mt-3"
          >
            Save
          </button>
          
        </div>
        
      </div>
      {showCode && (
        <div className="">
          {renderCode()}
          <div className="d-flex align-items-center justify-content-between px-3 py-2">
            <button onClick={handleSubmit} className="btn btn-primary mt-2">
              Submit
            </button>
            <button
              onClick={handleAddClick}
              className="btn btn-primary mx-2 mt-2 small_btn"
            >
              +
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default BuildingForm;
