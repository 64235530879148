import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { DepositExpenseRoute } from "../../../config/RouteConfig";
import { getToken } from "../../../services/useLocalStorage";
import { displayLoader } from "../../../UtilsComponent/DisplayLoader";
import { useForm } from "react-hook-form";
import {
  formclass,
  displayError,
  checkFile,
} from "../../../services/ValidationService";
import { todayDate } from "../../../services/DateAndTimeService";
import { floatPattern } from "../../../services/Pattern";
import {
  fetchActiveEmployee,
  fetchEmployee,
  getDepositById,
  insertDeposit,
  updateDepositById,
} from "../../../config/config";
import ApiService from "../../../services/ApiService";
import toast from "react-hot-toast";
import { SanitizeDate } from "../../../services/FormCommon";

//Deposit_Expense
const Edit = () => {
  var header = {
    Authorization: `Bearer ${getToken()}`,
    ["Content-type"]: "application/json",
  };
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [teamId, setTeamId] = useState("");

  const {
    register,
    formState: { errors },
    reset,
    getValues,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
  } = useForm({
    mode: "onChange",
  });

  const handleButtonClick = () => {
    const expanse = localStorage.getItem("expanse");
    const path = localStorage.getItem("wallet");

    if (expanse && path) {
      localStorage.removeItem("expanse");
    //   localStorage.removeItem("wallet");
      navigate(path);
    } else {
      navigate("/deposit-expense");
    }
  };


  const goBack = () => {
    navigate(`/${DepositExpenseRoute.list}`);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const header = { Authorization: `Bearer ${getToken()}` };
      const res = await ApiService(
        null,
        parseInt(params?.id),
        header,
        getDepositById.method,
        getDepositById.url
      );
      if (!res.data.error) {
        setLoading(false);
        const payload = res?.data?.data;
        if (Array.isArray(payload) && payload?.length > 0) {
          reset({
            id: payload[0]?.id,
            amount: payload[0]?.amount,
            date: SanitizeDate(payload[0]?.date),
            description: payload[0]?.description,
            team_id: payload[0]?.team_id,
          });
          setTeamId(payload[0]?.team_id);
        }
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const res = await ApiService(
        data,
        0,
        header,
        updateDepositById.method,
        updateDepositById.url
      );
      if (!res?.data?.error) {
        setLoading(false);
        toast.success(res?.data?.message);
        const expanse = localStorage.getItem("expanse");
    const path = localStorage.getItem("wallet");
    if (expanse && path) {
          localStorage.removeItem("expanse");
        //   localStorage.removeItem("wallet");
          navigate(path);
        } else {
        navigate(`/${DepositExpenseRoute?.list}`);
        }
      
      } else {
        setLoading(false);
        toast.error(res?.data?.message);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.error(
        err?.response?.data?.message
          ? err?.response?.data?.message
          : "Something went wrong"
      );
    }
  };

  const getEmployees = async () => {
    try {
      const header = { Authorization: `Bearer ${getToken()}` };
      const res = await ApiService(
        null,
        0,
        header,
        fetchActiveEmployee.method,
        fetchActiveEmployee.url
      );
      if (!res.data.error) {
        const payload = res.data?.data;
        if (Array.isArray(payload) && payload.length) {
          setEmployees(payload);
          fetchData();
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getEmployees();
  }, []);

  return (
    <div className="content__wrapper">
      <section className="page-content">
        <div className="page-title mobile-title">
          <h1 className="h4 mb-0">Edit</h1>
          <p className="mb-4">Deposit</p>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card p-5">
              <div className="card-header bg-transparent border-0 mb-5 p-0">
                <div className="row align-items-center justify-content-between">
                  <div className="col-md-6">
                    <h6 className="title-line text-shadow-yellow mb-0 pb-3">
                      Edit Deposit
                    </h6>
                  </div>
                </div>
              </div>

              <div className="card-body p-0">
                {loading ? (
                  displayLoader(null)
                ) : (
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label for="amount">Amount*</label>
                        <input
                          type="text"
                          name="amount"
                          placeholder="Amount"
                          id="amount"
                          className={formclass(errors?.amount)}
                          {...register("amount", {
                            required: "Required",
                            pattern: {
                              value: floatPattern,
                              message: "Should be in correct form",
                            },
                            validate: (value) =>
                              value > 0 || "Should be greater than zero",
                          })}
                        />
                        {displayError(errors?.amount?.message)}
                      </div>
                      <div className="form-group col-md-6">
                        <label for="date">Date*</label>
                        <input
                          type="date"
                          name="date"
                          placeholder="Amount"
                          id="date"
                          className={formclass(errors?.date)}
                          {...register("date", {
                            required: "Required",
                          })}
                          defaultValue={todayDate()}
                        />
                        {displayError(errors?.date?.message)}
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col">
                        <label htmlFor="description">Description</label>
                        <textarea
                          name="description"
                          id="description"
                          cols="30"
                          rows="10"
                          className={formclass(errors?.description)}
                          {...register("description")}
                        ></textarea>
                        {displayError(errors?.description?.message)}
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col">
                        <label for="team_id">Team Member*</label>
                        <select
                          name="team_id"
                          id="team_id"
                          className={formclass(errors?.team_id)}
                          {...register("team_id", {
                            required: "Required",
                          })}
                          defaultValue={teamId}
                        >
                          <option value={""}>--Select--</option>
                          {employees.map((data) => (
                            <option key={data?.id} value={data?.id}>
                              {data?.first_name} {data?.last_name}
                            </option>
                          ))}
                        </select>
                        {displayError(errors?.team_id?.message)}
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group mb-0 col-md-2">
                        <button
                          type="submit"
                          className="btn"
                          // onClick={handleButtonClick}
                        >
                          Submit
                        </button>
                      </div>
                      <div className="form-group mb-0 col-md-4">
                        <button
                          type="submit"
                          onClick={handleButtonClick}
                          className="btn"
                        >
                          Back
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Edit;
