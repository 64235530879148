import React,{useEffect, useRef,useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { todayDate } from '../../../services/DateAndTimeService';
import { AddFileExpense } from '../../../services/FileExpenseService';
import { getToken } from '../../../services/useLocalStorage';
import useValidator from '../../../services/Validation';
import { GetFile } from '../../../services/FileService';
import Loader from 'react-js-loader';
import toast from 'react-hot-toast';
import {login, FileExpenseRoute } from '../../../config/RouteConfig';
import ApiService from '../../../services/ApiService';
import { insertFileExpense } from '../../../config/config';

//File_Expense
const Add = () => {
  const navigate = useNavigate();  
  
  const initialExpValue = {
      amount : "",
      mst_file_id : "",
      description :"",
      date:todayDate(),
      receipt_image:"",
      submitters_img1: "",
      submitters_img2:"",
      submitters_img3:""
  }

  const [expense,setExpense] = useState(()=>initialExpValue);
  const [file, setFile] = useState([]);
  const [loading,setLoading] = useState(false);

  const [validator, showValidationMessage] = useValidator()

  const fetchFiles = async () => {
    try{
        const res = await GetFile(`${getToken()}`);
        if(!res.data.error){
            setFile(res.data.data);
        }else{
            toast.error(res.data.message);
            navigate(`/${login.page}`,{state:{from:location},replace:true});
        }
    }catch(err){
        console.log(err);
        if(err.response.data.error){
            toast.error(err.response.data.message);
            navigate(`/${login.page}`,{state:{from:location},replace:true});
        }
    }
  };

  useEffect(() => {
    fetchFiles();
  }, []); 
 

  const goBack = () =>{
    navigate(`/${FileExpenseRoute.list}`);
  }  

  const handleInput = (e)=>{
    
    const {name,value} = e.target;

    showValidationMessage(true);

    setExpense((prevState)=>{
        return {...prevState,[name]:value}
    });
  }

  const onFileInput = (e) =>{
    const file = e.target.files[0];
    const name = e.target.name;
    setExpense((prevState)=>{
        return {...prevState,[name]:file}
    });
  }
    

  const handleSubmit = async (e) =>{
    e.preventDefault();

    if(!validator.allValid()) {
        showValidationMessage(true)
        return;
    } 

    const formData = new FormData();

    for(const key of Object.keys(expense))
    {
        formData.append(key,expense[key]);
    }
    console.log("FormData entries:");
    for(var pair of formData.entries())
    {
        console.log(pair[0],pair[1]);
    }
    console.log("Expense data:", expense);

    
    try{
        setLoading(true);
        // const res = await AddFileExpense(formData,`${getToken()}`);
        const header = {Authorization:`Bearer ${getToken()}`,['Content-Type']:'multipart/form-data'};
        const res = await ApiService(formData,0,header,insertFileExpense.method,insertFileExpense.url);
        if(!res.data?.error){
            toast.success(res.data?.message);
            setLoading(false);
            navigate(`/${FileExpenseRoute.list}`);
        }else{
            setLoading(false);
            toast.error(res.data?.message);
        }
    }catch(err){
        setLoading(false);
        console.log(err);
        toast.error(err.response.data?.message);
    }

  }

  return (
    <div className="content__wrapper">
        

        <section className="page-content">
                <div className="page-title mobile-title">
                    <h1 className="h4 mb-0">Add</h1>
                    <p className="mb-4">File Expense</p>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card p-5">
                            <div className="card-header bg-transparent border-0 mb-5 p-0">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-md-6">
                                        <h6 className="title-line text-shadow-yellow mb-0 pb-3">Add File Expense</h6>
                                    </div>
                                </div>
                            </div>
                             
                            <div className="card-body p-0">
                            {!loading ? 
                    <form onSubmit={handleSubmit} className="padding_space">
                       <div className="row">
                            <div className="col">
                                <label htmlFor="description">Description</label>
                                <textarea name="description" className='form-control' onChange={handleInput} value={expense.description}></textarea>
                                {validator.message("description",expense.description,"required",{className:"text-danger"})}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label htmlFor="amount">Amount*</label>
                                <input type="text" name="amount" className='form-control' id="amount" onChange={handleInput} value={expense.amount} placeholder='Amount'  />
                                {validator.message("amount", expense.amount, "required|numeric", { className: 'text-danger' })}
                            </div>
                            <div className="col">
                                <label htmlFor="filename">Date*</label>
                                <input type="date" name="date" className='form-control' onChange={handleInput} id="date" value={expense.date}/>
                                {validator.message("date",expense.date,"required",{className:"text-danger"})}
                            </div>
                            <div className="col">
                                <label htmlFor="filename">File Name</label>
                                <select name='mst_file_id' id="mst_file_id" className='form-control' onChange={handleInput} value={expense.mst_file_id}>
                                        <option value="">Select File Name</option>
                                        {file.map((file)=>(
                                            <option value={file.id}>{file.file_name}</option>
                                        ))}
                                </select>
                                {validator.message("File", expense.mst_file_id, "required", { className: 'text-danger' })}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label htmlFor="receipt">Add Receipt*</label>
                                <input type="file" name="receipt_image" className='form-control' style={{height:'auto'}} onChange={onFileInput} id="receipt_image"/>
                                <span className='text-danger'></span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <label htmlFor="submitters_img1">Add Submitters Image 1</label>
                                <input type='file' name="submitters_img1" id="submitters_img1" style={{height:'auto'}} onChange={onFileInput} className='form-control'></input>
                                <span className='text-danger'></span>
                            </div>
                            <div className="col">
                                <label htmlFor="submitters_img2">Add Submitters Image 2</label>
                                <input type='file' name="submitters_img2" id="submitters_img2" style={{height:'auto'}} onChange={onFileInput} className='form-control'></input>
                                <span className='text-danger'></span>
                            </div>
                            <div className="col">
                                <label htmlFor="submitters_img3">Add Submitters Image 3</label>
                                <input type='file' name="submitters_img3" id="submitters_img3" style={{height:'auto'}} onChange={onFileInput} className='form-control'></input>
                                <span className='text-danger'></span>
                            </div>
                        </div>
                           <br/>                     
                        <div className="row">
                            <div className="form-group mb-0 col-md-2">
                                <button type="submit" className="btn">Submit</button>
                            </div>
                            <div className="form-group mb-0 col-md-4">
                                <button type="button" onClick={goBack} className="btn">Back</button>
                            </div>              
                        </div>

                       
                    </form>
                    :<Loader type="spinner-default" bgColor={"#000000"} title={"Please wait"} color={'#000000'} size={50}/>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        
    </div>
  )
}

export default Add;