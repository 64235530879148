import {callApi} from '../utils';
import {fetchCust,deleteCust,insertCust,updateCust,fetchCustById} from '../config/config';

export const getCustomers = async (token) =>{
    try{
        const header = {Authorization : `bearer ${token}`,["Content-Type"]:"application/x-www-form-urlencoded"};
        const res = await callApi(fetchCust.method,fetchCust.url,null,0,header);
        return res;
    }catch(err){
        return err;
    }
}

export const getCustomerById = async (id,token) =>{
    try{
        const header = {Authorization : `Bearer ${token}`};
        const res = await callApi(fetchCustById.method,fetchCustById.url,null,id,header);
        return res;
    }catch(err){    
        return err;
    }
}

export const deleteCustomer = async (id,token) =>{
    try{
        const header = {Authorization : `Bearer ${token}`};
        const res = await callApi(deleteCust.method,deleteCust.url,{id},0,header);
        return res;
    }catch(err){
        return err;
    }
}

export const addCustomer = async (data,token) =>{
    try{
        const header = {Authorization : `Bearer ${token}`};
        const res = await callApi(insertCust.method,insertCust.url,data,0,header);
        return res;
    }catch(err){
        return err;
    } 
}

export const updateCustomer = async (data,token) =>{
    try{
        const header = {Authorization : `Bearer ${token}`};
        const res = await callApi(updateCust.method,updateCust.url,data,0,header);
        return res;
    }catch(err){
        return err;
    } 
}