import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  memo,
  useRef,
} from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { SwalAlert } from "../../../UtilsComponent/SwalDialog";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { GetDate, todayDate } from "../../../services/DateAndTimeService";
import { displayLoader } from "../../../UtilsComponent/DisplayLoader";
import {
  paginationRows,
  preview_date_format,
} from "../../../config/CommonVariables";
import {
  ActionButtonJsx,
  actionButtonObject,
  exportToCsv,
  loadingTemplate,
  notFoundTemplate,
} from "../../../services/FormCommon";
import { LeaveRoute } from "../../../config/RouteConfig";
import { getToken } from "../../../services/useLocalStorage";
import ApiService from "../../../services/ApiService";
import {
  deleteLeaveById,
  getLeaveByTeamIdForTeam,
} from "../../../config/config";
import toast from "react-hot-toast";
import {
  useSortBy,
  useTable,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { MyLoader } from "../../../UtilsComponent/MyLoader";

//Leaves
const List = () => {
  const [leaves, setLeaves] = useState([]);

  const [loading, setLoading] = useState(false);

  var header = {
    Authorization: `Bearer ${getToken()}`,
    ["Content-type"]: "application/json",
  };

  const hname = {
    title: "Title",
    from_date: "From Date",
    to_date: "To Date",
    status: "Status",
    created_at: "Applied at",
    action: "action",
  };

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "Edit",
        Header: "",
        Cell: ({ row }) => (
          <React.Fragment>
            <div className="dropdown">
              <button
                className="btn btn-secondary btn_action_menu"
                type="button"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <b>
                  <ActionButtonJsx />
                </b>
              </button>
              <div className="dropdown-menu">
                <Link
                  className="dropdown-item"
                  to={`/${LeaveRoute.edit}/${row?.values?.id}`}
                  style={{ color: "black" }}
                >
                  {actionButtonObject.edit}
                </Link>
                <a
                  className="dropdown-item"
                  role={"button"}
                  onClick={() => onRemoveLeave(row?.values?.id)}
                >
                  {actionButtonObject.delete}
                </a>
              </div>
            </div>
          </React.Fragment>
        ),
      },
    ]);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "id",
        accessor: "id",
      },
      {
        Header: "From date",
        accessor: `${hname.from_date}`,
      },
      {
        Header: "To date",
        accessor: `${hname.to_date}`,
      },
      {
        Header: "Status",
        accessor: `${hname.status}`,
      },
      {
        Header: "Applied at",
        accessor: `${hname.created_at}`,
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    page,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data: leaves,
      initialState: { hiddenColumns: ["id"], pageIndex: 0, pageSize: 100 },
    },
    tableHooks,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const actionRender = (p) => {
    return (
      <>
        <Link to={`/${LeaveRoute.edit}/${p?.value}`} style={{ color: "black" }}>
          <i class="fas fa-pencil-alt" aria-hidden="true"></i>
        </Link>
        &nbsp;
        <a role={"button"} onClick={() => onRemoveLeave(p?.value)}>
          <i class="fa fa-trash" aria-hidden="true"></i>
        </a>
      </>
    );
  };

  const getStatus = (num) => {
    switch (num) {
      case 1:
        return "Pending";
      case 2:
        return "Accepted";
      case 3:
        return "Rejected";
      default:
        return "";
    }
  };

  const getLeaveData = async () => {
    try {
      setLoading(true);
      const res = await ApiService(
        null,
        0,
        header,
        getLeaveByTeamIdForTeam.method,
        getLeaveByTeamIdForTeam.url
      );

      if (!res?.data?.error) {
        setLoading(false);

        const payload = res?.data?.data;

        const rows = [];

        payload.forEach((element, i) => {
          rows.push({
            id: element?.id,
            [hname.title]: element?.title,
            [hname.from_date]: element?.from_date
              ? GetDate(element?.from_date, preview_date_format)
              : "",
            [hname.to_date]: element?.to_date
              ? GetDate(element?.to_date, preview_date_format)
              : "",
            [hname.status]: getStatus(element?.status),
            [hname.created_at]: element?.created_at
              ? GetDate(element?.created_at, preview_date_format)
              : "",
            [hname.action]: element?.id,
          });
        });

        if (payload?.length == 0) {
        }

        setLeaves(rows);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);

      console.log(err);
    }
  };

  useEffect(() => {
    getLeaveData();
  }, []);

  const removeLeaveData = useCallback(async (id) => {
    try {
      setLoading(true);

      const res = await ApiService(
        null,
        id,
        header,
        deleteLeaveById.method,
        deleteLeaveById.url
      );

      if (!res?.data?.error) {
        setLoading(false);
        toast.success(res?.data?.message);

        setLeaves((prevState) => {
          return prevState.filter((e) => {
            return e?.id != id;
          });
        });
      } else {
        setLoading(false);
        toast.error(res?.data?.message);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }, []);

  const onRemoveLeave = useCallback(async (id) => {
    SwalAlert({})
      .then(async (result) => {
        if (result.isConfirmed) {
          await removeLeaveData(id);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      });
  }, []);

  return (
    <div className="content__wrapper">
      <section className="page-content">
        <div className="page-title mobile-title">
          <h1 className="h4 mb-0">Leaves</h1>
          {/*<p className="mb-4">Online Process</p>*/}
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="zed__table card">
              <h5 className="text-shadow-yellow px-2 mb-4">All Leaves</h5>
              <div className="row">
                <div className="col-lg-3 col-12">
                  <GlobalFilter
                    filter={globalFilter}
                    setFilter={setGlobalFilter}
                  />
                </div>
              </div>
              <div className="table-responsive">
                {loading ? (
                  <MyLoader value={false} />
                ) : (
                  <table className="table datatable" {...getTableProps()}>
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render("Header")}
                              <span>
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? " 🔽"
                                    : " 🔼"
                                  : ""}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {/* {rows.map(row => { */}
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <td colSpan={5}>
                        <div className="pagination justify-content-end align-items-center">
                          <button
                            className="pagination_button"
                            onClick={() => gotoPage(0)}
                            disabled={!canPreviousPage}
                          >
                            {"<<"}
                          </button>
                          <button
                            className="pagination_button"
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                          >
                            {"<"}
                          </button>
                          <span>
                            Page{" "}
                            <strong>
                              {pageIndex + 1} of {pageOptions.length}
                            </strong>
                          </span>
                          <button
                            className="pagination_button"
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                          >
                            {">"}
                          </button>
                          <button
                            className="pagination_button"
                            onClick={() => gotoPage(pageCount - 1)}
                            disabled={!canNextPage}
                          >
                            {">>"}
                          </button>
                        </div>
                      </td>
                    </tfoot>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default List;

const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <span>
      <input
        className="form-control"
        type={"search"}
        style={{ margin: "5px" }}
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        placeholder="Search"
      />
    </span>
  );
};
