import React, { useState, useEffect, useCallback } from "react";
import {
  getRole,
  getToken,
  getUserRoles,
  superAccess,
} from "../../../../services/useLocalStorage";
import Loader from "react-js-loader";
import toast from "react-hot-toast";
import { adminKey, customerKey, empKey } from "../../../../config/RoleConfig";
import { useForm } from "react-hook-form";
import {
  displayError,
  formclass,
} from "../../../../services/ValidationService";
import { eyeButtonSvg, FileUrl } from "../../../../config/file_url";
import ApiService from "../../../../services/ApiService";
import {
  getProjectTypes,
  projectbyid,
  addproject,
  updateProject,
} from "../../../../config/config";
import { SwalAlert } from "../../../../UtilsComponent/SwalDialog";
import axios from "axios";
import { useParams } from "react-router-dom";
import { AuthHeader } from "../../../../services/FormCommon";
import { ReactSVG } from "react-svg";

//FileDetails For Project
const ProjectSummery = () => {
  const params = useParams();

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    reset,
    trigger,
  } = useForm({
    mode: "onChange",
  });

  const [isSubmittable, setSubmittable] = useState(1);
  const [is_save, setIsSave] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [id, setid] = useState(0);
  const [image, setImage] = useState(null);

  const onFileInput = (e) => {
    const file = e.target.files[0];
    setValue("google_image", file); // Set the value in the form
    setSelectedImage(file);
  };

  console.log("selected", selectedImage);

  useEffect(() => {
    // Retrieve the value from local storage when the component mounts
    const storedValue = localStorage.getItem("selectedCheckboxes");
    if (storedValue) {
      setSelectedCheckboxes(storedValue);
    }
  }, []);

  const onSubmit = useCallback(
    async (data) => {
      const formData = new FormData();
      formData.append("project_name", data.project_name);
      formData.append("project_date", data.project_date);
      formData.append("address", data.address);
      formData.append("mst_project_type_id", selectedCheckboxes);
      formData.append("google_location", data.google_location);
      formData.append("is_save", data.is_save);
      formData.append("mst_file_id", params?.id);
      // formData.append("google_image", selectedImage);

      if (selectedImage == null) {
        formData.append("google_image", image);
      } else {
        formData.append("google_image", selectedImage);
      }

      console.log("formData===>", formData);

      try {
        setLoading(true);
        const header = { Authorization: `Bearer ${getToken()}` };

        let res;
        if (id == 0) {
          // If id is null or blank, add a new project
          res = await ApiService(
            formData,
            0,
            header,
            addproject.method,
            addproject.url
          );
        } else {
          // If id is not null or blank, update the project
          res = await ApiService(
            formData,
            0,
            header,
            updateProject.method,
            updateProject.url
          );
        }
        localStorage.setItem("selectedCheckboxes", selectedCheckboxes);
        if (!res.data.error) {
          setLoading(false);
          toast.success(res?.data?.message);
          if (data.is_save == 1) {
            // setSubmittable(1);
          }
        } else {
          toast.error(res?.data?.message ? res?.data?.message : "Server Error");
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        toast.error(
          err?.response?.data?.message
            ? err?.response?.data?.message
            : "Server Error"
        );
      }
    },
    [params?.id, selectedCheckboxes, selectedImage, image]
  );

  const getFileDetails = async () => {
    try {
      setLoading(true);
      const header = { Authorization: `Bearer ${getToken()}` };
      const res = await ApiService(
        null,
        parseInt(params?.id),
        header,
        projectbyid.method,
        projectbyid.url
      );
      // console.log('API URL:', apiUrl);
      console.log("API Response:", res);
      if (!res.data.error) {
        setLoading(false);
        const data =
          Object.keys(res?.data?.data).length === 0
            ? undefined
            : res?.data?.data[0];

        if (data) {
          setSelectedCheckboxes(data?.mst_project_type_id);
          setid(data?.id);
          if (data?.google_image) {
            setImage(data?.google_image);
          }

          const formattedDate = new Date(data?.project_date)
            .toISOString()
            .split("T")[0];

          reset({
            project_name: data?.project_name,
            project_date: formattedDate,
            address: data?.address,
            mst_project_type_id: data?.mst_project_type_id,
            google_location: data?.google_location,
            google_image: data?.google_image,
          });
        }
      } else {
        setLoading(false);
        setSubmittable(0);
      }
    } catch (err) {
      setLoading(false);
      if (err.response.data.error) {
        toast.error(err.response.data.message);
      }
    }
  };

  useEffect(() => {
    getFileDetails();
  }, []);
  // console.log("iiidddd===>",id)
  useEffect(() => {
    const fetchDataFromAPI = async () => {
      try {
        // Replace this with your API endpoint
        const response = await axios.get(getProjectTypes.url, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        });

        setData(response.data.data); // Use response.data directly
        console.log("response-->", response.data.data);
      } catch (error) {
        console.error("Error fetching data from API", error);
      }
    };

    fetchDataFromAPI();
  }, []);

  const handleCheckboxChange = (id) => {
    setSelectedCheckboxes(id);
    console.log("Selected Checkbox Value:", id);
  };
  console.log("selected--->", selectedCheckboxes);

  return (
    <div class="grey-border rounded">
      <div class="card-header bg-white">
        <h5 class="mb-0 font-weight-normal">Project Summery</h5>
      </div>
      <div class="card-body bg-white rounded-bottom p-3">
        {!loading ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              name="hiddenInput"
              value="hiddenValue"
              type="hidden"
              id="0"
            />
            <fieldset id="fieldset">
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="project_name">Project Name</label>
                  <input
                    type="text"
                    name="project_name"
                    id="project_name"
                    className={formclass(errors?.project_name)}
                    placeholder="Enter Name."
                    {...register("project_name", {
                      required: "Required",
                    })}
                  />
                  <input type="hidden" id="id" name="id" value={id} />
                  {displayError(errors?.project_name?.message)}
                </div>
                <div className="form-group col-md-6">
                  <label> Date</label>
                  <input
                    type="date"
                    className={formclass(errors?.project_date)}
                    id="project_date"
                    name="project_date"
                    {...register("project_date", {
                      required: "Required",
                    })}
                  />
                  {displayError(errors?.project_date?.message)}
                </div>
              </div>
              <div className="form-group">
                <label>Address</label>
                <textarea
                  className={formclass(errors?.address)}
                  placeholder="Enter Address"
                  name="address"
                  id="address"
                  rows={3}
                  {...register("address", {
                    required: "Required",
                  })}
                />
                {displayError(errors?.address?.message)}
              </div>
              <div className="form-group">
                {data.map((item) => (
                  <label key={item.id} className="d-block">
                    <input
                      type="checkbox"
                      name="mst_project_type_id"
                      id="mst_project_type_id"
                      value={item.id}
                      checked={selectedCheckboxes === item.id}
                      onChange={() => handleCheckboxChange(item.id)}
                      className="mx-2"
                      // {...register("mst_project_type_id", { required: false })}
                    />
                    {item.title}
                  </label>
                ))}
              </div>
              {/*  */}

              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="google_location">Google Location</label>
                  <div className="position-relative">
                    <input
                      type="text"
                      id="google_location"
                      name="google_location"
                      {...register("google_location", { required: false })}
                      className={formclass(errors?.google_location)}
                    />
                  </div>
                  {displayError(errors?.google_location?.message)}
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="google_image">Google Image</label>
                  <div className="position-relative">
                    <input
                      type="file"
                      id="google_image"
                      name="google_image"
                      onChange={onFileInput}
                      // {...register("google_image", { required: false })}
                      className={formclass(errors?.google_image)}
                    />
                  </div>
                  {displayError(errors?.google_image?.message)}
                  <a
                    href={`${FileUrl}/${image}`}
                    style={{
                      position: "absolute",
                      right: "-8px",
                      top: "50%",
                    }}
                    target={"_blank"}
                  >
                    <ReactSVG
                      src={`${eyeButtonSvg}`}
                      wrapper="span"
                      beforeInjection={(svg) => {
                        // svg.classList.add('svg-class-name')
                        svg.setAttribute("style", "width: 20px");
                      }}
                    />
                  </a>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    value={0}
                    {...register("is_save")}
                  >
                    Save
                  </button>
                </div>
              </div>
            </fieldset>
          </form>
        ) : (
          <Loader
            type="spinner-default"
            bgColor={"#000000"}
            title={"Please wait"}
            color={"#000000"}
            size={50}
          />
        )}
      </div>
    </div>
  );
};

export default ProjectSummery;
