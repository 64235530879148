import {useLocation,Navigate,Outlet} from 'react-router-dom';
import {getRole, getToken, getUserRoles, shouldAllowedOfRoles} from './useLocalStorage';

const RequireAuth = (props) => {
  
  const location = useLocation();
  const {accessible} = props;
  
  let isAuthentic = false;

  if(!getToken()){
    isAuthentic = false;
  }
  else{
    isAuthentic = true;
    shouldAllowedOfRoles(getUserRoles(),accessible)
  }


  return(
      <>
        {!isAuthentic ? <Navigate to="/login" state={{from:location}} replace/> : <Outlet/> }
      </>
  )

}

export default RequireAuth