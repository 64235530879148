import React,{useEffect, useState} from "react";
import {useNavigate} from 'react-router-dom';
import useLocalStorage, { getUserRoles, superAccess } from "../../services/useLocalStorage";
import Loader from "react-js-loader";
import toast, { Toaster } from 'react-hot-toast';
import {useForm} from 'react-hook-form';
import { mailPattern } from "../../services/Pattern";
import { displayError, formclass } from "../../services/ValidationService";
import ApiService from "../../services/ApiService";
import { userlogin } from "../../config/config";
import RouteConfig, { dashboard } from "../../config/RouteConfig";
import { eyeButtonSvg, FileUrl } from "../../config/file_url";
import { ReactSVG } from "react-svg";


const Login = () =>{

    const navigate = useNavigate();
    const [ loading, setLoading ] = useState(false);


    const {register,handleSubmit,formState:{errors}} = useForm({
        mode:"onChange"
    });

    const onSubmit = async (data) =>{
        
        try
        {
            setLoading(true);
            const header = {['Content-Type']:'application/json'};
            const email = data?.email ? data?.email.trim() : "";
            const password = data?.password ? data?.password?.trim() :"";

            if(email&&password)
            {
                const res = await ApiService({email,password},0,header,userlogin.method,userlogin.url);
                if(!res.data.error)
                {
                    setLoading(false);

                    const payload = res?.data?.data;
                    
                    const username = payload?.username ? payload?.username : "" ;
                    const fullname = payload?.fullname ? payload?.fullname :"" ;
                    const user_email = payload?.email ? payload?.email : "";
                    const first_name = payload?.first_name ? payload?.first_name : "";
                    const last_name = payload?.last_name ? payload?.last_name : "";
                    const profile_picture = payload?.profile_picture ? payload?.profile_picture : "";
                    const token = payload?.token ? payload?.token : "";

                    const userinfo = {username,profile_picture,fullname,first_name,last_name,user_email,token};
                    useLocalStorage("userinfo",userinfo);
                    toast.success(res?.data?.message);
                    setLoading(false);
                    navigate(`/${dashboard.list}`);

                }
                else
                {
                    setLoading(false);
                    toast.error(res?.data?.message);
                }
            }
            else
            {
                setLoading(false);
                toast.error("Unable to login the page");
            }
        }
        catch(err)
        {
            setLoading(false);
            console.log(err);
            toast.error(err?.response?.data?.message ? err?.response?.data?.message : "Something went wrong");
        }
    }

    useEffect(()=>{
        console.log(superAccess());
        console.log(getUserRoles());
    },[]);


    return (
        <>
        
        <main>


            <Toaster
                position="top-left"
                reverseOrder={false}
            />

        
        <section className="authentication--block">
            <div className="authentication__main d-flex">
           
                <div className="authentication__left">
                    <div className="authentication__left__inner">
                        <div className="header__logo">
                            <a href="https://www.zaidexceldesign.com/" target={"_blank"}>
                                <img src={`${FileUrl}/img/logo_dark.png`} alt=""/>
                            </a>
                        </div>
                       
                        <div className="header_message">
                            <h1>Hi, Welcome Back!</h1>
                        </div>
                          
                        <div className="authentication__form">
                         
                        {loading ? 
                        <Loader type="spinner-default" bgColor={"#000000"} title={"Please wait"} color={'#000000'} size={50} />:
                            <form onSubmit={handleSubmit(onSubmit)} aria-label="Login">
                                <div className="form-group">
                                    <label htmlFor="email">Email 
                                    {/* <ReactSVG 
                                        src={`${eyeButtonSvg}`}  
                                        wrapper="span"
                                        beforeInjection={(svg) => {
                                            // svg.classList.add('svg-class-name')
                                            svg.setAttribute('style', 'width: 30px')
                                          }}
                                        /> */}
                                        </label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                          <div className="input-group-text bg-transparent"><i className="fal fa-user"></i></div>
                                        </div>
                                        <input 
                                            id="email" 
                                            type="text"
                                            name="email" 
                                            {...register("email",
                                            {
                                                required:"Required",
                                                pattern:{
                                                    value:mailPattern,
                                                    message:"Invalid email"
                                                }
                                            })}
                                            placeholder="Email"
                                            className={formclass(errors?.email,"form-control border-left-0")}
                                        />
                                    </div>
                                    <p className="text-danger">{errors?.email?.message}</p>
                                    {displayError(errors?.email?.message)}
                                </div>

                                <div className="form-group">
                                    <label htmlFor="password">Password</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                          <div className="input-group-text bg-transparent"><i className="far fa-lock-alt"></i></div>
                                        </div>
                                        <input 
                                            type="password" 
                                            id="password" 
                                            name="password" 
                                            {...register("password",
                                            {required:"Required"})}
                                            placeholder="******"
                                            className={formclass(errors?.password,"form-control border-left-0")}
                                        />
                                    </div>
                                    <p className="text-danger">{errors?.password?.message}</p>
                                    {displayError(errors?.password?.message)}
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn d-block w-100 btn__login">Login</button>
                                </div>
                            </form>
                        }
                        
                        </div>
                        
                    </div>
                </div>
               
                <div className="authentication__right">
                    <div className="authentication__right__inner">
                        <img src={`${FileUrl}/img/contact_img_white.png`} alt=""/>
                    </div>
                </div>

            </div>
            
        </section>
    </main> 
        </>
    )
}

export default Login;