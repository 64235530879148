import React from 'react'

const NotFound = () => {
  return (
    <div className="container">
        <h2 className="text-center">Requested page not found</h2>
    </div>
  )
}

export default NotFound;