import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Login from "../pages/Auth/Login";
import Wrapper from "../components/Wrapper";
import Profile_details from "../components/Profile_details";
import Dashboard from "../pages/Dashboard/Dashboard";
import Elements from "../components/Elements";
import RequireAuth from "../services/RequireAuth";

import {
  CustRoute,
  dashboard,
  DepositExpenseRoute,
  elements,
  EmpRoute,
  FileExpenseRoute,
  FilesRoute,
  LeaveRoute,
  OfficeExpenseRoute,
  profile_details,
  ProjectRoute,
  SearchRoute,
  TodosRoute,
  SiteRoute,
  architectureRoute,
  liasoningRoute,
  InterioRoute,
} from "../config/RouteConfig";
import NotFound from "../pages/Auth/NotFound";
import { adminKey, customerKey, empKey, subadmin,HoD } from "../config/RoleConfig";

/*Employee pages*/
import ListEmp from "../pages/MainPages/employee/List";
import EditEmp from "../pages/MainPages/employee/Edit";
import AddEmp from "../pages/MainPages/employee/Add";
import TeamExpenseReport from "../pages/MainPages/employee/TeamExpenseReport";
import ListTeamProfileDetails from "../pages/MainPages/employee/TeamProfileDetails/List";
import EditProfile from "../pages/MainPages/employee/EditProfile";
/*-------------------------------------------------- */

/*Customer page*/
import ListCustomer from "../pages/MainPages/customer/List";
import AddCustomer from "../pages/MainPages/customer/Add";
import EditCustomer from "../pages/MainPages/customer/Edit";
/*-------------------------------------------------- */

/*File pages*/
import Details from "../pages/MainPages/file/FileDetails/Details";
import ListFile from "../pages/MainPages/file/List";
import EditFile from "../pages/MainPages/file/Edit";
import AddFile from "../pages/MainPages/file/Add";
import ListFileOnline from "../pages/MainPages/file/ListOnlineProcess";
import ListFileOffline from "../pages/MainPages/file/ListOfflineProcess";
import ListFileApproved from "../pages/MainPages/file/ListApproved";
import ListFileReject from "../pages/MainPages/file/ListReject";
import ListFileBuc from "../pages/MainPages/file/ListBUC";
import ListTodoOfFile from "../pages/MainPages/file/ListTodoOfFile";
/*-------------------------------------------------- */

/*File expense pages*/
import FileExpense from "../pages/MainPages/file_expense/List";
import AddFileExpense from "../pages/MainPages/file_expense/Add";
import EditFileExpense from "../pages/MainPages/file_expense/Edit";
import PreviewFileExpense from "../pages/MainPages/file_expense/PreviewFileExpense";
/*-------------------------------------------------- */

/*Office expense pages*/
import OfficeExpense from "../pages/MainPages/office_expense/List";
import AddOfficeExpense from "../pages/MainPages/office_expense/Add";
import EditOfficeExpense from "../pages/MainPages/office_expense/Edit";
import PreviewOfficeExpense from "../pages/MainPages/office_expense/PreviewOfficeExpense";
/*-------------------------------------------------- */

/*Deposit Expense pages*/
import DepositList from "../pages/MainPages/deposit_expense/List";
import DepositAdd from "../pages/MainPages/deposit_expense/Add";
import DepositEdit from "../pages/MainPages/deposit_expense/Edit";
/*************************************************/

/*Todo*/
import ListTodo from "../pages/MainPages/Todo/ListTodo";
import EditTodo from "../pages/MainPages/Todo/EditTodo";
import AddTodo from "../pages/MainPages/Todo/AddTodo";
import PreviewTodo from "../pages/MainPages/Todo/PreviewTodo";
import MarkTodoForm from "../pages/MainPages/Todo/MarkTodoForm";
import ListExpense from "../pages/MainPages/file/ListExpense";
import HodPreviewTodo from "../pages/MainPages/Todo/HodPreviewTodo";
/*************************************************/

/* Leave */
import ListLeave from "../pages/MainPages/Leaves/List";
import AddLeave from "../pages/MainPages/Leaves/Add";
import EditLeave from "../pages/MainPages/Leaves/Edit";
import ListofTeamLeave from "../pages/MainPages/Leaves/ListofTeam";
import PreviewLeave from "../pages/MainPages/Leaves/Preview";

/*Project*/
import ListOnlineProject from "../pages/MainPages/projects/ListOnlineProject";
import ListOfflineProject from "../pages/MainPages/projects/ListOfflineProject";
import ListApprovedProject from "../pages/MainPages/projects/ListApprovedProject";
import ListBUCProject from "../pages/MainPages/projects/ListBUCProject";
import ListRejectProject from "../pages/MainPages/projects/ListRejectProject";
// import ProjectDetails from '../pages/projects/Details';
import ProjectDetails from "../pages/MainPages/projects/ProjectDetails/Details";
import ListProject from "../pages/MainPages/projects/ListProject";

/* Site Inspection*/
import ListSite from "../pages/MainPages/SiteInspection/List";
import AddSite from "../pages/MainPages/SiteInspection/Add";
import EditSite from "../pages/MainPages/SiteInspection/Edit";
import Emplist from "../pages/MainPages/SiteInspection/EmpList";
import Previewlist from "../pages/MainPages/SiteInspection/Preview";

import Search from "../components/Search";
import Sample from "../components/Sample";
import ReactPdfDemo from "../pages/Demo/ReactPdfDemo";
import ReactTableDemo from "../pages/Demo/ReactTableDemo";

//Pdfs
import TeamExportReportPdf from "../pages/MainPages/employee/TeamExportReportPdf";
import GenerateFileExpensePdf from "../pages/MainPages/file/GenerateFileExpensePdf";

// architecture
import AddArchitecture from "../pages/MainPages/architecture/AddArchitecture";
import MstProjectDetails from "../pages/MainPages/architecture/Details";
import EditProject from "../pages/MainPages/architecture/editProject";
import ProjectSummery from "../pages/MainPages/architecture/AddProject";
import Architecturelist from "../pages/MainPages/architecture/list";

// liasoning
import AddLiasoning from "../pages/MainPages/file/AddLiasoning";

const Application = () => {
  process.env.TZ = "Asia/Culcutta";
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />

      {/* Login Page */}
      <Route path="/" element={<Login />}>
        <Route path="login" element={<Login />} />
      </Route>

      <Route path="/test_pdf" element={<Sample />} />

      <Route path="/test_pdf1" element={<ReactPdfDemo />} />

      <Route path="/test_table" element={<ReactTableDemo />} />

      {/* This should be protected */}

      <Route element={<RequireAuth accessible={[adminKey, empKey]} />}>
        <Route
          path={`/${dashboard.list}`}
          element={
            <Wrapper headerTitle={"Dashboard"} children={<Dashboard />} />
          }
        />
      </Route>

      <Route element={<RequireAuth />}>
        <Route
          path={`/${elements.list}`}
          element={<Wrapper children={<Elements />} />}
        />
      </Route>

      <Route element={<RequireAuth />}>
        <Route
          path={`/${profile_details.list}`}
          element={
            <Wrapper
              obj={{ profile_details: true }}
              children={<Profile_details />}
            />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey, empKey]} />}>
        <Route
          path={`/${FilesRoute.list}`}
          element={<Wrapper children={<ListFile />} />}
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey, empKey]} />}>
        <Route
          path={`/${FilesRoute.listFileOnline}`}
          element={
            <Wrapper
              headerTitle={"File (Online Process)"}
              children={<ListFileOnline />}
            />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey, empKey]} />}>
        <Route
          path={`/${FilesRoute.listFileOffline}`}
          element={
            <Wrapper
              headerTitle={"File (Offline Process)"}
              children={<ListFileOffline />}
            />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey, empKey]} />}>
        <Route
          path={`/${FilesRoute.listFileBuc}`}
          element={
            <Wrapper headerTitle={"File (BUC)"} children={<ListFileBuc />} />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey, empKey]} />}>
        <Route
          path={`/${FilesRoute.file_todos}/:id`}
          element={
            <Wrapper headerTitle={"File Todos"} children={<ListTodoOfFile />} />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey, empKey]} />}>
        <Route
          path={`/${FilesRoute.listFileApproved}`}
          element={
            <Wrapper
              headerTitle={"File (Approved)"}
              children={<ListFileApproved />}
            />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey, empKey]} />}>
        <Route
          path={`/${FilesRoute.listFileReject}`}
          element={
            <Wrapper
              headerTitle={"File (Reject)"}
              children={<ListFileReject />}
            />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey]} />}>
        <Route
          path={`/${FilesRoute.listFileExpense}/:id`}
          element={
            <Wrapper headerTitle={"File Expense"} children={<ListExpense />} />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey]} />}>
        <Route
          path={`/${FilesRoute.file_expense_pdf}/:id`}
          element={<GenerateFileExpensePdf />}
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey, empKey]} />}>
        <Route
          path={`/${FilesRoute.add}`}
          element={<Wrapper headerTitle={"Add File"} children={<AddFile />} />}
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey, empKey]} />}>
        <Route
          path={`/${FilesRoute.edit}/:id`}
          element={
            <Wrapper headerTitle={"Edit File"} children={<EditFile />} />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey, empKey]} />}>
        <Route
          path={`/${FilesRoute.fileDetail}/:id`}
          element={
            <Wrapper headerTitle={"File Details"} children={<Details />} />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey]} />}>
        <Route
          path={`/${EmpRoute.list}`}
          element={<Wrapper headerTitle={"Teams"} children={<ListEmp />} />}
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey]} />}>
        <Route
          path={`/${EmpRoute.deposit_expense_report}/:id`}
          element={
            <Wrapper
              headerTitle={"Team Expense Report"}
              children={<TeamExpenseReport />}
            />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey]} />}>
        <Route
          path={`${EmpRoute.deposit_expense_report_pdf}/:id`}
          element={<TeamExportReportPdf />}
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey]} />}>
        <Route
          path={`${EmpRoute.team_profile_details}/:id`}
          element={
            <Wrapper
              headerTitle={"Team Profile"}
              children={<ListTeamProfileDetails />}
            />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[empKey]} />}>
        <Route
          path={`/${EmpRoute.EditProfile}/:id`}
          element={
            <Wrapper headerTitle={"Profile"} children={<EditProfile />} />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey]} />}>
        <Route
          path={`/${EmpRoute.add}`}
          element={<Wrapper headerTitle={"Add Team"} children={<AddEmp />} />}
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey]} />}>
        <Route
          path={`/${EmpRoute.edit}/:id`}
          element={<Wrapper headerTitle={"Edit Team"} children={<EditEmp />} />}
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey]} />}>
        <Route
          path={`/${CustRoute.list}`}
          element={
            <Wrapper headerTitle={"Clients"} children={<ListCustomer />} />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey]} />}>
        <Route
          path={`/${CustRoute.add}`}
          element={
            <Wrapper headerTitle={"Add Client"} children={<AddCustomer />} />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey]} />}>
        <Route
          path={`/${CustRoute.edit}/:id`}
          element={
            <Wrapper headerTitle={"Edit Client"} children={<EditCustomer />} />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey]} />}>
        <Route
          path={`/${DepositExpenseRoute.list}`}
          element={
            <Wrapper headerTitle={"Deposit"} children={<DepositList />} />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey]} />}>
        <Route
          path={`/${DepositExpenseRoute.add}`}
          element={
            <Wrapper headerTitle={"Add Deposit"} children={<DepositAdd />} />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey]} />}>
        <Route
          path={`/${DepositExpenseRoute.edit}/:id`}
          element={
            <Wrapper headerTitle={"Edit Deposit"} children={<DepositEdit />} />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey, empKey]} />}>
        <Route
          path={`/${FileExpenseRoute.list}`}
          element={
            <Wrapper headerTitle={"File Expenses"} children={<FileExpense />} />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey, empKey]} />}>
        <Route
          path={`/${FileExpenseRoute.edit}/:id`}
          element={
            <Wrapper
              headerTitle={"Edit File Expense"}
              children={<EditFileExpense />}
            />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey, empKey]} />}>
        <Route
          path={`/${FileExpenseRoute.add}`}
          element={
            <Wrapper
              headerTitle={"Add File Expense"}
              children={<AddFileExpense />}
            />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey, empKey]} />}>
        <Route
          path={`/${OfficeExpenseRoute.list}`}
          element={
            <Wrapper
              headerTitle={"Office Expense"}
              children={<OfficeExpense />}
            />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey, empKey]} />}>
        <Route
          path={`/${OfficeExpenseRoute.add}`}
          element={
            <Wrapper
              headerTitle={"Add Office Expense"}
              children={<AddOfficeExpense />}
            />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey, empKey]} />}>
        <Route
          path={`/${OfficeExpenseRoute.edit}/:id`}
          element={
            <Wrapper
              headerTitle={"Edit Office Expense"}
              children={<EditOfficeExpense />}
            />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey, empKey]} />}>
        <Route
          path={`/${FileExpenseRoute.preview}/:id`}
          element={
            <Wrapper
              headerTitle={"Preview File Expense"}
              children={<PreviewFileExpense />}
            />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey, empKey]} />}>
        <Route
          path={`/${OfficeExpenseRoute.preview}/:id`}
          element={
            <Wrapper
              headerTitle={"Preview Office Expense"}
              children={<PreviewOfficeExpense />}
            />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey, subadmin]} />}>
        <Route
          path={`/${TodosRoute.add}`}
          element={<Wrapper headerTitle={"Add Todo"} children={<AddTodo />} />}
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey, subadmin]} />}>
        <Route
          path={`/${TodosRoute.edit}/:id`}
          element={
            <Wrapper headerTitle={"Edit Todo"} children={<EditTodo />} />
          }
        />
      </Route>

      <Route
        element={<RequireAuth accessible={[adminKey, empKey, subadmin]} />}
      >
        <Route
          path={`/${TodosRoute.list}`}
          element={<Wrapper headerTitle={"Todos"} children={<ListTodo />} />}
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey, subadmin]} />}>
        <Route
          path={`/${TodosRoute.reviewtodo}/:id`}
          element={
            <Wrapper headerTitle={"Review Todo"} children={<PreviewTodo />} />
          }
        />
        <Route
          path={`/${TodosRoute.hodreviewtodo}/:id`}
          element={
            <Wrapper
              headerTitle={"HOD Review Todo"}
              children={<HodPreviewTodo />}
            />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey, empKey]} />}>
        <Route
          path={`/${architectureRoute.list}`}
          element={
            <Wrapper headerTitle={"Architecture"} children={<Architecturelist />} />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={adminKey} />}>
        <Route
          path={`/${architectureRoute.add}`}
          element={
            <Wrapper headerTitle={"Add Architecture"} children={<ProjectSummery />} />
          }
        />
      </Route>
      <Route element={<RequireAuth accessible={adminKey} />}>
        <Route
          path={`/${architectureRoute.edit}/:id`}
          element={
            <Wrapper
              headerTitle={"edit Project"}
              children={<EditProject />}
            />
          }
        />
      </Route>
      <Route element={<RequireAuth accessible={[adminKey, empKey]} />}>
        <Route
          path={`/${architectureRoute.details}/:id`}
          element={
            <Wrapper
              headerTitle={"Project Details"}
              children={<MstProjectDetails />}
            />
          }
        />
      </Route>
      <Route element={<RequireAuth accessible={adminKey} />}>
        <Route
          path={`/${liasoningRoute.add}`}
          element={
            <Wrapper headerTitle={"Add Liasoning Details"} children={<AddLiasoning />} />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[empKey]} />}>
        <Route
          path={`/${TodosRoute.markTodo}/:id`}
          element={
            <Wrapper headerTitle={"Mark Todo"} children={<MarkTodoForm />} />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey, empKey]} />}>
        <Route
          path={`/${SearchRoute.list}/:key`}
          element={
            <Wrapper headerTitle={"Search Result"} children={<Search />} />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey, empKey]} />}>
        <Route
          path={`/${LeaveRoute.list}`}
          element={<Wrapper headerTitle={"Leaves"} children={<ListLeave />} />}
        />
      </Route>

      <Route element={<RequireAuth accessible={[empKey]} />}>
        <Route
          path={`/${LeaveRoute.add}`}
          element={
            <Wrapper headerTitle={"Add Leave"} children={<AddLeave />} />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[empKey]} />}>
        <Route
          path={`/${LeaveRoute.edit}/:id`}
          element={
            <Wrapper headerTitle={"Edit Leave"} children={<EditLeave />} />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey]} />}>
        <Route
          path={`/${LeaveRoute.listLeaveOfTeam}/:id`}
          element={
            <Wrapper
              headerTitle={"Leaves (Team)"}
              children={<ListofTeamLeave />}
            />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey]} />}>
        <Route
          path={`/${LeaveRoute.previewLeave}/:id`}
          element={
            <Wrapper
              headerTitle={"Team Preview Leave"}
              children={<PreviewLeave />}
            />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey, empKey]} />}>
        <Route
          path={`/${FilesRoute.listFileProject}`}
          element={
            <Wrapper headerTitle={"Projects"} children={<ListProject />} />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey, empKey]} />}>
        <Route
          path={`/${ProjectRoute.projectDetails}/:id`}
          element={
            <Wrapper
              headerTitle={"Project Details"}
              children={<ProjectDetails />}
            />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey]} />}>
        <Route
          path={`/${SiteRoute.list}`}
          element={
            <Wrapper headerTitle={"Site-Inspection"} children={<ListSite />} />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey]} />}>
        <Route
          path={`/${SiteRoute.add}`}
          element={
            <Wrapper headerTitle={"Site-Inspection"} children={<AddSite />} />
          }
        />
      </Route>

      <Route element={<RequireAuth accessible={[adminKey]} />}>
        <Route
          path={`/${SiteRoute.edit}/:id`}
          element={
            <Wrapper headerTitle={"Site-Inspection"} children={<EditSite />} />
          }
        />
      </Route>
      <Route element={<RequireAuth accessible={[empKey]} />}>
        <Route
          path={`/${SiteRoute.emplist}`}
          element={
            <Wrapper headerTitle={"Site-Inspection"} children={<Emplist />} />
          }
        />
      </Route>
      <Route element={<RequireAuth accessible={[empKey]} />}>
        <Route
          path={`/${SiteRoute.preview}/:id`}
          element={
            <Wrapper
              headerTitle={"Site-Inspection"}
              children={<Previewlist />}
            />
          }
        />
      </Route>

      {/* <Route element={<RequireAuth accessible={[adminKey,empKey]}/>}>
    <Route path={`/${ProjectRoute.listProjectOnline}`} element={<Wrapper  children={<ListOnlineProject/>}/>}/>
  </Route>

  <Route element={<RequireAuth accessible={[adminKey,empKey]}/>}>
    <Route path={`/${ProjectRoute.listProjectOffline}`} element={<Wrapper  children={<ListOfflineProject/>}/>}/>
  </Route>

  <Route element={<RequireAuth accessible={[adminKey,empKey]}/>}>
    <Route path={`/${ProjectRoute.listProjectApproved}`} element={<Wrapper  children={<ListApprovedProject/>}/>}/>
  </Route>

  <Route element={<RequireAuth accessible={[adminKey,empKey]}/>}>
    <Route path={`/${ProjectRoute.listProjectBUC}`} element={<Wrapper  children={<ListBUCProject/>}/>}/>
  </Route>

  <Route element={<RequireAuth accessible={[adminKey,empKey]}/>}>
    <Route path={`/${ProjectRoute.listProjectReject}`} element={<Wrapper children={<ListRejectProject/>}/>}/>
  </Route>

  */}

      {/* ------------------------------- */}
    </Routes>
  );
};

export default Application;
