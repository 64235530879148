const URL = process.env.REACT_APP_API_URL;
// "http://localhost:5600/api";
module.exports = {
  userlogin: {
    method: "POST",
    url: `${URL}/login`,
  },
  dashboardInfo: {
    method: "GET",
    url: `${URL}/getDashboardInfo`,
  },
  dashboardInfoForTeam: {
    method: "GET",
    url: `${URL}/getDashboardInfo-team`,
  },
  getNotification: {
    method: "GET",
    url: `${URL}/getNotification`,
  },
  getTotalIncomeExpense: {
    method: "GET",
    url: `${URL}/get-total_income_expense`,
  },
  getNotification_employee: {
    method: "GET",
    url: `${URL}/notification-employee`,
  },
  getNotification_admin: {
    method: "GET",
    url: `${URL}/notification`,
  },
  SendToSeenNotification: {
    method: "POST",
    url: `${URL}/notification-seen`,
  },
  insertEmployee: {
    method: "POST",
    url: `${URL}/employee/add/users`,
  },
  fetchEmployee: {
    method: "POST",
    url: `${URL}/employee/getEmployee`,
  },
  fetchActiveEmployee: {
    method: "GET",
    url: `${URL}/employee/getActiveEmployee`,
  },
  deleteEmployee: {
    method: "POST",
    url: `${URL}/employee/delete`,
  },
  fetchEmployeeById: {
    method: "GET",
    url: `${URL}/employee`,
  },
  updateEmployeeById: {
    method: "POST",
    url: `${URL}/employee/update/users`,
  },
  fetchCust: {
    method: "POST",
    url: `${URL}/customer/getCustomers`,
  },
  deleteCust: {
    method: "POST",
    url: `${URL}/customer/delete`,
  },
  insertCust: {
    method: "POST",
    url: `${URL}/customer/add/users`,
  },
  updateCust: {
    method: "POST",
    url: `${URL}/customer/update/users`,
  },
  fetchCustById: {
    method: "GET",
    url: `${URL}/customer`,
  },
  // insertFile: {
  //   method: "POST",
  //   url: `${URL}/file/add`,
  //   foldername: "files",
  // },
  insertFile: {
    method: "POST",
    url: `${URL}/file/add`,
  },
  fetchFile: {
    method: "POST",
    url: `${URL}/file/getFile`,
  },
  fetchFileOfOnline: {
    method: "GET",
    url: `${URL}/file/getFileOfOnline`,
  },
  fetchFileOfOnlineForTeam: {
    method: "GET",
    url: `${URL}/file/getFileOfOnline-team`,
  },
  fetchFileOfOffline: {
    method: "GET",
    url: `${URL}/file/getFileOfOffline`,
  },
  fetchFileOfOfflineForTeam: {
    method: "GET",
    url: `${URL}/file/getFileOfOffline-team`,
  },
  fetchFileOfBUC: {
    method: "GET",
    url: `${URL}/file/getFileOfBUC`,
  },
  fetchFileOfBUCForTeam: {
    method: "GET",
    url: `${URL}/file/getFileOfBUC-team`,
  },
  fetchFileOfApproved: {
    method: "GET",
    url: `${URL}/file/getFileOfApproved`,
  },
  fetchFileOfApprovedForTeam: {
    method: "GET",
    url: `${URL}/file/getFileOfApproved-team`,
  },
  fetchFileOfReject: {
    method: "GET",
    url: `${URL}/file/getFileOfReject`,
  },
  fetchFileOfRejectForTeam: {
    method: "GET",
    url: `${URL}/file/getFileOfReject-team`,
  },
  deleteFile: {
    method: "POST",
    url: `${URL}/file/delete`,
  },
  fetchFileById: {
    method: "GET",
    url: `${URL}/file`,
  },
  updateFileById: {
    method: "POST",
    url: `${URL}/file/update`,
    foldername: "files",
  },
  constantMst_Type: {
    method: "POST",
    url: `${URL}/constant/getMst_Type`,
  },
  constantStatus_File: {
    method: "POST",
    url: `${URL}/constant/getStatus_File`,
  },
  insertDeposit: {
    method: "POST",
    url: `${URL}/expenses/add-deposit`,
  },
  getDeposit: {
    method: "GET",
    url: `${URL}/expenses/get-deposit`,
  },
  getDepositById: {
    method: "GET",
    url: `${URL}/expenses/get-deposit-byid`,
  },
  deleteDepositById: {
    method: "DELETE",
    url: `${URL}/expenses/delete-deposit-byid`,
  },
  updateDepositById: {
    method: "POST",
    url: `${URL}/expenses/update-deposit-byid`,
  },
  fetchFileExpenses: {
    method: "POST",
    url: `${URL}/file_expenses/getExpenses`,
  },
  deleteFileExpense: {
    method: "POST",
    url: `${URL}/file_expenses/delete`,
  },
  fetchFileExpenseById: {
    method: "GET",
    url: `${URL}/expenses/get-file-expense-byid`,
  },
  updateFileExpenseById: {
    method: "POST",
    url: `${URL}/expenses/update-file-expense/file_expenses`,
    foldername: "file_expenses",
  },
  updateFileExpenseByIdForAdmin: {
    method: "POST",
    url: `${URL}/expenses/update-file-expense-admin/file_expenses`,
    foldername: "file_expenses",
  },
  insertFileExpense: {
    method: "POST",
    url: `${URL}/expenses/add-file-expense/file_expenses`,
    foldername: "file_expenses",
  },
  fetchOfficeExpenses: {
    method: "POST",
    url: `${URL}/office_expenses/getExpenses`,
  },
  deleteOfficeExpense: {
    method: "DELETE",
    url: `${URL}/expenses/delete-office-expense-byid`,
  },
  deleteOfficeExpenseForAdmin: {
    method: "DELETE",
    url: `${URL}/expenses/delete-office-expense-byid-admin`,
  },
  deleteFileExpense: {
    method: "DELETE",
    url: `${URL}/expenses/delete-file-expense-byid`,
  },
  deleteFileExpenseForAdmin: {
    method: "DELETE",
    url: `${URL}/expenses/delete-file-expense-byid-admin`,
  },
  fetchOfficeExpenseById: {
    method: "GET",
    url: `${URL}/expenses/get-office-expense-byid`,
  },
  updateOfficeExpenseById: {
    method: "POST",
    url: `${URL}/expenses/update-office-expense/office_expenses`,
    foldername: "office_expenses",
  },
  updateOfficeExpenseByIdForAdmin: {
    method: "POST",
    url: `${URL}/expenses/update-office-expense-admin/office_expenses`,
    foldername: "office_expenses",
  },
  insertOfficeExpense: {
    method: "POST",
    url: `${URL}/expenses/add-office-expense/office_expenses`,
    foldername: "office_expenses",
  },
  checkOfficeExpense: {
    method: "POST",
    url: `${URL}/expenses/verify-office-expense-byid`,
  },
  checkFileExpense: {
    method: "POST",
    url: `${URL}/expenses/verify-file-expense-byid`,
  },
  fetchMstDocument: {
    method: "POST",
    url: `${URL}/document_master`,
  },
  fetchFileDetails: {
    method: "GET",
    url: `${URL}/file_details`,
  },
  changeFileDetails: {
    method: "POST",
    url: `${URL}/file_details`,
  },
  changeFileDocumentJson: {
    method: "POST",
    url: `${URL}/file/documentjson`,
  },
  getFileDocumentJson: {
    method: "GET",
    url: `${URL}/file/documentjson`,
  },
  messageToClient: {
    method: "POST",
    url: `${URL}/file/messagetoclient`,
  },
  getMessageToClient: {
    method: "GET",
    url: `${URL}/filedata/messagetoclient`,
  },
  lisoner: {
    method: "GET",
    url: `${URL}/filedata/lisoner/get`,
  },
  planner: {
    method: "GET",
    url: `${URL}/filedata/planner/get`,
  },
  stagelist: {
    method: "GET",
    url: `${URL}/stagelist`,
  },
  stageListByProcess: {
    method: "GET",
    url: `${URL}/stagelistByProcess`,
  },
  insert_enagar: {
    method: "POST",
    url: `${URL}/enagar`,
  },
  get_enagar: {
    method: "GET",
    url: `${URL}/getEnagarportal`,
  },
  insert_ownerPortal: {
    method: "POST",
    url: `${URL}/ownerPortal`,
  },
  getOwnerPortal: {
    method: "GET",
    url: `${URL}/getOwnerPortal`,
  },
  getTypesOfPlotValidation: {
    method: "GET",
    url: `${URL}/getTypesOfPlotValidation`,
  },
  file_plot_validation: {
    method: "POST",
    url: `${URL}/file_plot_validation/plot_validation`,
  },
  get_file_plot_validation: {
    method: "GET",
    url: `${URL}/file_plot_validation`,
  },
  reject_file_plot: {
    method: "POST",
    url: `${URL}/reject_file_plot`,
  },
  reject_enagar: {
    method: "POST",
    url: `${URL}/reject_enagar_portal`,
  },
  test_documents: {
    method: "POST",
    url: `${URL}/file_plot_validation_test/plot_validation`,
  },
  get_document_upload: {
    method: "GET",
    url: `${URL}/get_document_upload`,
  },
  document_upload: {
    method: "POST",
    url: `${URL}/document_upload/document_upload`,
  },
  remove_document_upload: {
    method: "DELETE",
    url: `${URL}/remove_document_upload`,
  },
  getDepartments: {
    method: "GET",
    url: `${URL}/departments`,
  },
  getPosts: {
    method: "GET",
    url: `${URL}/posts`,
  },
  getAssistantJuniorEngineer: {
    method: "GET",
    url: `${URL}/filequery/assistantJuniorEngineer`,
  },
  sendAssistantJuniorEngineer: {
    method: "POST",
    url: `${URL}/filequery/assistantJuniorEngineer/assistant_junior_engineer`,
  },
  getDeputyEngineer: {
    method: "GET",
    url: `${URL}/filequery/deputyEngineer`,
  },
  sendDeputyEngineer: {
    method: "POST",
    url: `${URL}/filequery/deputyEngineer/deputy_engineer`,
  },
  getZonalOfficer: {
    method: "GET",
    url: `${URL}/filequery/zonalOfficer`,
  },
  sendZonalOfficer: {
    method: "POST",
    url: `${URL}/filequery/zonalOfficer/zonal_officer`,
  },
  getDeputyCommissioner: {
    method: "GET",
    url: `${URL}/filequery/deputyCommissioner`,
  },
  sendDeputyCommissioner: {
    method: "POST",
    url: `${URL}/filequery/deputyCommissioner/deputy_commissioner`,
  },
  getCommissioner: {
    method: "GET",
    url: `${URL}/filequery/commissioner`,
  },
  sendCommissioner: {
    method: "POST",
    url: `${URL}/filequery/commissioner/commissioner`,
  },
  rejectQueryFile: {
    method: "POST",
    url: `${URL}/filequery/rejectfilequery`,
  },
  fetchRoadScrunity: {
    method: "GET",
    url: `${URL}/roadscrunity`,
  },
  saveRoadScrunity: {
    method: "POST",
    url: `${URL}/roadscrunity/road_scrunity`,
  },
  getAllFilesByTeamId: {
    method: "GET",
    url: `${URL}/all-files-by-team-id`,
  },
  getOfficeExpenseForAdmin: {
    method: "GET",
    url: `${URL}/expenses/get-office-expense-admin`,
  },
  getFileExpenseForAdmin: {
    method: "GET",
    url: `${URL}/expenses/get-file-expense-admin`,
  },
  getOfficeExpenseForTeam: {
    method: "GET",
    url: `${URL}/expenses/get-office-expense-team`,
  },
  getFileExpenseForTeam: {
    method: "GET",
    url: `${URL}/expenses/get-file-expense-team`,
  },
  getTodos: {
    method: "GET",
    url: `${URL}/todo/get-todo`,
  },
  insertTodo: {
    method: "POST",
    url: `${URL}/todo/add-todo`,
  },
  updateTodo: {
    method: "POST",
    url: `${URL}/todo/update-todo`,
  },
  markAsDoneTodo: {
    method: "POST",
    url: `${URL}/todo/mark-todo-as-done`,
  },
  deleteTodo: {
    method: "DELETE",
    url: `${URL}/todo/remove-todo`,
  },
  deleteadmincomment: {
    method: "DELETE",
    url: `${URL}/todo/remove-admin-comment`,
  },
  deleteHodcomment: {
    method: "DELETE",
    url: `${URL}/todo/remove-hod-comment`,
  },
  getTodosByTeam: {
    method: "GET",
    url: `${URL}/todo/get-todo-of-team`,
  },
  getTodosByTeamId: {
    method: "GET",
    url: `${URL}/todo/get-todo-by-team-id`,
  },
  getTodosById: {
    method: "GET",
    url: `${URL}/todo/get-todo-by-id`,
  },
  getTodosByFileId: {
    method: "GET",
    url: `${URL}/todo/get-todo-by-fileid`,
  },
  checkTodo: {
    method: "POST",
    url: `${URL}/todo/check-todo`,
  },
  checkhodTodo: {
    method: "POST",
    url: `${URL}/todo/hod-check-todo`,
  },
  getFileExpenseByFileId: {
    method: "GET",
    url: `${URL}/expenses/get-file-expense-bymstfileid`,
  },
  getFileExpenseByFileIdPdf: {
    method: "GET",
    url: `${URL}/expenses/get-file-expense-bymstfileid-pdf`,
  },
  getDepositExpenseByTeamId: {
    method: "GET",
    url: `${URL}/expenses/get-deposit-expense-by-teamid`,
  },
  getDepositExpenseByTeamIdForPdf: {
    method: "GET",
    url: `${URL}/expenses/get-deposit-expense-by-teamid-pdf`,
  },
  searchapi: {
    method: "POST",
    url: `${URL}/search`,
  },
  generatePdfOfTeamExpense: {
    method: "POST",
    url: `${URL}/generatepdf-teamexpense`,
  },
  AddLeave: {
    method: "POST",
    url: `${URL}/add-leave`,
  },
  getLeaveByTeamIdForTeam: {
    method: "GET",
    url: `${URL}/get-leave-list-byteam-id-forteam`,
  },
  getLeaveByTeam: {
    method: "GET",
    url: `${URL}/get-leave-list-byteam-id`,
  },
  getLeaveById: {
    method: "GET",
    url: `${URL}/get-leave`,
  },
  editLeave: {
    method: "POST",
    url: `${URL}/edit-leave`,
  },
  deleteLeaveById: {
    method: "GET",
    url: `${URL}/delete-leave`,
  },
  actionForLeave: {
    method: "POST",
    url: `${URL}/action_for_leave`,
  },
  saveWorkFee: {
    method: "POST",
    url: `${URL}/save-work-fee`,
  },
  getWorkFee: {
    method: "GET",
    url: `${URL}/get-work-fee`,
  },
  skipWorkFee: {
    method: "GET",
    url: `${URL}/skip-work-fee`,
  },
  getAllImportantFees: {
    method: "GET",
    url: `${URL}/get-all_important_fees`,
  },
  deleteAllImportantFees: {
    method: "GET",
    url: `${URL}/delete-all_important_fees`,
  },
  saveAllImportantFees: {
    method: "POST",
    url: `${URL}/save-all_important_fees/all_important_fees`,
  },
  skipFileDetails: {
    method: "POST",
    url: `${URL}/skip-file_details`,
  },
  skipEnagarPortal: {
    method: "GET",
    url: `${URL}/skip-enagar_portal`,
  },
  skipOwnerPortal: {
    method: "GET",
    url: `${URL}/skip-owner_portal`,
  },
  skipAssistantJuniorEngineer: {
    method: "GET",
    url: `${URL}/skip-assistant_junior_engineer`,
  },
  skipDeputyEngineer: {
    method: "GET",
    url: `${URL}/skip-deputy_engineer`,
  },
  skipZonalOfficer: {
    method: "GET",
    url: `${URL}/skip-zonal_officer`,
  },
  skipDeputyCommissioner: {
    method: "GET",
    url: `${URL}/skip-deputy_commissioner`,
  },
  skipCommissioner: {
    method: "GET",
    url: `${URL}/skip-commissioner`,
  },
  get_approvedPlan: {
    method: "GET",
    url: `${URL}/get-approved_plan`,
  },
  save_approvedPlan: {
    method: "POST",
    url: `${URL}/save-approved_plan/approved_plan`,
  },
  skip_approvedPlan: {
    method: "GET",
    url: `${URL}/skip-approved_plan`,
  },
  delete_approvedPlan: {
    method: "GET",
    url: `${URL}/delete-approved_plan_byid`,
  },
  skip_allImportantFees: {
    method: "GET",
    url: `${URL}/skip-all_important_fees`,
  },
  get_aminities: {
    method: "GET",
    url: `${URL}/get-aminities_fees`,
  },
  save_aminities: {
    method: "POST",
    url: `${URL}/save-aminities_fees/aminities`,
  },
  skip_aminities: {
    method: "GET",
    url: `${URL}/skip-aminities_fees`,
  },
  skip_sendMessageToClient: {
    method: "GET",
    url: `${URL}/skip-send_message_to_client`,
  },
  get_filePlotValidation: {
    method: "GET",
    url: `${URL}/get-plot_validation`,
  },
  save_filePlotValidation: {
    method: "POST",
    url: `${URL}/save-plot_validation/plot_validation`,
  },
  save_and_next_filePlotValidation: {
    method: "POST",
    url: `${URL}/save_and_next-plot_validation/plot_validation`,
  },
  reject_filePlotValidation: {
    method: "POST",
    url: `${URL}/reject-plot_validation`,
  },
  skip_filePlotValidation: {
    method: "POST",
    url: `${URL}/skip-plot_validation`,
  },
  save_enagarPortal: {
    method: "POST",
    url: `${URL}/save-enagarportal`,
  },
  save_and_next_enagarPortal: {
    method: "POST",
    url: `${URL}/save_and_next-enagarportal`,
  },
  skip_enagarPortal: {
    method: "GET",
    url: `${URL}/skip-enagarportal`,
  },
  get_documentUpload: {
    method: "GET",
    url: `${URL}/get-document_upload`,
  },
  save_documentUpload: {
    method: "POST",
    url: `${URL}/save-document_upload/document_upload/`,
  },
  skip_documentUpload: {
    method: "GET",
    url: `${URL}/skip-document_upload`,
  },
  remove_documentUpload: {
    method: "GET",
    url: `${URL}/remove-document_upload`,
  },
  save_fileApprovedReject: {
    method: "POST",
    url: `${URL}/save-file_approved_reject`,
  },
  getMstFileCode: {
    method: "GET",
    url: `${URL}/get-file-codes`,
  },
  getOnlineProjectForSuperUser: {
    method: "GET",
    url: `${URL}/get-project-online-superuser`,
  },
  getOfflineProjectForSuperUser: {
    method: "GET",
    url: `${URL}/get-project-offline-superuser`,
  },
  getBUCProjectForSuperUser: {
    method: "GET",
    url: `${URL}/get-project-buc-superuser`,
  },
  getApprovedProjectForSuperUser: {
    method: "GET",
    url: `${URL}/get-project-approved-superuser`,
  },
  getRejectProjectForSuperUser: {
    method: "GET",
    url: `${URL}/get-project-reject-superuser`,
  },
  getOnlineProjectForTeam: {
    method: "GET",
    url: `${URL}/get-project-online-team`,
  },
  getOfflineProjectForTeam: {
    method: "GET",
    url: `${URL}/get-project-offline-team`,
  },
  getBUCProjectForTeam: {
    method: "GET",
    url: `${URL}/get-project-buc-team`,
  },
  getApprovedProjectForTeam: {
    method: "GET",
    url: `${URL}/get-project-approved-team`,
  },
  getRejectProjectForTeam: {
    method: "GET",
    url: `${URL}/get-project-reject-team`,
  },
  getProjectsForSuperUser: {
    method: "GET",
    url: `${URL}/get-project-superuser`,
  },
  getProjectsForTeam: {
    method: "GET",
    url: `${URL}/get-project-team`,
  },
  getSiteCondition: {
    method: "GET",
    url: `${URL}/get-site_condition`,
  },
  saveSiteCondition: {
    method: "POST",
    url: `${URL}/save-site_condition/site_condition`,
  },
  deleteSiteCondition: {
    method: "POST",
    url: `${URL}/delete-site_condition-image`,
  },
  skipSiteCondition: {
    method: "GET",
    url: `${URL}/skip-site_condition`,
  },
  getProjectDirection: {
    method: "GET",
    url: `${URL}/get-project-direction`,
  },
  getTentativePdf: {
    method: "GET",
    url: `${URL}/get-tentative-pdf`,
  },
  saveTentativePdf: {
    method: "POST",
    url: `${URL}/save-tentative-pdf/tentative_pdf`,
  },
  deleteTentativePdf: {
    method: "POST",
    url: `${URL}/delete-tentative-pdf`,
  },
  getApprovedPlanPdf: {
    method: "GET",
    url: `${URL}/get-approved_plan-pdf`,
  },
  saveApprovedPlanPdf: {
    method: "POST",
    url: `${URL}/save-approved_plan-pdf/approved_plan_pdf`,
  },
  deleteApprovedPlanPdf: {
    method: "POST",
    url: `${URL}/delete-approved_plan-pdf`,
  },
  getBuildingBangalow: {
    method: "GET",
    url: `${URL}/get-building_bungalow`,
  },
  saveBuildingBangalow: {
    method: "POST",
    url: `${URL}/save-building_bungalow`,
  },
  deleteBuildingBangalow: {
    method: "POST",
    url: `${URL}/delete-building_bungalow`,
  },
  getProjectTypes: {
    method: "GET",
    url: `${URL}/get-project_types`,
  },
  getInaugurationMessage: {
    method: "GET",
    url: `${URL}/get-inauguration_message`,
  },
  saveInaugurationMessage: {
    method: "POST",
    url: `${URL}/save-inauguration_message/inauguration_message`,
  },
  deleteInaugurationMessage: {
    method: "POST",
    url: `${URL}/delete-inauguration_message`,
  },
  addsites: {
    method: "POST",
    url: `${URL}/save-site-inspection/siteinspection`,
    foldername: "siteinspection",
  },
  updatesites: {
    method: "POST",
    url: `${URL}/update-site-inspection/siteinspection`,
    foldername: "siteinspection",
  },
  getsitesbyid: {
    method: "GET",
    url: `${URL}/get-site-inspection`,
  },
  getsites: {
    method: "GET",
    url: `${URL}/get-site-inspection`,
  },
  getchecklist: {
    method: "GET",
    url: `${URL}/get-site-checklist`,
  },
  getfileDatabyId: {
    method: "GET",
    url: `${URL}/get-dropdown-file`,
  },
  getempsitebyId: {
    method: "GET",
    url: `${URL}/get-site-emplist`,
  },
  deletesites: {
    method: "POST",
    // url: `${URL}/delete-site-inspection`,
    url: `${URL}/delete-site-inspection`,
  },

  getProjectTypes: {
    method: "GET",
    url: `${URL}/get-project-types`,
  },

  addproject: {
    method: "POST",
    url: `${URL}/addProject/projectsummery`,
    foldername: "projectsummery",
  },

  projectbyid: {
    method: "GET",
    url: `${URL}/get-project`,
  },

  updateProject: {
    method: "POST",
    url: `${URL}/update-project/projectsummery`,
    foldername: "projectsummery",
  },

  addlocation: {
    method: "POST",
    url: `${URL}/add-location/projectlocation`,
    foldername: "projectlocation",
  },
  locationbyid: {
    method: "GET",
    url: `${URL}/get-location`,
  },

  updatelocation: {
    method: "POST",
    url: `${URL}/update-location/projectlocation`,
    foldername: "projectlocation",
  },
  getlocation: {
    method: "GET",
    url: `${URL}/get-all-location`,
  },

  projectdetailsbyid: {
    method: "GET",
    url: `${URL}/get-project-details`,
  },

  addprojectdetails: {
    method: "POST",
    url: `${URL}/add-Project-detail`,
  },

  projectcheckboxbyid: {
    method: "GET",
    url: `${URL}/get-project-checkbox`,
  },

  updateprojectdetail: {
    method: "POST",
    url: `${URL}/update-project-details`,
    // foldername:"projectlocation"
  },

  getprojectdetials: {
    method: "GET",
    url: `${URL}/get-project-qty`,
  },
  addallplans: {
    method: "POST",
    url: `${URL}/add-all-plans/allplans`,
    foldername: "allplans",
  },

  getallplansbyid: {
    method: "GET",
    url: `${URL}/get-all`,
  },
  getallplans: {
    method: "GET",
    url: `${URL}/get-all-plans`,
  },

  updateallplans: {
    method: "POST",
    url: `${URL}/update-all-plans/allplans`,
    foldername: "allplans",
  },

  addProjectPlan: {
    method: "POST",
    url: `${URL}/add-project-plan/projectplan`,
    foldername: "projectplan",
  },
  projectplanbyid: {
    method: "GET",
    url: `${URL}/get-project-plan`,
  },

  updateProjectplan: {
    method: "POST",
    url: `${URL}/update-project-plan/projectplan`,
    foldername: "projectplan",
  },
  totalclientbyid: {
    method: "GET",
    url: `${URL}/get-client-data`,
  },
  getDepartment: {
    method: "GET",
    url: `${URL}/get-department`,
  },

  getHOD: {
    method: "GET",
    url: `${URL}/todo/get-HOD`,
  },
  getDpartmentempoloyee: {
    method: "GET",
    url: `${URL}/todo/get-department-employee`,
  },
  getTodosByDepartmentTeam: {
    method: "GET",
    url: `${URL}/todo/get-department-team`,
  },
  fetchHod: {
    method: "GET",
    url: `${URL}/get-Hod`,
  },
  
  getDpartmentempoloyee: {
    method: "GET",
    url: `${URL}/todo/get-department-employee`,
  },
  getTodosByDepartmentTeam: {
    method: "GET",
    url: `${URL}/todo/get-department-team`,
  },
  getCategory: {
    method: "GET",
    url: `${URL}/get-category`,
  },
  addmstprojectdetails: {
    method: "POST",
    url: `${URL}/addProject-detail`,
  },
  fetchmstproject: {
    method: "GET",
    url: `${URL}/get-mst-project`,
  },
  getProjectforHod: {
    method: "GET",
    url: `${URL}/get-department-project`,
  },
  deleteProject: {
    method: "DELETE",
    url: `${URL}/remove-project`,
  },
  updatemstproject: {
    method: "POST",
    url: `${URL}/update-mstproject`,
  },
  getmstProject: {
    method: "GET",
    url: `${URL}/get-mst-project`,
  },

  updateprojectsummery: {
    method: "POST",
    url: `${URL}/update-project-summery/architectureprojectsummery`,
    foldername: "architectureprojectsummery",
  },
  teamprojectlist: {
    method: "GET",
    url: `${URL}/get-team-list`,
  },
  tentativeplanbyid: {
    method: "GET",
    url: `${URL}/get-tentative-plan`,
  },

  addTentativePlan: {
    method: "POST",
    url: `${URL}/add-tentative/tentativePlan`,
    foldername: "tentativePlan",
  },
  deletetantativePlan: {
    method: "DELETE",
    url: `${URL}/remove-tantative-image`,
  },

  addhighRiseDetail: {
    method: "POST",
    url: `${URL}/addhigh-rise-detail`,
  },

  deletehighrisedetail: {
    method: "DELETE",
    url: `${URL}/remove-high-rise-detail`,
  },

  highrisedetailbyid: {
    method: "GET",
    url: `${URL}/get-high-rise-detail`,
  },
  addbuildingdetail: {
    method: "POST",
    url: `${URL}/add-building-detail/buildingdetail`,
    foldername: "buildingdetail",
  },
  buildingdetailbyid: {
    method: "GET",
    url: `${URL}/get-building-detail`,
  },

  deletebuildingdetail: {
    method: "POST",
    url: `${URL}/delete-building-detail`,
  },
};


