import React, { useEffect, useState, useCallback, useMemo } from "react";
import ApiService from "../../../../services/ApiService";
import { GetDate } from "../../../../services/DateAndTimeService";
import { getToken } from "../../../../services/useLocalStorage";
import {
  fetchActiveEmployee,
  getTodos,
  deleteTodo,
} from "../../../../config/config";
import { GetFile } from "../../../../services/FileService";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { ActionButtonJsx } from "../../../../services/FormCommon";
import { SwalAlert } from "../../../../UtilsComponent/SwalDialog";
import Select from "react-select";
import { Accordion, Tab, Nav } from "react-bootstrap";
import { eyeButtonSvg, FileUrl } from "../../../../config/file_url";
import "bootstrap/dist/css/bootstrap.min.css";
import { ReactSVG } from "react-svg";
import EmpTodoModal from "../EmpTodoModal";
import TodoModal from "../Todomodal";

//Todo list admin
const ListAdmin = () => {
  const [todos, setTodos] = useState([]);
  const header = {
    Authorization: `Bearer ${getToken()}`,
    ["Content-Type"]: "application/json",
  };

  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [employees, setEmployees] = useState([]);
  const [file, setFile] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState("");
  const [selectedFileId, setSelectedFileId] = useState("");
  const [show, setShow] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedWork, setSelectedWork] = useState(null);
  const [selectedTodoId, setSelectedTodoId] = useState(null);
  const [activeTab, setActiveTab] = useState("pending");
  const [team_fullname, setTeam_fullname] = useState("");

  useCallback(() => {
    setStartDate("");
    setEndDate("");
  }, []);

  const getEmployees = async () => {
    try {
      // const res = await GetEmployees(`${getToken()}`);
      setLoading(true);
      const header = { Authorization: `Bearer ${getToken()}` };
      const res = await ApiService(
        null,
        0,
        header,
        fetchActiveEmployee.method,
        fetchActiveEmployee.url
      );

      if (!res.data.error) {
        setLoading(false);
        const payload = res.data?.data;
        if (Array.isArray(payload) && payload.length) {
          setEmployees(payload);
        }
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const validJson = (jsonString) => {
    try {
      return JSON.parse(jsonString);
    } catch (err) {
      return "";
    }
  };
  const { getValues } = useForm({
    mode: "onChange",
  });

  const fetchTodos = async () => {
    try {
      setLoading(true);
      const res = await ApiService(
        null,
        0,
        header,
        getTodos.method,
        getTodos.url
      );
      if (!res.data?.error) {
        setLoading(false);

        const payload = res?.data?.data;

        setTodos(payload);
        if (Array.isArray(payload) && payload.length) {
          const rows = [];
          // ? GetDate(element?.date,"DD-MM-YYYY") : "-"
          payload.forEach((element) => {
            const cardJson = validJson(element?.card);

            let card = "";

            if (Array.isArray(cardJson) && cardJson.length > 0) {
              card = cardJson[cardJson.length - 1]?.card_type;
            }
          });

          //   setTodos(rows);
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);

      console.log(err);
    }
  };

  const fetchFiles = async () => {
    try {
      const res = await GetFile(`${getToken()}`);
      if (!res.data.error) {
        // console.log("res-->", res);
        setFile(res.data.data);
      } else {
        toast.error(res.data.message);
      }
    } catch (err) {
      console.log(err);
      if (err.response.data.error) {
        toast.error(err.response.data.message);
      }
    }
  };

  useEffect(() => {
    fetchTodos();
    getEmployees();
    fetchFiles();
    const storedTeam = localStorage.getItem("selectedTeam");
    if (storedTeam) {
      setSelectedTeam(storedTeam);
    }
  }, []);

  const handleChange = (e) => {
    const selectedStartDate = e.target.value;
    setStartDate(selectedStartDate);
  };

  const handleEndChange = (e) => {
    const selectedEndDate = e.target.value;
    setEndDate(selectedEndDate);
  };

  const handleTeamChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedTeam(selectedValue);
    localStorage.setItem("selectedTeam", selectedValue);
  };

  const handleTabChange = (event) => {
    const selected = event;
    setActiveTab(selected);
    
  };

  const handleFileSelect = (event) => {
    const selectedFileId = event.label;
    setSelectedFileId(selectedFileId);
  };

  const filterByTab = (todos) => {
    if (activeTab === "in_progress") {
      return todos.filter((item) => item.todo_status === 0);
    } else if (activeTab === "pending") {
      return todos.filter((item) => item.todo_status === 1);
    } else if (activeTab === "completed") {
      return todos.filter((item) => item.todo_status === 2);
    } else {
      return todos;
    }
  };

  const filterData = (todos) => {
    let filteredTodos = todos;

    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      filteredTodos = filteredTodos.filter((item) => {
        const executionDate = new Date(item.execution_date);
        const completedDate = new Date(item.completed_date);
        return executionDate >= start && completedDate <= end;
      });
    }

    if (selectedTeam !== "") {
      filteredTodos = filteredTodos.filter(
        (item) => item.team_fullname === selectedTeam
      );
    }

    if (selectedFileId !== "") {
      filteredTodos = filteredTodos.filter(
        (item) => item.file_name === selectedFileId
      );
    }

    return filteredTodos.sort((a, b) => b.id - a.id);
  };

  const filteredData = useMemo(
    () => filterData(filterByTab(todos)),
    [startDate, endDate, todos, selectedTeam, selectedFileId, activeTab]
  );

  useEffect(() => {
    // console.log("Filtered Data:", filteredData);
  }, [filteredData]);

  const getLastCard = (jsonData) => {
    try {
      if (validJson(jsonData)) {
        const data = JSON.parse(jsonData);
        if (Array.isArray(data) && data?.length) {
          return data[data.length - 1]?.card_type;
        }
      } else {
        return "";
      }
    } catch (err) {
      console.log(err);
      return "";
    }
  };

  const userinfo = JSON.parse(localStorage.getItem("userinfo"));

  const username = `${userinfo.first_name} ${userinfo.last_name}`;

  const groupTodos = (todos) => {
    const grouped = {};
    todos.forEach((todo) => {
      const dateKey = todo.created_at.split("T")[0];
      const key = dateKey + todo.team_fullname;
      if (!grouped[key]) {
        grouped[key] = [todo];
      } else {
        grouped[key].push(todo);
      }
    });
    return Object.values(grouped);
  };

  const removeTodo = async (id) => {
    try {
      const res = await ApiService(
        null,
        parseInt(id),
        header,
        deleteTodo.method,
        deleteTodo.url
      );
      if (!res?.data?.error) {
        // setTodos(id);
        toast.success(res?.data?.message);
        window.location.reload();
      } else {
        toast.error(res?.data?.message);
      }
    } catch (err) {
      console.log(err);
      toast.error(
        err?.response?.data?.message
          ? err?.response?.data?.message
          : "Something went wrong"
      );
    }
  };

  const onRemoveTodo = (id) => {
    SwalAlert({})
      .then(async (result) => {
        if (result.isConfirmed) {
          await removeTodo(id);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  const handleClose = () => setShow(false);

  const handleShow = (todosGroup, work) => {
    setTeam_fullname(work.team_fullname);
    setSelectedTodoId(work.id); // Log the ID when the modal is shown
    setSelectedGroup(todosGroup);
    setSelectedWork(work);
    setShow(true);
  };

  const handleReset = () => {
    window.location.reload();
  };

  const pendingTodosLength = useMemo(
    () => filterData(todos).filter((todo) => todo.todo_status === 1).length,
    [todos, startDate, endDate, selectedTeam, selectedFileId]
  );
  const allTasksLength = useMemo(
    () => filterData(todos).length,
    [todos, startDate, endDate, selectedTeam, selectedFileId]
  );
  const completedTodosLength = useMemo(
    () => filterData(todos).filter((todo) => todo.todo_status === 2).length,
    [todos, startDate, endDate, selectedTeam, selectedFileId]
  );
  const inProgressTodosLength = useMemo(
    () => filterData(todos).filter((todo) => todo.todo_status === 0).length,
    [todos, startDate, endDate, selectedTeam, selectedFileId]
  );

  const calculateDaysBetweenDates = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffTime = Math.abs(end - start);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  return (
    <div className="content__wrapper">
      <section className="page-content">
        <div className="page-title mobile-title">
          <h1 className="h4 mb-0">Todos</h1>
        </div>
        <div className="todos_section">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-5">
              <div className="title_heading">
                <h4 className="mb-0">All Todos</h4>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="right_todos_part d-flex align-items-center justify-content-end">
                <div className="col-md-5">
                  <select
                    name="team_id"
                    id="team_id"
                    className="form-control"
                    value={selectedTeam}
                    onChange={handleTeamChange}
                  >
                    <option value={""}>--Select Team--</option>
                    {employees.map((data, index) => (
                      <option
                        key={index}
                        value={`${data.first_name} ${data.last_name}`}
                        selected={data?.id == getValues(`team_id`)}
                      >
                        {data.first_name} {data.last_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-7 Select">
                  <Select
                    className="todo_team_select"
                    options={file.map((file) => ({
                      label: file.file_name,
                      value: file.id,
                    }))}
                    name="mst_file_project_id"
                    id="mst_file_project_id"
                    onChange={(e) => handleFileSelect(e)}
                    isSearchable
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          {/* <h6 className="person_name">{selectedTeam}<span>- test</span></h6> */}
          <Tab.Container activeKey={activeTab} onSelect={handleTabChange}>
            <Nav variant="pills" className="main_nav_link row">
              <Nav.Item className="tab_box col pending">
                <Nav.Link eventKey="pending">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <img
                        src={`${FileUrl}/img/icons/errorround.svg`}
                        className="in__svg pr-2"
                        alt="project"
                      />
                      <span className="project_status">Pending</span>
                    </div>
                    <div>
                      <span className="project_counter">
                        {pendingTodosLength}
                      </span>
                    </div>
                  </div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="tab_box col all_task">
                <Nav.Link eventKey="second">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <img
                        src={`${FileUrl}/img/icons/barround.svg`}
                        className="in__svg pr-2"
                        alt="project"
                      />
                      <span className="project_status"> All Task</span>
                    </div>

                    <div>
                      <span className="project_counter"> {allTasksLength}</span>
                    </div>
                  </div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="tab_box col completed">
                <Nav.Link eventKey="completed">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <img
                        src={`${FileUrl}/img/icons/roundtick.svg`}
                        className="in__svg pr-2"
                        alt="project"
                      />
                      <span className="project_status"> Completed</span>
                    </div>
                    <div>
                      <span className="project_counter">
                        {" "}
                        {completedTodosLength}
                      </span>
                    </div>
                  </div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="tab_box col in_progess">
                <Nav.Link eventKey="in_progress">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <img
                        src={`${FileUrl}/img/icons/clockround.svg`}
                        className="in__svg pr-2"
                        alt="project"
                      />
                      <span className="project_status"> In Progress</span>
                    </div>
                    <div>
                      <span className="project_counter">
                        {" "}
                        {inProgressTodosLength}
                      </span>
                    </div>
                  </div>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content></Tab.Content>
          </Tab.Container>
          <div className="row main_status_body">
            <div className="col-md-12">
              <div className="zed__table">
                <div className="row heading_row mt-3">
                  <div className="col-lg-5">
                    <div className="d-flex align-items-center">
                      <p className="pr-5">sr.no</p>
                      <p>Project Name</p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="d-flex align-items-center">
                      <p className="pr-4 reminder_show">Reminder</p>
                      <p className="pr-4 due_date_show">Due Date</p>
                      <p className="status_show">Status</p>
                    </div>
                  </div>
                  <div className="col-lg-1">
                    <p className="pl-3">Card</p>
                  </div>
                  <div className="col-lg-2">
                    <p>comments</p>
                  </div>
                </div>
                <div className="row" style={{ justifyContent: "end" }}>
                  <div className="col-lg-7">
                    <h5 className="">All Task</h5>
                  </div>
                  <div className="col-lg-2 col-12">
                    <div className="form-group mb-0">
                      <input
                        type="date"
                        name="start_date"
                        id="start_date"
                        onChange={handleChange}
                        value={startDate}
                        className="form-control todo_filter_date"
                      />
                    </div>
                  </div>
                  <div className="col-lg-2 col-12">
                    <div className="form-group mb-0">
                      <input
                        type="date"
                        name="end_date"
                        id="end_date"
                        onChange={handleEndChange}
                        value={endDate}
                        className="form-control todo_filter_date"
                      />
                    </div>
                  </div>
                  <div className="col-lg-1 col-12">
                    <div className="form-group mb-0">
                      <button className="outline-btn" onClick={handleReset}>
                        Reset
                      </button>
                    </div>
                  </div>
                </div>

                {groupTodos(filteredData).map((todosGroup, groupIndex) => (
                  <>
                    <div className="d-flex justify-content-between">
                      <div>
                        <p
                          style={{
                            textAlign: "right",
                            fontSize: "16px",
                            paddingTop: "5px",
                            fontWeight: "400",
                            color: "#212322",
                            marginBottom: "5px",
                          }}
                        >
                          {GetDate(todosGroup[0].created_at, "DD-MM-YYYY")}
                        </p>
                      </div>
                      <div
                        style={{
                          textAlign: "right",
                          fontSize: "16px",
                          paddingTop: "10px",
                          fontWeight: "500",
                          color: "#212322",
                        }}
                      >
                        Total Task - {todosGroup.length}
                      </div>
                    </div>
                    <Accordion
                      key={groupIndex}
                      className="table-responsive mb-2"
                    >
                      {todosGroup.map((work, workIndex) => (
                        <Accordion.Item
                          eventKey={`${groupIndex}-${workIndex}`}
                          key={workIndex}
                        >
                          <Accordion.Header
                            className={`todo_header_accrdin ${
                              work.todo_status === null ||
                              work.todo_status === undefined
                                ? "In_Progress"
                                : work.todo_status === 0
                                ? "In_Progress"
                                : work.todo_status === 1
                                ? "Pending"
                                : work.todo_status === 2
                                ? "Completed"
                                : ""
                            }`}
                          >
                            <div className="row w-100 align-items-center">
                              <div className="col-lg-5">
                                <div className="d-flex align-items-center">
                                  <div className="mr-5 normal_text">
                                    {workIndex + 1}
                                  </div>
                                  <div className="normal_text d-flex">
                                    <img
                                      src={`${FileUrl}/img/icons/filetodo.svg`}
                                      className="in__svg pr-2"
                                      alt="project"
                                    />
                                    {work.file_name}
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="d-flex align-items-center">
                                  <div className="pr-4 pl-2 normal_text reminder_show">
                                    {work.remainder !== null &&
                                      work.remainder !== 0 && (
                                        <span className="counter_show">
                                          {work.remainder}
                                        </span>
                                      )}
                                  </div>
                                  <div className="pr-5 due_date_show">
                                    <p className="due_date_text normal_text">
                                      {GetDate(
                                        work.completed_date,
                                        "DD-MM-YYYY"
                                      )}
                                    </p>
                                  </div>
                                  <div className=" ">
                                    <p
                                      className={`incomplete_text status_btn ${
                                        work.todo_status === null ||
                                        work.todo_status === undefined
                                          ? "In_Progress"
                                          : work.todo_status === 0
                                          ? "In_Progress"
                                          : work.todo_status === 1
                                          ? "Pending"
                                          : work.todo_status === 2
                                          ? "Completed"
                                          : ""
                                      }`}
                                    >
                                      {" "}
                                      {work.todo_status === null ||
                                      work.todo_status === undefined
                                        ? "In Progress"
                                        : work.todo_status === 0
                                        ? "In Progress"
                                        : work.todo_status === 1
                                        ? "Pending"
                                        : work.todo_status === 2
                                        ? "Completed"
                                        : ""}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-1">
                                <button
                                  className={`status_btn ${
                                    work.card ? getLastCard(work.card) : "-"
                                  }`}
                                >
                                  {work.card ? getLastCard(work.card) : "-"}
                                </button>
                              </div>
                              <div className="col-lg-2">
                                <div className="d-flex align-items-center">
                                  <div className="hod_inner_box">
                                    {/* {work.admin_comment == 1 &&
                                    work.hod_comment == 1 ? (
                                      <div className="hod_box">
                                        <span className="">2 msgs</span>
                                      </div>
                                    ) : work.admin_comment == 1 ||
                                      work.hod_comment == 1 ? (
                                      <div className="hod_box">
                                        <span className="">1 msg</span>
                                      </div>
                                    ) : (
                                      "NA"
                                    )} */}
                                    {work.admin_comment == 1 ||
                                    work.hod_comment == 1 ? (
                                      <div className="hod_box d-flex align-items-center">
                                           {work.hod_comment == 1 && (
                                          <div
                                            className="hod_img text-right"
                                            style={{ zIndex: "1" }}
                                          >
                                            <img
                                            src={`${FileUrl}/${work.hod_profile || '/users/profile.jpg'}`}
                                              alt="HOD"
                                            />
                                          </div>
                                        )}
                                        {work.admin_comment == 1 && (
                                          <div
                                            className="hod_img text-right"
                                            style={{ zIndex: "2" }}
                                          >
                                            <img
                                              src={`${FileUrl}/${work.admin_profile|| '/users/zaid.png'}`}
                                              alt="admin"
                                            />
                                          </div>
                                        )}
                                     
                                      </div>
                                    ) : (
                                      "NA"
                                    )}
                                  </div>
                                  <div className="eye_icon">
                                    <ReactSVG
                                      src={`${eyeButtonSvg}`}
                                      wrapper="span"
                                      // onClick={() =>
                                      //   handleShow(todosGroup, work)
                                      // }
                                      onClick={(event) => {
                                        event.stopPropagation(); // Stop propagation
                                        handleShow(todosGroup, work); // Execute handleShow
                                      }}
                                      beforeInjection={(svg) => {
                                        svg.setAttribute(
                                          "style",
                                          "width: 20px"
                                        );
                                      }}
                                    />
                                  </div>
                                  <React.Fragment>
                                    <div className="dropdown text-right  edit_dropdown">
                                      <button
                                        className="btn btn_action_menu transparent_btn"
                                        type="button"
                                        data-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <b>
                                          <ActionButtonJsx />
                                        </b>
                                      </button>
                                      <div className="dropdown-menu">
                                        <a
                                          className="dropdown-item"
                                          role={"button"}
                                          onClick={() => onRemoveTodo(work.id)}
                                        >
                                          delete
                                        </a>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                </div>
                              </div>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="row">
                              <div className="col-lg-8">
                                <div className="row">
                                  <div className="col-lg-8">
                                    <div className="d-flex justify-content-between">
                                      <div className="mx-4 w-100">
                                        <span className="card_inner_mini_heading">
                                          Task assigned
                                        </span>
                                        <div className="d-flex align-items-start mt-2">
                                          <img
                                            src={`${FileUrl}/img/icons/clipboard.svg`}
                                            className="in__svg pr-2"
                                            alt="project"
                                          />
                                          <textarea
                                            name="description"
                                            id="description"
                                            cols="3"
                                            value={work.description}
                                            disabled
                                            style={{
                                              flex: "1",
                                              width: "100%",
                                              minHeight: 0,
                                              border: 0,
                                              borderRadius: "10px",
                                              backgroundColor: "white",
                                            }}
                                            rows={5}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div>
                                      <p className="mb-0 card_inner_mini_heading">
                                        Member Card
                                      </p>
                                      <button
                                        className={`status_btn ${
                                          work.card
                                            ? getLastCard(work.card)
                                            : "-"
                                        }`}
                                      >
                                        {work.card
                                          ? getLastCard(work.card)
                                          : "-"}
                                      </button>
                                    </div>
                                    <div className="mt-4">
                                      <p className="mb-0 card_inner_mini_heading">
                                        Due Date
                                      </p>

                                      {GetDate(
                                        work.completed_date,
                                        "DD-MM-YYYY"
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="row mt-4">
                                  <div className="col-lg-8">
                                    <div className="d-flex justify-content-between">
                                      <div className="mx-4 w-100">
                                        <span className="card_inner_mini_heading">
                                          Reply from
                                        </span>
                                        <div className="d-flex align-items-start mt-2">
                                          <img
                                            src={`${FileUrl}/img/icons/edit_pencil.svg`}
                                            className="in__svg pr-2"
                                            alt="project"
                                          />
                                          <textarea
                                            name="reason"
                                            id="reason"
                                            cols="3"
                                            value={work.reason}
                                            disabled
                                            style={{
                                              flex: "1",
                                              width: "100%",
                                              minHeight: 0,
                                              border: 0,
                                              borderRadius: "10px",
                                              backgroundColor: "white",
                                            }}
                                            rows={5}
                                          ></textarea>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="mt-4">
                                      <p className="mb-0">Reminder Date</p>
                                      <p>
                                        {" "}
                                        {GetDate(
                                          work.remainder_date,
                                          "DD-MM-YYYY"
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                {work.actual_completed_date && (
                                  <p className="task_line_text">
                                    Task completed{" "}
                                    <span>
                                      {calculateDaysBetweenDates(
                                        work.created_at,
                                        work.actual_completed_date
                                      )}{" "}
                                      days After
                                    </span>{" "}
                                    Due date
                                  </p>
                                )}
                                <div
                                  className="remark_box"
                                  style={{
                                    border: "1px solid grey",
                                    paddingLeft: "10px",
                                    paddingTop: "10px",
                                    backgroundColor: "white",
                                  }}
                                >
                                  <h6 className="private_comment">Comments</h6>
                                  <div className="reply_box">
                                    <div className="main_chat_view_box">
                                      <div className="d-flex">
                                        <div className="profile_img">
                                          {" "}
                                          <img
                                            // src={`${FileUrl}/${work.hod_profile}`}
                                            src={
                                              work.hod_profile
                                                ? `${FileUrl}/${work.hod_profile}`
                                                : `${FileUrl}/users/profile.jpg`
                                            }
                                            alt="HOD"
                                          />
                                        </div>
                                        <div className="ps-2">
                                          <div className="d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center">
                                              <h6 className="hod_text">
                                                {work.hod_name}
                                              </h6>
                                              <p className="date_text">
                                                {" "}
                                                {GetDate(
                                                  work.hod_comment_date,
                                                  "DD-MM-YYYY"
                                                )}
                                              </p>
                                            </div>
                                            <div>
                                              <div>
                                                <button
                                                  className={`mx-5 small_status_btn status_btn ${
                                                    work.admin_card
                                                      ? getLastCard(
                                                          work.admin_card
                                                        )
                                                      : "-"
                                                  }`}
                                                >
                                                  {" "}
                                                  {work.admin_card
                                                    ? getLastCard(
                                                        work.admin_card
                                                      )
                                                    : "-"}
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <p className="chat_msg_text">
                                            {work.hod_remarks}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="main_chat_view_box">
                                      <div className="d-flex">
                                        <div className="profile_img">
                                          {" "}
                                          <img
                                            // src={`${FileUrl}/${work.admin_profile}`}
                                            src={`${FileUrl}/users/zaid.png`}
                                            alt="admin"
                                          />
                                        </div>
                                        <div className="ps-2 ">
                                          <div className="d-flex align-items-center justify-content-between pt-2">
                                            <div className="d-flex align-items-center">
                                              <h6 className="hod_text">
                                                {work.admin_name}
                                              </h6>
                                              <p className="date_text">
                                                {GetDate(
                                                  work.admin_comment_date,
                                                  "DD-MM-YYYY"
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                          <p className="chat_msg_text">
                                            <p>{work.admin_remarks}</p>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </>
                ))}
                {username == team_fullname ? (
                  <EmpTodoModal
                    show={show}
                    handleClose={handleClose}
                    todo={selectedWork}
                    todoId={selectedTodoId}
                  />
                ) : (
                  <TodoModal
                    show={show}
                    handleClose={handleClose}
                    todo={selectedWork}
                    todoId={selectedTodoId}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ListAdmin;
