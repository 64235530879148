import React, { useState, useEffect } from "react";
import ApiService from "../../../services/ApiService";
import { teamprojectlist } from "../../../config/config";
import { getToken } from "../../../services/useLocalStorage";
import { GetDate } from "../../../services/DateAndTimeService";
import { FileUrl } from "../../../config/file_url";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faFile,
  faPeopleGroup,
  faShapes,
  faFilePowerpoint,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { architectureRoute, ProjectRoute } from "../../../config/RouteConfig";

//File Reject
const TeamListProject = () => {
  const [project, setProject] = useState([]);

  const fetchproject = async () => {
    try {
      // const res = await GetEmployees(`${getToken()}`);
      const header = { Authorization: `Bearer ${getToken()}` };
      const res = await ApiService(
        null,
        0,
        header,
        teamprojectlist.method,
        teamprojectlist.url
      );
      if (!res.data.error) {
        const payload = res.data?.data;
        console.log("res-->", res);

        if (Array.isArray(payload) && payload.length) {
          setProject(payload);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchproject();
  }, []);
  const calculateDuration = (startDate) => {
    const start = new Date(startDate);
    const end = new Date();
    const timeDifference = end.getTime() - start.getTime();
    const durationInDays = Math.ceil(timeDifference / (1000 * 3600 * 24));
  
    if (durationInDays <= 30) {
      return `${durationInDays} day${durationInDays !== 1 ? 's' : ''}`;
    } else {
      const durationInMonths = Math.floor(durationInDays / 30);
      const remainingDays = durationInDays % 30;
      if (remainingDays === 0) {
        return `${durationInMonths} month${durationInMonths !== 1 ? 's' : ''}`;
      } else {
        return `${durationInMonths} month${durationInMonths !== 1 ? 's' : ''} and ${remainingDays} day${remainingDays !== 1 ? 's' : ''}`;
      }
    }
  };

  return (
    <>
      <div className="content__wrapper">
        <section className="page-content">
          <div className="page-title mobile-title">
            <h1 className="h4 mb-0">Files</h1>
            {/*<p className="mb-4">Online Process</p>*/}
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="zed__table card">
                <h5 className="text-shadow-yellow px-2 mb-4">
                  All hod Projects
                </h5>
                {project.map((project, index) => (
                  <div className="project_main_box mb-4">
                    <div className="project_header">
                      <div className="row justify-content-between">
                        <div className="col-lg-5">
                          <p>
                            {" "}
                            <FontAwesomeIcon
                              icon={faFile}
                              className="pr-3"
                            />{" "}
                            <Link
                              className="detail_href"
                              to={`/${architectureRoute.details}/${project.id}`}
                            >
                              {project.project_name} - {project.office_no}
                            </Link>
                          </p>
                        </div>

                        <div className="col-lg-7">
                          <p class="text-right">
                            {GetDate(project.project_date, "DD-MM-YYYY")}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="project_body">
                      <div className="row">
                        <div className="col-lg-5">
                          <p className="category_text">
                            <img
                              src={`${FileUrl}/img/icons/material-category.svg`}
                              className="in__svg pr-2"
                              alt="project"
                            />
                            {project.categoryname}
                          </p>
                          <p className="type_text">
                            <img
                              src={`${FileUrl}/img/icons/pdf.svg`}
                              className="in__svg pr-2"
                              alt="project"
                            />
                            {project.ProjectType}
                          </p>
                          <p className="type_text mt-2">
                            <img
                              src={`${FileUrl}/img/icons/location.svg`}
                              className="in__svg pr-2"
                              alt="project"
                            />
                            {project.google_location}
                          </p>
                        </div>
                        <div className="col-lg-4">
                          <p className="">
                            <img
                              src={`${FileUrl}/img/icons/group.svg`}
                              className="in__svg pr-2"
                              alt="project"
                            />
                            {project.departmentname}
                          </p>
                          <p className="site_text">Site Visit No. - 02</p>
                          <div className="inner_date_box">
                            <p>
                              <img
                                src={`${FileUrl}/img/icons/timer-clock.svg`}
                                className="in__svg pr-2"
                                alt="project"
                              />
                              {calculateDuration(project.project_date)} 
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <p className="ongoing_text">
                            {" "}
                            {(() => {
                              switch (project.process) {
                                case 1:
                                  return (
                                    <p className="ongoing_text">Ongoing</p>
                                  );
                                case 2:
                                  return <p className="hold_text">Hold</p>;
                                case 3:
                                  return (
                                    <p className="completed_text">Completed</p>
                                  );
                                default:
                                  return null;
                              }
                            })()}
                          </p>
                          <div className="hod_box">
                            <div
                              className="hod_img text-right"
                              style={{ zIndex: "2" }}
                            >
                              {" "}
                              <img
                                src={`${FileUrl}/${project.profile_picture}`}
                                alt="HOD"
                              />
                            </div>
                            <div
                              className="hod_img text-right"
                              style={{ zIndex: "1" }}
                            >
                              {" "}
                              <img
                                src={`${FileUrl}/${project.team1_profile_picture}`}
                                alt="HOD"
                              />
                            </div>
                            <div className="hod_img text-right">
                              {" "}
                              <img
                                src={`${FileUrl}/${project.team2_profile_picture}`}
                                alt="HOD"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default TeamListProject;
