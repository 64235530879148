import React, { useState, useEffect, useCallback } from "react";
import { getToken } from "../../../services/useLocalStorage";
import toast from "react-hot-toast";
import ApiService from "../../../services/ApiService";
import { highrisedetailbyid } from "../../../config/config";
import { useParams, useNavigate } from "react-router-dom";

//FileDetails For Project
const ProjectSummery = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [highriseData,setHighRiseData] = useState([])
  const ProjectData = async () => {
    try {
      setLoading(true);
      const header = {
        Authorization: `Bearer ${getToken()}`,
        ["Content-Type"]: "application/json",
      };
      const res = await ApiService(
        null,
        parseInt(params?.id),
        header,
        highrisedetailbyid.method,
        highrisedetailbyid.url
      );
      if (!res.data.error) {
        setLoading(false);
        const data = res.data.data;
        console.log("data-->", data);
        setHighRiseData(data)
      } else {
        setLoading(false);
        toast.error(res.data.message);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      if (err.response.data.error) {
        toast.error(err.response.data.message);
      }
    }
  };
  useEffect(() => {
    ProjectData();
  }, []);

  return (
    <div class="grey-border rounded">
      <div class="card-header bg-white">
        <h5 class="mb-0 font-weight-normal">High Rise List</h5>
      </div>
      <div class="card-body bg-white rounded-bottom p-3">
      {highriseData.map((building) => (
        <div className="high_rise_inner_card">
          <div className="card_header">
            <span className="text_box">{building.building_name}</span>
          </div>
          <div className="main_card_body">
            <div className="row">
              <div className="col-lg-4">
                <div className="d-flex align-items-center mb-2">
                  <p className="key">Basment Type :-</p>
                  <p className="value">{building.basement_type}</p>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <p className="key">Per Floor Flat :-</p>
                  <p className="value">{building.per_floor_flat}</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="d-flex align-items-center mb-2">
                  <p className="key">Floor Type :-</p>
                  <p className="value">{building.floor_type}</p>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <p className="key">Flat Type :-</p>
                  <p className="value">{building.flat_type}</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="d-flex align-items-center mb-2">
                  <p className="key">How Many Floor :-</p>
                  <p className="value">{building.how_many_floor}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
          ))}
      </div>
    </div>
  );
};

export default ProjectSummery;
