import React, { useEffect, useState, useMemo } from "react";
import ApiService from "../../../../services/ApiService";
import { GetDate } from "../../../../services/DateAndTimeService";
import { getToken } from "../../../../services/useLocalStorage";
import { getTodosByTeam } from "../../../../config/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Accordion, Nav, Tab } from "react-bootstrap";
import { ActionButtonJsx } from "../../../../services/FormCommon";
import "bootstrap/dist/css/bootstrap.min.css";
import { eyeButtonSvg, FileUrl } from "../../../../config/file_url";
import { ReactSVG } from "react-svg";
import EmpTodoModal from "../EmpTodoModal";
//Todo: Team
const ListTeam = () => {
  const [todos, setTodos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRemainder, setselectedRemainder] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedWork, setSelectedWork] = useState(null);
  const [selectedTodoId, setSelectedTodoId] = useState(null);
  const [activeTab, setActiveTab] = useState("second");

  const header = {
    Authorization: `Bearer ${getToken()}`,
    ["Content-Type"]: "application/json",
  };

  const validJson = (jsonString) => {
    try {
      return JSON.parse(jsonString);
    } catch (err) {
      return "";
    }
  };

  const fetchTodos = async () => {
    try {
      setLoading(true);
      const res = await ApiService(
        null,
        0,
        header,
        getTodosByTeam.method,
        getTodosByTeam.url
      );
      if (!res.data?.error) {
        setLoading(false);

        const payload = res?.data?.data;
        console.log("payload-->", payload);

        setTodos(payload);
        if (Array.isArray(payload) && payload.length) {
          const rows = [];

          payload.forEach((element) => {
            let card = "";

            const cardJson = validJson(element?.card);
            if (Array.isArray(cardJson) && cardJson.length > 0) {
              card = cardJson[cardJson.length - 1]?.card_type;
            }

            console.log("card-->", card);
          });
          //   setTodos(rows);
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);

      console.log(err);
    }
  };

  useEffect(() => {
    fetchTodos();
  }, []);

  const getLastCard = (jsonData) => {
    try {
      if (validJson(jsonData)) {
        const data = JSON.parse(jsonData);
        if (Array.isArray(data) && data?.length) {
          return data[data.length - 1]?.card_type;
        }
      } else {
        return "";
      }
    } catch (err) {
      console.log(err);
      return "";
    }
  };

  const handleRemainder = (event) => {
    const selected = event.target.value;
    setselectedRemainder(selected);
  };

  const filterByRemainder = (todos) => {
    if (selectedRemainder !== "") {
      return todos.filter(
        (item) => item.remainder !== null && item.remainder !== 0
      );
    }
    return todos;
  };

  const filterByTab = (todos) => {
    console.log("todos-->", todos);
    if (activeTab === "pending") {
      const today = GetDate(new Date().toISOString(), "DD-MM-YYYY");

      return todos.filter((item) => {
        if (item.completed_date) {
          const completedDate = GetDate(item.completed_date, "DD-MM-YYYY"); // Format completed_date
          return completedDate === today;
        }
        return false; // Handle cases where completed_date is null or undefined
      });
    } else {
      return todos;
    }
  };

  const filteredData = useMemo(() => {
    let filteredTodos = todos;

    filteredTodos = filterByTab(filteredTodos);
    filteredTodos = filterByRemainder(filteredTodos);
    // Sort the todos in descending order based on ID
    filteredTodos = filteredTodos.sort((a, b) => b.id - a.id);

    return filteredTodos;
  }, [todos, selectedRemainder, activeTab]);

  const groupTodos = (todos) => {
    const grouped = {};
    todos.forEach((todo) => {
      const dateKey = todo.created_at.split("T")[0];
      const key = dateKey + todo.team_fullname;
      if (!grouped[key]) {
        grouped[key] = [todo];
      } else {
        grouped[key].push(todo);
      }
    });
    return Object.values(grouped);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleShow = (todosGroup, work) => {
    setSelectedGroup(todosGroup);
    setSelectedWork(work);
    setSelectedTodoId(work.id);
    setShowModal(true);
  };

  const handleTabChange = (event) => {
    // console.log("evenet==>", event);
    const selected = event;
    setActiveTab(selected);
    // console.log("Selected Status:", selected);
  };

  const pendingTodosLength = todos.filter((todo) => {
    const today = GetDate(new Date().toISOString(), "DD-MM-YYYY");
    if (todo.completed_date) {
      const completedDate = GetDate(todo.completed_date, "DD-MM-YYYY");
      return  completedDate === today;
    }
    return false;
  }).length;

  const allTasksLength = todos.length;

  return (
    <div className="content__wrapper">
      <section className="page-content">
        <div className="page-title mobile-title">
          <h1 className="h4 mb-0">Todos</h1>
        </div>
        <div className="todos_section">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-5">
              <div className="title_heading">
                <h4 className="mb-0">All Todos</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <Tab.Container activeKey={activeTab} onSelect={handleTabChange}>
            <Nav variant="pills" className="main_nav_link row">
              <Nav.Item className="tab_box col all_task">
                <Nav.Link eventKey="second">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <img
                        src={`${FileUrl}/img/icons/barround.svg`}
                        className="in__svg pr-2"
                        alt="project"
                      />
                      <span className="project_status"> All Task</span>
                    </div>

                    <div>
                      <span className="project_counter"> {allTasksLength}</span>
                    </div>
                  </div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="tab_box col pending">
                <Nav.Link eventKey="pending">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <img
                        src={`${FileUrl}/img/icons/errorround.svg`}
                        className="in__svg pr-2"
                        alt="project"
                      />
                      <span className="project_status">Today Due Date</span>
                    </div>
                    <div>
                      <span className="project_counter">
                        {pendingTodosLength}
                      </span>
                    </div>
                  </div>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content></Tab.Content>
          </Tab.Container>
          <div className="row main_status_body">
            <div className="col-md-12">
              <div className="zed__table">
                <div className="row heading_row mt-3">
                  <div className="col-lg-4">
                    <div className="d-flex justify-content-start">
                      <p className="pr-3">sr.no</p>
                      <p className="pl-5">Project Name</p>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="d-flex justify-content-around">
                      <p>Reminder</p>
                      <p>Due Date</p>
                      <p>Status</p>
                      <p>Member Card</p>
                      <p>comments</p>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ justifyContent: "end" }}>
                  <div className="col-lg-7">
                    <h5 className="text-shadow-yellow px-2 mb-0">All Task</h5>
                  </div>
                </div>

                {groupTodos(filteredData).map((todosGroup, groupIndex) => (
                  <>
                    <div className="d-flex justify-content-between">
                      <div>
                        <p
                          style={{
                            textAlign: "right",
                            fontSize: "16px",
                            paddingTop: "5px",
                            fontWeight: "400",
                            color: "#212322",
                            marginBottom: "5px",
                          }}
                        >
                          {GetDate(todosGroup[0].created_at, "DD-MM-YYYY")}
                        </p>
                      </div>
                      <div
                        style={{
                          textAlign: "right",
                          fontSize: "16px",
                          paddingTop: "10px",
                          fontWeight: "500",
                          color: "#212322",
                        }}
                      >
                        Total Task - {todosGroup.length}
                      </div>
                    </div>

                    <Accordion
                      key={groupIndex}
                      className="table-responsive mb-2"
                    >
                      {todosGroup.map((work, workIndex) => (
                        <Accordion.Item
                          eventKey={`${groupIndex}-${workIndex}`}
                          key={workIndex}
                        >
                          <Accordion.Header className="todo_header_accrdin">
                            {/* <Accordion.Header className={`todo_header_accrdin ${
                              work.todo_status === null ||
                              work.todo_status === undefined
                                ? "In_Progress"
                                : work.todo_status === 0
                                ? "In_Progress"
                                : work.todo_status === 1
                                ? "Pending"
                                : work.todo_status === 2
                                ? "Completed"
                                : ""
                            }`}
                          > */}

                            <div className="row w-100 align-items-center">
                              <div className="col-lg-5">
                                <div className="d-flex align-items-center">
                                  <div className="mx-5 normal_text">
                                    {workIndex + 1}
                                  </div>
                                  <div className="normal_text d-flex">
                                    <img
                                      src={`${FileUrl}/img/icons/filetodo.svg`}
                                      className="in__svg pr-2"
                                      alt="project"
                                    />
                                    {work.file_name}
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="d-flex align-items-center">
                                  <div className="pr-4 pl-2 normal_text reminder_show">
                                    {work.remainder !== null &&
                                      work.remainder !== 0 && (
                                        <span className="counter_show">
                                          {work.remainder}
                                        </span>
                                      )}
                                  </div>
                                  <div className="pr-5 due_date_show">
                                    <p className="due_date_text normal_text">
                                      {GetDate(
                                        work.completed_date,
                                        "DD-MM-YYYY"
                                      )}
                                    </p>
                                  </div>
                                  <div className="">
                                    {/* <div className="col-lg-4">
                                        <div className="mx-0"> */}
                                    <p
                                      className={`incomplete_text status_btn  ${
                                        work.todo_status === null ||
                                        work.todo_status === undefined
                                          ? "In_Progress"
                                          : work.todo_status === 0
                                          ? "In_Progress"
                                          : work.todo_status === 1
                                          ? "Pending"
                                          : work.todo_status === 2
                                          ? "Completed"
                                          : ""
                                      }`}
                                    >
                                      {" "}
                                      {work.todo_status === null ||
                                      work.todo_status === undefined
                                        ? "In Progress"
                                        : work.todo_status === 0
                                        ? "In Progress"
                                        : work.todo_status === 1
                                        ? "Pending"
                                        : work.todo_status === 2
                                        ? "Completed"
                                        : ""}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-1">
                                <button
                                  className={`status_btn ${
                                    work.card ? getLastCard(work.card) : "-"
                                  }`}
                                >
                                  {work.card ? getLastCard(work.card) : "-"}
                                </button>
                              </div>

                              <div className="col-lg-2">
                                <div className="d-flex align-items-center">
                                  <div className="hod_inner_box">
                                    {work.admin_comment == 1 ||
                                    work.hod_comment == 1 ? (
                                      <div className="hod_box d-flex align-items-center">
                                        {work.hod_comment == 1 && (
                                          <div
                                            className="hod_img text-right"
                                            style={{ zIndex: "1" }}
                                          >
                                            <img
                                              src={`${FileUrl}/${
                                                work.hod_profile ||
                                                "/users/profile.jpg"
                                              }`}
                                              alt="HOD"
                                            />
                                          </div>
                                        )}
                                        {work.admin_comment == 1 && (
                                          <div
                                            className="hod_img text-right"
                                            style={{ zIndex: "2" }}
                                          >
                                            <img
                                              src={`${FileUrl}/${
                                                work.admin_profile ||
                                                "/users/zaid.png"
                                              }`}
                                              alt="admin"
                                            />
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      "NA"
                                    )}
                                  </div>
                                  <div className="eye_icon">
                                    <ReactSVG
                                      src={`${eyeButtonSvg}`}
                                      wrapper="span"
                                      onClick={(event) => {
                                        event.stopPropagation(); // Stop propagation
                                        handleShow(todosGroup, work); // Execute handleShow
                                      }}
                                      beforeInjection={(svg) => {
                                        // svg.classList.add('svg-class-name')
                                        svg.setAttribute(
                                          "style",
                                          "width: 20px"
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="row">
                              <div className="col-lg-8">
                                <div className="row">
                                  <div className="col-lg-8">
                                    <div className="d-flex justify-content-between">
                                      <div className="mx-4 w-100">
                                        <span className="card_inner_mini_heading">
                                          Task assigned
                                        </span>
                                        <div className="d-flex align-items-start mt-2">
                                          <img
                                            src={`${FileUrl}/img/icons/clipboard.svg`}
                                            className="in__svg pr-2"
                                            alt="project"
                                          />
                                          <textarea
                                            name="description"
                                            id="description"
                                            cols="3"
                                            value={work.description}
                                            disabled
                                            style={{
                                              flex: "1",
                                              width: "100%",
                                              minHeight: 0,
                                              border: 0,
                                              borderRadius: "10px",
                                              backgroundColor: "white",
                                            }}
                                            rows={5}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div>
                                      <p className="mb-0 card_inner_mini_heading">
                                        Member Card
                                      </p>
                                      <button
                                        className={`status_btn ${
                                          work.card
                                            ? getLastCard(work.card)
                                            : "-"
                                        }`}
                                      >
                                        {work.card
                                          ? getLastCard(work.card)
                                          : "-"}
                                      </button>
                                    </div>
                                    <div className="mt-4">
                                      <p className="mb-0 card_inner_mini_heading">
                                        Due Date
                                      </p>

                                      {GetDate(
                                        work.completed_date,
                                        "DD-MM-YYYY"
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="row mt-4">
                                  <div className="col-lg-8">
                                    <div className="d-flex justify-content-between">
                                      <div className="mx-4 w-100">
                                        <span className="card_inner_mini_heading">
                                          Reply from
                                        </span>
                                        <div className="d-flex align-items-start mt-2">
                                          <img
                                            src={`${FileUrl}/img/icons/edit_pencil.svg`}
                                            className="in__svg pr-2"
                                            alt="project"
                                          />
                                          <textarea
                                            name="reason"
                                            id="reason"
                                            cols="3"
                                            value={work.reason}
                                            disabled
                                            style={{
                                              flex: "1",
                                              width: "100%",
                                              minHeight: 0,
                                              border: 0,
                                              borderRadius: "10px",
                                              backgroundColor: "white",
                                            }}
                                            rows={5}
                                          ></textarea>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="mt-4">
                                      <p className="mb-0">Reminder Date</p>
                                      <p>
                                        {" "}
                                        {GetDate(
                                          work.remainder_date,
                                          "DD-MM-YYYY"
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div
                                  className="remark_box"
                                  style={{
                                    border: "1px solid grey",
                                    paddingLeft: "10px",
                                    paddingTop: "10px",
                                    backgroundColor: "white",
                                  }}
                                >
                                  <h6 className="private_comment">Comments</h6>
                                  <div className="reply_box">
                                    <div className="main_chat_view_box">
                                      <div className="d-flex">
                                        <div className="profile_img">
                                          {" "}
                                          <img
                                            src={`${FileUrl}/${
                                              work.hod_profile ||
                                              "/users/profile.jpg"
                                            }`}
                                            alt="HOD"
                                          />
                                        </div>
                                        <div className="ps-2">
                                          <div className="d-flex align-items-center justify-content-between mb-1">
                                            <div className="d-flex align-items-center">
                                              <h6 className="hod_text">
                                                {work.hod_name}
                                              </h6>
                                              <p className="date_text">
                                                {" "}
                                                {GetDate(
                                                  work.hod_comment_date,
                                                  "DD-MM-YYYY"
                                                )}
                                              </p>
                                            </div>
                                            <div>
                                              <div>
                                                <button
                                                  className={`mx-5 status_btn ${
                                                    work.admin_card
                                                      ? getLastCard(
                                                          work.admin_card
                                                        )
                                                      : "-"
                                                  }`}
                                                >
                                                  {" "}
                                                  {work.admin_card
                                                    ? getLastCard(
                                                        work.admin_card
                                                      )
                                                    : "-"}
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <p className="chat_msg_text">
                                            {work.hod_remarks}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="main_chat_view_box">
                                      <div className="d-flex">
                                        <div className="profile_img">
                                          {" "}
                                          <img
                                            src={`${FileUrl}/${
                                              work.admin_profile ||
                                              "/users/zaid.png"
                                            }`}
                                            alt="admin"
                                          />
                                        </div>
                                        <div className="ps-2">
                                          <div className="d-flex align-items-center justify-content-between mb-1">
                                            <div className="d-flex align-items-center">
                                              <h6 className="hod_text">
                                                {work.admin_name}
                                              </h6>
                                              <p className="date_text">
                                                {GetDate(
                                                  work.admin_comment_date,
                                                  "DD-MM-YYYY"
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                          <p className="chat_msg_text">
                                            <p>{work.admin_remarks}</p>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </>
                ))}
                <EmpTodoModal
                  show={showModal} // Pass down the show state
                  handleClose={handleCloseModal} // Pass down the handleClose function
                  todo={selectedWork}
                  todoId={selectedTodoId}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ListTeam;
