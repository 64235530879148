import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  memo,
  useRef,
} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GetDate } from "../../../../services/DateAndTimeService";
import { getToken } from "../../../../services/useLocalStorage";
import toast from "react-hot-toast";
import { SwalAlert } from "../../../../UtilsComponent/SwalDialog";
import { image2svg } from "../../../../UtilsComponent/UtilFunctions";
import file_url, { FileUrl } from "../../../../config/file_url";
import ApiService from "../../../../services/ApiService";
import {
  deleteOfficeExpenseForAdmin,
  getOfficeExpenseForAdmin,
} from "../../../../config/config";
import { OfficeExpenseRoute } from "../../../../config/RouteConfig";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  datatableHeight,
  paginationRows,
} from "../../../../config/CommonVariables";
import {
  exportToCsv,
  loadingTemplate,
  notFoundTemplate,
  actionButtonObject,
  ActionButtonJsx,
} from "../../../../services/FormCommon";
import {
  useSortBy,
  useTable,
  usePagination,
  useGlobalFilter,
  useRowSelect,
} from "react-table";
import { MyLoader } from "../../../../UtilsComponent/MyLoader";

const Admin_list = () => {
  const [expenses, setExpenses] = useState([]);

  const [loading, setLoading] = useState(false);

  const header = {
    Authorization: `Bearer ${getToken()}`,
    ["Content-Type"]: "application/json",
  };

  const fetchExpense = async () => {
    try {
      setLoading(true);
      const res = await ApiService(
        null,
        0,
        header,
        getOfficeExpenseForAdmin.method,
        getOfficeExpenseForAdmin.url
      );
      if (!res?.data?.error) {
        setLoading(false);

        const payload = res?.data?.data;
        if (Array.isArray(payload) && payload.length > 0) {
          const rows = [];

          payload.forEach((element) => {
            rows.push({
              id: element?.id,
              [hname.date]: element?.date
                ? GetDate(element?.date, "DD-MMM-YYYY")
                : "-",
              [hname.amount]: element?.amount,
              [hname.description]: element?.description,
              [hname.team_fullname]: element?.team_fullname,
              // [hname.status]:
              //   element?.is_approved == 1
              //     ? "Approved"
              //     : element?.is_approved == 0
              //     ? "Pending"
              //     : "Rejected",
              [hname.status]: (
                <span
                  className={
                    element?.is_approved === 1
                      ? "approved"
                      : element?.is_approved === 0
                      ? "pending"
                      : "rejected"
                  }
                >
                  {element?.is_approved === 1
                    ? "Approved"
                    : element?.is_approved === 0
                    ? "Pending"
                    : "Rejected"}
                </span>
              ),
              // [hname.action]:element?.id
            });
          });

          setExpenses(rows);
          // setExpenses(rows);
        } else {
        }
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);

      console.log(err);
      toast.error(
        err?.response?.data?.message
          ? err?.response?.data?.message
          : "Something went wrong"
      );
    }
  };

  useEffect(() => {
    fetchExpense();
  }, []);

  const hname = {
    date: "Date",
    amount: "Amount",
    description: "Description",
    team_fullname: "Team",
    status: "Status",
    action: "action",
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "id",
        accessor: "id",
      },
      {
        Header: "Date",
        accessor: `${hname.date}`,
      },
      {
        Header: "Amount",
        accessor: `${hname.amount}`,
      },
      {
        Header: "Description",
        accessor: `${hname.description}`,
      },
      {
        Header: "Team",
        accessor: `${hname.team_fullname}`,
      },
      {
        Header: "Status",
        accessor: `${hname.status}`,
      },
    ],
    []
  );

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "Edit",
        Header: "",
        Cell: ({ row }) => (
          <React.Fragment>
            <div className="dropdown">
              <button
                className="btn btn-secondary btn_action_menu"
                type="button"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <b>
                  <ActionButtonJsx />
                </b>
              </button>
              <div className="dropdown-menu">
                <Link
                  className="dropdown-item"
                  to={`/${OfficeExpenseRoute.preview}/${row?.values?.id}`}
                >
                  {actionButtonObject.preview_expense}
                </Link>
                <Link
                  className="dropdown-item"
                  to={`/${OfficeExpenseRoute.edit}/${row?.values?.id}`}
                >
                  {actionButtonObject.edit}
                </Link>
                <a
                  className="dropdown-item"
                  role={"button"}
                  onClick={() => onRemoveExpense(row?.values?.id)}
                >
                  {actionButtonObject.delete}
                </a>
              </div>
            </div>
          </React.Fragment>
        ),
      },
    ]);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    page,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data: expenses,
      initialState: { hiddenColumns: ["id"], pageIndex: 0, pageSize: 100 },
    },
    tableHooks,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect, // Add useRowSelect hook
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Add a checkbox column
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: ({ row }) => (
            <div>
              <input type="checkbox" {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  const removeExpense = async (id) => {
    try {
      setLoading(true);

      const res = await ApiService(
        null,
        parseInt(id),
        header,
        deleteOfficeExpenseForAdmin.method,
        deleteOfficeExpenseForAdmin.url
      );
      if (!res?.data?.error) {
        setLoading(false);
        toast.success(res?.data?.message);
        setList(id);
      } else {
        setLoading(false);

        toast.error(res?.data?.message);
      }
    } catch (err) {
      setLoading(false);

      console.log(err);
    }
  };

  const setList = (id) => {
    setExpenses((prevState) => {
      return prevState.filter((v) => {
        return v?.id != id;
      });
    });
  };

  const onRemoveExpense = (id) => {
    SwalAlert({})
      .then(async (result) => {
        if (result?.isConfirmed) {
          if (parseInt(id) != NaN) {
            await removeExpense(id);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="content__wrapper">
        <section className="page-content">
          <div className="page-title mobile-title">
            <h1 className="h4 mb-0">Office Expense</h1>
            {/*<p className="mb-4">Online Process</p>*/}
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="zed__table card">
                <h5 className="text-shadow-yellow px-2 mb-4">
                  All Office Expense
                </h5>
                <div className="row">
                  <div className="col-lg-3 col-12">
                    <GlobalFilter
                      filter={globalFilter}
                      setFilter={setGlobalFilter}
                    />
                  </div>
                </div>
                <div className="table-responsive">
                  {loading ? (
                    <MyLoader value={false} />
                  ) : (
                    <table className="table expense_table datatable" {...getTableProps()}>
                      <thead>
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps()
                                )}
                              >
                                {column.render("Header")}
                                <span>
                                  {column.isSorted
                                    ? column.isSortedDesc
                                      ? " 🔽"
                                      : " 🔼"
                                    : ""}
                                </span>
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {/* {rows.map(row => { */}
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => {
                                return (
                                  <td {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <td colSpan={6}>
                          <div className="pagination justify-content-end align-items-center">
                            <button
                              className="pagination_button"
                              onClick={() => gotoPage(0)}
                              disabled={!canPreviousPage}
                            >
                              {"<<"}
                            </button>
                            <button
                              className="pagination_button"
                              onClick={() => previousPage()}
                              disabled={!canPreviousPage}
                            >
                              {"<"}
                            </button>
                            <span>
                              Page{" "}
                              <strong>
                                {pageIndex + 1} of {pageOptions.length}
                              </strong>
                            </span>
                            <button
                              className="pagination_button"
                              onClick={() => nextPage()}
                              disabled={!canNextPage}
                            >
                              {">"}
                            </button>
                            <button
                              className="pagination_button"
                              onClick={() => gotoPage(pageCount - 1)}
                              disabled={!canNextPage}
                            >
                              {">>"}
                            </button>
                          </div>
                        </td>
                      </tfoot>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Admin_list;

const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <span>
      <input
        className="form-control"
        type={"search"}
        style={{ margin: "5px" }}
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        placeholder="Search"
      />
    </span>
  );
};
