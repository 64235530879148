import React, { useState, useEffect } from "react";
import ProjectSummery from "./ProjectSummery";
import TentativePlan from "./TentativePlan";
import HighRiseDetail from "./HighriseDetail";
import Projectstatus from "./Projectstatus";
import HighRiseList from "./HighRiseList";
import BuildingDetails from "./BuildingDetails";
import axios from "axios";
import toast from "react-hot-toast";
import { getToken } from "../../../services/useLocalStorage";
import ApiService from "../../../services/ApiService";
import { fetchmstproject, highrisedetailbyid } from "../../../config/config";
import { useParams } from "react-router-dom";
import { AuthHeader } from "../../../services/FormCommon";

//Project Details
const Details = () => {
  const params = useParams();

  const stageList = [
    { id: 1, title: "Project Summery" },
    { id: 2, title: "Tentative Plan" },
    { id: 3, title: "High Rise Detail" },
    { id: 4, title: "High Rise List" },
    { id: 6, title: "Project Status" },
  ];

  const [currentState, setCurrentState] = useState(1);
  const [lastStage, setLastStage] = useState(6);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState({});
  const [buildingdata, setBuildingData] = useState([]);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [selectbuildingId, setSelectedBuildingId] = useState(null);

  const fetchFile = async () => {
    try {
      const res = await axios({
        method: fetchmstproject.method,
        url: `${fetchmstproject.url}/${params?.id}`,
        headers: AuthHeader(),
      });

      if (!res?.data?.error) {
        setFile(res?.data?.data[0]);
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const UpdateStage = (num) => {
    setLastStage(num);
    renderComponent(num);
  };

  const renderComponent = (num, buildingName = null,buildingId = null) => {
    console.log(`Clicked on stage ${num}`);

    setCurrentState(num);
    setSelectedBuilding(buildingName);
    setSelectedBuildingId(buildingId);
    if (num === 1) {
      localStorage.removeItem("ischecked");
      localStorage.removeItem("slabarea");
      localStorage.removeItem("cop");
      localStorage.removeItem("building");
      localStorage.removeItem("residence");
      localStorage.removeItem("commercial");
    }
  };

  const ProjectData = async () => {
    try {
      setLoading(true);
      const header = {
        Authorization: `Bearer ${getToken()}`,
        ["Content-Type"]: "application/json",
      };
      const res = await ApiService(
        null,
        parseInt(params?.id),
        header,
        highrisedetailbyid.method,
        highrisedetailbyid.url
      );
      if (!res.data.error) {
        setLoading(false);
        const data = res.data.data;
        console.log("data-->", data);
        setBuildingData(data);
      } else {
        setLoading(false);
        toast.error(res.data.message);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      if (err.response.data.error) {
        toast.error(err.response.data.message);
      }
    }
  };

  useEffect(() => {
    fetchFile();

    ProjectData();
  }, []);

  return (
    <div class="content__wrapper">
      <div class="page__header pt-3 position-relative">
        <div class="container-fluid">
          <h1>{file?.project_name ? file?.project_name : ""}</h1>
          <h6>
            Office File No.: {file?.office_no ? file?.office_no : ""}
          </h6>
        </div>
      </div>
      <section class="py-4">
        <div class="container-fluid">
          <div class="card__wrapper">
            <div class="row">
              <div class="col file_details_sidebar">
                <div class="card height-auto">
                  <ul class="file__process-list list-unstyled">
                    <ul class="file__process-list list-unstyled">
                      {stageList.map((s, i) => (
                        <React.Fragment>
                          {s?.id <= lastStage ? (
                            <li
                              onClick={() => renderComponent(s?.id)}
                              className={` ${
                                s?.id == currentState ? "active" : ""
                              }`}
                            >
                              <a href="javascript:void(0)">
                                <span>
                                  {s?.id == currentState ? (
                                    <i className="fas fa-pen"></i>
                                  ) : (
                                    i + 1
                                  )}
                                </span>
                                {s?.title}
                              </a>
                              {s.id === 4 && ( // Render building details only when High Rise List stage is selected
                                <ul className="pl-5">
                                  {buildingdata.map((building) => (
                                    <li key={building.id}>
                                      <a
                                      href="javascript:void(0)"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          renderComponent(5, building.building_name, building.id);
                                        }}
                                        className={
                                          currentState === 5 ? "active" : ""
                                        }
                                      >
                                        Building Detail -{" "}
                                        {building.building_name}
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </li>
                          ) : (
                            <li
                              onClick={null}
                              className={` ${
                                s?.id == currentState ? "active" : ""
                              }`}
                            >
                              <a href="javascript:void(0)">
                                <span>
                                  {s?.id == currentState ? (
                                    <i className="fas fa-pen"></i>
                                  ) : (
                                    i + 1
                                  )}
                                </span>
                                {s?.title}
                              </a>
                            </li>
                          )}
                        </React.Fragment>
                      ))}
                    </ul>
                  </ul>
                </div>
              </div>
              <div class="col">
                {(() => {
                  switch (currentState) {
                    case 1:
                      return <ProjectSummery />;
                    case 2:
                      return <TentativePlan />;
                    case 3:
                      return <HighRiseDetail />;
                    case 4:
                      return <HighRiseList />;
                    case 5: 
                      return <BuildingDetails buildingName={selectedBuilding} buildingId={selectbuildingId}/>;
                    case 6:
                      return <Projectstatus />;

                    default:
                      return <ProjectSummery />;
                  }
                })()}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Details;
