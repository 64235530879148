import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import useAuth from "../../../services/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
import $ from "jquery";
import { FileUrl } from "../../../config/file_url";
import {
  CustRoute,
  EmpRoute,
  FilesRoute,
  FileExpenseRoute,
  OfficeExpenseRoute,
  dashboard,
  profile_details,
  DepositExpenseRoute,
  TodosRoute,
  SearchRoute,
  LeaveRoute,
  SiteRoute,
  architectureRoute,
  liasoningRoute,
  InterioRoute,
} from "../../../config/RouteConfig";
import { image2svg } from "../../../UtilsComponent/UtilFunctions";
import {
  Cordinator,
  isAdmin,
  getFullName,
  getUserProfilePic,
  isEmp,
  ishod,
  superAccess,
} from "../../../services/useLocalStorage";
import { useForm } from "react-hook-form";
import {
  adminKey,
  customerKey,
  empKey,
  subadmin,
  cordinator,
} from "../../../config/RoleConfig";
import { getRole } from "../../../services/useLocalStorage";
import ApiService from "../../../services/ApiService";
import { AuthHeader } from "../../../services/FormCommon";
import {
  dashboardInfo,
  getNotification,
  getNotification_admin,
  getNotification_employee,
  SendToSeenNotification,
} from "../../../config/config";

import {
  addDaysFromDays,
  GetDate,
  GetTime,
  parseDate,
  todayDate,
} from "../../../services/DateAndTimeService";

import jwt_decode from "jwt-decode";

const Header = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    register,
    formState: { errors },
    getValues,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
  } = useForm({
    mode: "onChange",
  });

  const [notifications, setNotifications] = useState([]);

  //Super access
  const [todoNotification, setTodoNotification] = useState([]);

  //Employee etc;
  const [todoNotification_forOthers, setTodoNotification_forOthers] = useState(
    []
  );
  const [userId, setUserId] = useState("");

  // Example usage
  // const token = 'userinfo';
  // const decodedToken = jwt_decode(token);
  //  const ID = decodedToken.id;

  // console.log("ID======>",ID);
  // console.log("decodedToken====>",decodedToken)

  useEffect(() => {
    // Retrieve the token from local storage
    const userinfo = JSON.parse(localStorage.getItem("userinfo"));
    const token = userinfo.token;

    // Decode the token to get the user ID
    if (token) {
      console.log("token==>", token);
      const decodedToken = jwt_decode(token);
      const userId = decodedToken.id;
      setUserId(userId);
    }
  }, []);

  // console.log("userid===>", userId);

  const notificationTodo = async () => {
    try {
      if (superAccess([adminKey, subadmin])) {
        const res = await ApiService(
          null,
          0,
          AuthHeader(),
          getNotification_admin.method,
          getNotification_admin.url
        );
        if (!res?.data?.error) {
          const data1 = res?.data?.data;
          if (Array.isArray(data1) && data1?.length) {
            const arr = [];
            for (let d of data1) {
              const data = {
                id: d?.id,
                json_data: validJson(d?.json_data)
                  ? JSON.parse(d?.json_data)
                  : {},
                table_name: d?.table_name,
                fullname: d?.fullname,
                child_id: d?.child_id,
                created_at: d?.created_at,
              };

              arr.push(data);
            }
            setTodoNotification(arr);
          }
        } else {
        }
      }

      if (isEmp()) {
        const res = await ApiService(
          null,
          0,
          AuthHeader(),
          getNotification_employee.method,
          getNotification_employee.url
        );
        if (!res?.data?.error) {
          const data1 = res?.data?.data;
          if (Array.isArray(data1) && data1?.length) {
            const arr = [];
            for (let d of data1) {
              const data = {
                id: d?.id,
                json_data: validJson(d?.json_data),
                table_name: d?.table_name,
                child_id: d?.child_id,
                created_at: d?.created_at,
              };

              arr.push(data);
            }
            setTodoNotification_forOthers(arr);
          }
        } else {
        }
      }
    } catch (err) {
      console.log(err);
      toast.error(`${err?.response?.data?.message}`);
    }
  };

  const validJson = (jsonString) => {
    try {
      return JSON.parse(jsonString);
    } catch (err) {
      console.log(err);
      return {};
    }
  };

  const onSubmit = async (data) => {
    console.log(data);
    navigate(`/${SearchRoute.list}/${data?.key}`);
  };

  const doLogout = () => {
    localStorage.removeItem("userinfo");
    localStorage.removeItem("wallet");
    localStorage.removeItem("path");
    navigate("/", { state: { name: location }, replace: true });
  };

  // const seenNotification = (route, data = null, fireApi = false) => {
  //   if (route) {
  //     if (fireApi) {
  //       ApiService(
  //         data,
  //         0,
  //         AuthHeader(),
  //         SendToSeenNotification.method,
  //         SendToSeenNotification.url
  //       );
  //     }
  //     navigate(`${route}`);
  //   }
  // };
  const seenNotification = (route, data = null, fireApi = false, index) => {
    if (fireApi) {
      ApiService(
        data,
        0,
        AuthHeader(),
        SendToSeenNotification.method,
        SendToSeenNotification.url
      );
    }
    clearMessage(index);
    // Navigate to the specified route if needed
    if (route) {
      if (fireApi) {
        ApiService(
          data,
          0,
          AuthHeader(),
          SendToSeenNotification.method,
          SendToSeenNotification.url
        );
      }
      navigate(`${route}`);
    }
  };

  const clearMessage = (index) => {
    const updatedNotifications = [...todoNotification];
    updatedNotifications.splice(index, 1);
    setTodoNotification(updatedNotifications);
  };

  useEffect(() => {
    image2svg();
    $(".toggle__menu").on("click", function (e) {
      e.preventDefault();
      $(this).toggleClass("open");
      $("body").toggleClass("sidebar-toggle");
    });
  }, []);

  useEffect(() => {
    notificationTodo();
  }, [window.location.pathname]);

  return (
    <>
      <header className="header navbar navbar-expand justify-content-between">
        <div className="page-title">
          <h1 className="h4 mb-0">
            {props?.headerTitle
              ? props.headerTitle
              : window.location.pathname.split("/")[1]}
          </h1>
        </div>
        <div className="header-action-form d-flex">
          {[adminKey].includes(getRole()) ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group mb-0">
                <i className="search-icon">
                  <img
                    src={`${FileUrl}/img/icons/search.svg`}
                    className="in__svg"
                    alt="search"
                  />
                </i>
                <input
                  type="text"
                  name="key"
                  id="key"
                  {...register("key", { required: true })}
                  className="form-control"
                  placeholder="Search"
                />
              </div>
            </form>
          ) : (
            ""
          )}
          <div className="header-add-action dropdown">
            <a
              className="btn btn-sm"
              role="button"
              id="add-plus"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Add +
            </a>
            <ul
              className="list-unstyled dropdown-menu"
              aria-labelledby="add-plus"
            >
              {/* <li className="dropdown-item">
                <Link to={`/${TodosRoute.add}`}>
                  <img
                    src={`${FileUrl}/img/icons/activity.svg`}
                    className="in__svg"
                    alt="Activity"
                  />{" "}
                  Todo
                </Link>
              </li> */}
              {isAdmin() || Cordinator() || ishod() ? (
                <li className="dropdown-item">
                  <Link to={`/${TodosRoute.add}`}>
                    <img
                      src={`${FileUrl}/img/icons/activity.svg`}
                      className="in__svg"
                      alt="Activity"
                    />{" "}
                    Todo
                  </Link>
                </li>
              ) : null}
              {isEmp() ? (
                <li className="dropdown-item">
                  <Link to={`/${EmpRoute.EditProfile}/${userId}`}>
                    <img
                      src={`${FileUrl}/img/icons/two-user.svg`}
                      className="in__svg"
                      alt="Employees"
                    />{" "}
                    Profile
                  </Link>
                </li>
              ) : (
                <li className="dropdown-item">
                  <Link to={`/${EmpRoute.add}`}>
                    <img
                      src={`${FileUrl}/img/icons/two-user.svg`}
                      className="in__svg"
                      alt="Employees"
                    />{" "}
                    Team
                  </Link>
                </li>
              )}

        
              <li className="dropdown-item">
                <Link to={`/${FilesRoute.add}`}>
                  <img
                    src={`${FileUrl}/img/icons/folder.svg`}
                    className="in__svg"
                    alt="Employees"
                  />{" "}
                  File
                </Link>
              </li>
              {superAccess([adminKey, empKey]) &&
              //  ?
              !Cordinator() ? (
                <>
                  <li className="dropdown-item">
                    <Link to={`/${FileExpenseRoute.add}`}>
                      <img
                        src={`${FileUrl}/img/icons/chart.svg`}
                        className="in__svg"
                        alt="Employees"
                      />{" "}
                      File Expense
                    </Link>
                  </li>

                  <li className="dropdown-item">
                    <Link to={`/${OfficeExpenseRoute.add}`}>
                      <img
                        src={`${FileUrl}/img/icons/paper.svg`}
                        className="in__svg"
                        alt="Employees"
                      />{" "}
                      Office Expense
                    </Link>
                  </li>
                </>
              ) : (
                //  :
                ""
              )}
              {superAccess([adminKey]) ? (
                <>
                  <li className="dropdown-item">
                    <Link to={`/${CustRoute.add}`}>
                      <img
                        src={`${FileUrl}/img/icons/profile.svg`}
                        className="in__svg"
                        alt="Employees"
                      />{" "}
                      Client
                    </Link>
                  </li>
                  <li className="dropdown-item">
                    <Link to={`/${DepositExpenseRoute.add}`}>
                      <img
                        src={`${FileUrl}/img/icons/paper.svg`}
                        className="in__svg"
                        alt="Employees"
                      />{" "}
                      Deposit
                    </Link>
                  </li>
                </>
              ) : (
                ""
              )}
              {superAccess([adminKey]) ? (
                <>
                  <li className="dropdown-item">
                    <Link to={`/${architectureRoute.add}`}>
                      <img
                        src={`${FileUrl}/img/icons/architecture.svg`}
                        className="in__svg"
                        alt="Employees"
                      />{" "}
                      Architecture
                    </Link>
                  </li>
                  {/* <li className="dropdown-item">
                    <Link to={`/${liasoningRoute.add}`}>
                      <img
                        src={`${FileUrl}/img/icons/liasoning.svg`}
                        className="in__svg"
                        alt="Employees"
                      />{" "}
                      Liasoning
                    </Link> 
                  </li> */}
                  <li className="dropdown-item">
                    <Link to={`/${InterioRoute.add}`}>
                      <img
                        src={`${FileUrl}/img/icons/interior.svg`}
                        className="in__svg"
                        alt="Employees"
                      />{" "}
                      Interior
                    </Link>
                  </li>
                </>
              ) : (
                ""
              )}
               {isEmp() ? (
              <li className="dropdown-item">
                <Link to={`/${LeaveRoute.add}`}>
                  <img
                    src={`${FileUrl}/img/icons/paper.svg`}
                    className="in__svg"
                    alt="Employees"
                  />{" "}
                  Leave
                </Link>
              </li>
               ) : (
                ""
              )}
              <li className="dropdown-item">
                <Link to={`/${SiteRoute.add}`}>
                  <img
                    src={`${FileUrl}/img/icons/siteinspect.svg`}
                    className="in__svg"
                    // alt="Employees"
                  />{" "}
                  Site Inspection
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <ul className="navbar-nav topbar__menu list-unstyled align-items-center">
          <li className="dropdown">
            <a
              role="button"
              id="notification"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="header-action-icon">
                <img
                  src={`${FileUrl}/img/icons/notification.svg`}
                  className="in__svg"
                  alt="Employees"
                />
              </span>

              {superAccess([adminKey, subadmin]) ? (
                todoNotification?.length > 0 ? (
                  <span className="notification-icon-badge"></span>
                ) : (
                  ""
                )
              ) : isEmp() ? (
                todoNotification_forOthers?.length > 0 ? (
                  <span className="notification-icon-badge"></span>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </a>
            <div
              className="dropdown-menu dropdown-menu-right"
              aria-labelledby="notification"
            >
              <h6 className="mb-3">
                <span className="float-right">
                  <a>{/* <small>Clear All</small> */}</a>
                </span>
                Notification
              </h6>
              <ul
                className="notification__list list-unstyled"
                style={{ maxHeight: "300px", overflowY: "auto" }}
              >
                <li className="notification--item unread">
                

                  {superAccess([adminKey, subadmin]) ? (
                    <>
                      {todoNotification.length > 0 ? (
                        <>
                          {todoNotification.map((n, i) => (
                            <div className="dropdown-item d-flex">
                              <div className="notification-details ml-2">
                                <button
                                  className="close"
                                  onClick={() => {
                                    clearMessage(i);
                                    seenNotification(
                                      `/${TodosRoute.markTodo}/${n?.child_id}`,
                                      { id: n?.id }, // set data to null
                                      true
                                    );
                                  }}
                                  style={{
                                    position: "absolute",
                                    top: "-2px",
                                    right: "0",
                                    fontSize: "12px",
                                    padding: "4px",
                                  }}
                                >
                                  X
                                </button>
                                <h5 className="user-name mb-0">
                                  {n?.fullname}{" "}
                                  <small>
                                    {GetDate(n?.created_at, "DD-MM-YYYY")}{" "}
                                    {GetTime(n?.created_at, "hh:mm:ss A")}
                                  </small>
                                </h5>
                                <p className="user-message small text-truncate mb-0">
                                  {n?.json_data?.title}
                                </p>
                                <p className="user-message small text-truncate mb-0">
                                  {n?.json_data?.message}
                                </p>
                                <p className="user-message small text-truncate mb-0">
                                  Card :{" "}
                                  {n?.json_data?.card
                                    ? n?.json_data?.card.toUpperCase()
                                    : "-"}
                                </p>
                                <a
                                  role="button"
                                  onClick={() =>
                                    seenNotification(
                                      `/${TodosRoute.reviewtodo}/${n?.child_id}`,
                                      { id: n?.id },
                                      true
                                    )
                                  }
                                >
                                  View details{" "}
                                  <img
                                    src={`${FileUrl}/img/icons/right-arrow-group.svg`}
                                    className="in__svg"
                                    alt="Employees"
                                  />
                                </a>
                              </div>
                            </div>
                          ))}
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {isEmp() ? (
                    <>
                      {todoNotification_forOthers.length > 0 ? (
                        <>
                          {todoNotification_forOthers.map((n, i) => (
                            <div className="dropdown-item d-flex">
                              <div className="notification-details ml-2">
                                <button
                                  className="close"
                                  onClick={() => {
                                    clearMessage(i);
                                    seenNotification(
                                      "",
                                      // `/${TodosRoute.markTodo}/${n?.child_id}`,
                                      { id: n?.id }, // set data to null
                                      true,
                                      i
                                    );
                                  }}
                                  style={{
                                    position: "absolute",
                                    top: "-2px",
                                    right: "0",
                                    fontSize: "12px",
                                    padding: "4px",
                                  }}
                                >
                                  X
                                </button>
                                <h5 className="user-name mb-0">
                                  Admin{" "}
                                  <small>
                                    {GetDate(n?.created_at, "DD-MM-YYYY")}{" "}
                                    {GetTime(n?.created_at, "hh:mm:ss A")}
                                  </small>
                                </h5>
                                <p className="user-message small text-truncate mb-0">
                                  {n?.json_data?.title}
                                </p>
                                <p className="user-message small text-truncate mb-0">
                                  {n?.json_data?.message}
                                </p>
                                <a
                                  role="button"
                                  onClick={() =>
                                    seenNotification(
                                      `/${TodosRoute.markTodo}/${n?.child_id}`,
                                      { id: n?.id },
                                      true
                                    )
                                  }
                                >
                                  View details{" "}
                                  <img
                                    src={`${FileUrl}/img/icons/right-arrow-group.svg`}
                                    className="in__svg"
                                    alt="Employees"
                                  />
                                </a>
                              </div>
                            </div>
                          ))}
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}

                
                </li>
               
              </ul>
            </div>
          </li>
          <li className="dropdown">
            <a
              role="button"
              id="message"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="header-action-icon">
                <img
                  className="in__svg"
                  src={`${FileUrl}/img/icons/message.svg`}
                  alt="Envelope"
                />
              </span>
              <span className="notification-icon-badge"></span>
            </a>
            <div
              className="notification__list dropdown-menu dropdown-menu-right"
              aria-labelledby="message"
            >
             
            </div>
          </li>
          <li className="dropdown">
            <a
              role="button"
              id="user-account"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {/* <span className="account-user-avatar"><img src={`${FileUrl}/img/user.png`} alt="User" /></span> */}
              <span className="account-user-avatar">
                <img src={`${getUserProfilePic()}`} alt="User" />
              </span>
              <span className="account-user-name">{getFullName()}</span>
            </a>
            <ul
              className="list-unstyled dropdown-menu"
              aria-labelledby="user-account"
            >
              
              <li className="dropdown-item">
                <a href="#" onClick={doLogout}>
                  Logout
                </a>
              </li>
            </ul>
          </li>
          <li className="mobile-menu">
            <img
              src={`${FileUrl}/img/icons/mobile-menu.svg`}
              className="in__svg"
              alt="Employees"
            />
          </li>
        </ul>
      </header>

    
    </>
  );
};

export default Header;
