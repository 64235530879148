import axios from 'axios';

export const callApi = (Method,Url,Data,Id,setHeader) =>{
    
    let option = {
        method:Method,
        url:Url
    }

    if(Data!=null){
        option = {...option,data:Data};
    }

    if(Id!=0){
        option = {...option,url:`${Url}/${Id}`};
    }

    if(setHeader){
        option = {...option,headers:setHeader}
    }

    console.log(option);

    return axios(option).then((response)=>{
        return response;
    }).catch((err)=>{
        return err;
    })

}