import React, { useEffect, useState, useCallback, useMemo, memo, useRef } from 'react'
import { Link } from 'react-router-dom';
import { deleteCustomer, getCustomers } from '../../../services/CustomerService';
import { getToken } from '../../../services/useLocalStorage';
import toast, { Toaster } from 'react-hot-toast';
import { CustRoute } from '../../../config/RouteConfig';
import { SwalAlert } from '../../../UtilsComponent/SwalDialog';
import { ReactComponent as Edit } from '../../../assets/svg/edit.svg';
import { ReactComponent as Delete } from '../../../assets/svg/delete.svg';
import { FileUrl } from '../../../config/file_url';
import { image2svg } from '../../../UtilsComponent/UtilFunctions';
import { AgGridReact } from "ag-grid-react";
// import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import ApiService from '../../../services/ApiService';
import { fetchCust } from '../../../config/config';
import { paginationRows } from '../../../config/CommonVariables';
import { ActionButtonJsx, actionButtonObject, exportToCsv, loadingTemplate, notFoundTemplate } from '../../../services/FormCommon';
import { useSortBy, useTable, usePagination, useGlobalFilter,useRowSelect } from 'react-table';
import { MyLoader } from '../../../UtilsComponent/MyLoader';




//Customer
const List = () => {

    const tableRef = useRef(null);
    const [custData, setCustData] = useState([]);
    const [loading,setLoading] = useState(false);


    const fetchCustomers = async () => {
        try {
            setLoading(true);
            const header = { Authorization: `Bearer ${getToken()}`, ['Content-Type']: 'application/json' };
            const res = await ApiService(null, 0, header, fetchCust.method, fetchCust.url);
            if (!res.data.error) {
            setLoading(false);


                const payload = res?.data?.data;

                if (Array.isArray(payload) && payload?.length > 0) {
                    const rows = [];
                    payload.forEach(element => {
                        rows.push({
                            id: element?.id,
                            [hname.first_name]: element?.first_name,
                            [hname.last_name]: element?.last_name,
                            [hname.email]: element?.email,
                            [hname.mobile]: element?.phone,
                            // [hname.action]:element?.id
                        });
                    });

                    setCustData(rows);
                }
                else {
                    setLoading(false);

                }

            } else {
                setLoading(false);

                toast.error(res.data?.message);
            }
        } catch (err) {
            setLoading(false);

            console.log(err);
            toast.error(err?.response?.data?.message ? err?.response?.data?.message : "An Error occured");
        }
    };

    useEffect(() => {
        fetchCustomers();
    }, []);

    const hname = {
        first_name: "First name",
        last_name: "Last name",
        email: "Email",
        mobile: "Mobile",
        action: "action"
    };

    const actionRender = (p) => {
        return <>

            <Link to={`/${CustRoute.edit}/${p?.value}`} style={{ color: "black" }}><i className="fas fa-pencil-alt"></i></Link>&nbsp;
            <a role={"button"} onClick={() => onRemoveCustomer(p?.value)}><i className="fa fa-trash" aria-hidden="true"></i></a>
        </>
    }

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        resizable: true,
        flex: 1,
        filterParams: {
            debounsMs: 0,
            // buttons:['apply','clear','reset','cancel']
        }
    }), []);

    const columnDef = [
        { field: hname.first_name },
        { field: hname.last_name },
        { field: hname.email },
        { field: hname.mobile },
        { field: hname.action, sortable: false, filter: false, cellRenderer: memo(actionRender) }
    ];

    const columns = React.useMemo(
        () => [
            {
                Header: 'id',
                accessor: 'id',
            },
            {
                Header: 'First Name',
                accessor: `${hname.first_name}`
            },
            {
                Header: 'Last Name',
                accessor: `${hname.last_name}`
            },
            {
                Header: 'Email',
                accessor: `${hname.email}`
            },
            {
                Header: 'Mobile',
                accessor: `${hname.mobile}`
            },
        ],
        []
    )

    const tableHooks = (hooks) => {
        hooks.visibleColumns.push((columns) => [
            ...columns,
            {
                id: "Edit",
                Header: '',
                Cell: ({ row }) => (
                    <React.Fragment>
                        <div className="dropdown">
                            <button className="btn btn-secondary btn_action_menu" type="button" data-toggle="dropdown" aria-expanded="false">
                                <b><ActionButtonJsx /></b>
                            </button>
                            <div className="dropdown-menu">
                                <Link to={`/${CustRoute.edit}/${row?.values?.id}`} className="dropdown-item" style={{ color: "black" }}>{actionButtonObject.edit}</Link>
                                <a role={"button"} className="dropdown-item" onClick={() => onRemoveCustomer(row?.values?.id)}>{actionButtonObject.delete}</a>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }

        ])
    }


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        canPreviousPage,
        page,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        setGlobalFilter,
        state: { pageIndex, pageSize, globalFilter },
    } = useTable({ columns, data: custData, initialState: { hiddenColumns: ["id"], pageIndex: 0, pageSize: 100 } }, tableHooks, useGlobalFilter, useSortBy, usePagination,
    // useRowSelect, // Add useRowSelect hook
    // hooks => {
    //   hooks.visibleColumns.push(columns => [
    //     // Add a checkbox column
    //     {
    //       id: 'selection',
    //       Header: ({ getToggleAllRowsSelectedProps }) => (
    //         <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
    //       ),
    //       Cell: ({ row }) => (
    //         <div>
    //           <input
    //             type="checkbox"
    //             {...row.getToggleRowSelectedProps()}
    //           />
    //         </div>
    //       ),
    //     },
    //     ...columns,
    //   ]);
    // }
    );





    const removeCustomer = async (id) => {

        if (!id) { toast.error("Unable to delete"); return; }

        try {
            setLoading(true);

            const res = await deleteCustomer(id, getToken());
            if (!res.data.error) {
                setLoading(false);

               

                setCustData((prevState)=>{
                    return prevState.filter((c,i)=>{
                        return c?.id!=id
                    })
                })
                toast.success(res.data?.message);

            } else {
                setLoading(false);

                toast.error(res.data?.message ? res.data?.message : "Unable to delete");
            }
        } catch (err) {
            setLoading(false);

            console.log(err);
            toast.error(err?.response?.data?.message ? err?.response?.data?.message : "Something went Wrong");
        }
    }

    const onRemoveCustomer = (id) => {
        SwalAlert({}).then(async (result) => {
            if (result.isConfirmed) {
                await removeCustomer(id);
            }
        }).catch((err) => {
            console.log(err);
            toast.error("Something went wrong");
        })
    }

    return (
        <>

            <div className="content__wrapper">
                <section className="page-content">
                    <div className="page-title mobile-title">
                        <h1 className="h4 mb-0">All Customer</h1>
                        {/*<p className="mb-4">Customers</p>*/}
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="zed__table card">
                                <h5 className="text-shadow-yellow px-2 mb-4">All Customers</h5>
                                <div className="row">
                                    <div className="col-lg-3 col-12">
                                        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                                    </div>
                                </div>
                                <div className="table-responsive">
                                {loading ? <MyLoader value={false}/> : 
                                    <table className='table datatable' {...getTableProps()}>
                                        <thead>
                                            {headerGroups.map(headerGroup => (
                                                <tr {...headerGroup.getHeaderGroupProps()}>
                                                    {headerGroup.headers.map(column => (
                                                        <th
                                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                                        >
                                                            {column.render('Header')}
                                                            <span>
                                                                {column.isSorted
                                                                    ? column.isSortedDesc
                                                                        ? ' 🔽'
                                                                        : ' 🔼'
                                                                    : ''}
                                                            </span>
                                                        </th>
                                                    ))}
                                                </tr>
                                            ))}
                                        </thead>
                                        <tbody {...getTableBodyProps()}>
                                            {/* {rows.map(row => { */}
                                            {page.map(row => {
                                                prepareRow(row)
                                                return (
                                                    <tr {...row.getRowProps()}>
                                                        {row.cells.map(cell => {
                                                            return (
                                                                <td
                                                                    {...cell.getCellProps()}

                                                                >
                                                                    {cell.render('Cell')}
                                                                </td>
                                                            )
                                                        })}
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                        <tfoot>
                                            <td colSpan={3}></td>
                                            <td colSpan={2}>
                                            <div className="pagination justify-content-end align-items-center">
                                        <button className='pagination_button' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                            {'<<'}
                                        </button>
                                        <button className='pagination_button' onClick={() => previousPage()} disabled={!canPreviousPage}>
                                            {'<'}
                                        </button>
                                        <span>
                                            Page{' '}
                                            <strong>
                                                {pageIndex+1} of {pageOptions.length}
                                            </strong>
                                        </span>
                                        <button className='pagination_button' onClick={() => nextPage()} disabled={!canNextPage}>
                                            {'>'}
                                        </button>
                                        <button className='pagination_button' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                            {'>>'}
                                        </button>
                                        

                                    </div>
                                            </td>
                                        </tfoot>
                                    </table>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default List;

const GlobalFilter = ({ filter, setFilter }) => {
    return (
        <span>
            <input
                className="form-control"
                type={"search"}
                style={{ margin: "5px" }}
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                placeholder="Search"
            />
        </span>
    )
}