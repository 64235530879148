import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GetDate, todayDate } from '../../../services/DateAndTimeService';
import { getFileExpenseById, updateFileExpense } from '../../../services/FileExpenseService';
import { getToken } from '../../../services/useLocalStorage';
import useValidator from '../../../services/Validation';
import { GetFile } from '../../../services/FileService';
import { getOfficeExpenseById, updateOfficeExpense } from '../../../services/OfficeExpenseService';
import { displayLoader } from '../../../UtilsComponent/DisplayLoader';
import toast from 'react-hot-toast';
import ApiService from '../../../services/ApiService';
import { checkOfficeExpense, fetchOfficeExpenseById, updateOfficeExpenseById } from '../../../config/config';
import { login, OfficeExpenseRoute } from '../../../config/RouteConfig';
import { FileUrl } from '../../../config/file_url';
import { useForm } from "react-hook-form"
import { displayError, formclass } from '../../../services/ValidationService';


const PreviewOfficeExpense = () => {

    const navigate = useNavigate();
    const params = useParams();

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        mode: "onChange"
    });

    const [loading, setLoading] = useState(false);
    const [expense, setExpense] = useState({});


    const goBack = () => {
        navigate(`/${OfficeExpenseRoute.list}`);
    }

    const fetchOfficeExpense = async () => {
        try {
            setLoading(true);
            const header = { Authorization: `Bearer ${getToken()}`, ['Content-Type']: 'application/json' }
            const res = await ApiService(null, parseInt(params?.id), header, fetchOfficeExpenseById.method, fetchOfficeExpenseById.url);
            if (!res.data.error) {
                setLoading(false);
                const expense_data = res.data.data[0];
                setExpense({
                    team_fullname: expense_data?.team_fullname ? expense_data?.team_fullname : "",
                    amount: expense_data?.amount ? expense_data?.amount : "",
                    description: expense_data?.description,
                    date: expense_data?.date ? GetDate(expense_data?.date, "YYYY-MM-DD") : undefined,
                    receipt_image: expense_data?.receipt_image ? expense_data?.receipt_image : "",
                    submitters_img1: expense_data?.submitters_img1 ? expense_data?.submitters_img1 : "",
                    submitters_img2: expense_data?.submitters_img2 ? expense_data?.submitters_img2 : "",
                    submitters_img3: expense_data?.submitters_img3 ? expense_data?.submitters_img3 : "",
                    is_approved: expense_data?.is_approved
                });

                reset({ is_approved: expense_data?.is_approved })

            } else {
                setLoading(false);
                toast.error(res.data.message);
                navigate(`/${login.list}`, { state: { from: location }, replace: true });
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
            if (err.response.data.error) {
                toast.error(err.response.data.message);
            }
        }
    }

    const onSubmit = async (data) => {
        console.log(data);

        try {
            setLoading(true);
            const header = { Authorization: `Bearer ${getToken()}`, ['Content-Type']: 'application/json' }
            const res = await ApiService({ ...data, id: parseInt(params?.id) }, 0, header, checkOfficeExpense.method, checkOfficeExpense.url);
            if (!res?.data?.error) {
                setLoading(false);
                toast.success(res?.data?.message);
                navigate(`/${OfficeExpenseRoute.list}`);
            }
            else {
                setLoading(false);
                toast.error(res?.data?.message);
                navigate(`/${OfficeExpenseRoute.list}`);
            }
        }
        catch (err) {
            setLoading(false);
            toast.error(err?.response?.data?.message ? err?.response?.data?.message : "Something went wrong");
            console.log(err);

        }

    }

    useEffect(() => {
        fetchOfficeExpense();
    }, []);

    return (
        <div className="content__wrapper">

            <section className="page-content">
                <div className="page-title mobile-title">
                    <h1 className="h4 mb-0">Preview</h1>
                    <p className="mb-4">Office Expense</p>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card p-5">
                            <div className="card-header bg-transparent border-0 mb-5 p-0">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-md-6">
                                        <h6 className="title-line text-shadow-yellow mb-0 pb-3">Preview Office Expense</h6>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body p-0">
                                {!loading ?

                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <label htmlFor="description">Description</label>
                                                    <textarea name="description" className='form-control' value={expense?.description} disabled={true}></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <label htmlFor="team_fullname">Team name</label>
                                                    <input type="text" name="team_fullname" className='form-control' id="team_fullname" value={expense?.team_fullname} placeholder='Team name' disabled={true} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <label htmlFor="amount">Amount</label>
                                                    <input type="text" name="amount" className='form-control' id="amount" value={expense?.amount} placeholder='Amount' disabled={true} />
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="form-group">
                                                    <label htmlFor="filename">Date</label>
                                                    {expense?.date ?
                                                        <input type="date" name="date" className='form-control' id="date" value={expense?.date} disabled={true} />
                                                        : "-"}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div>
                                                    <b>Receipt Image</b>:
                                                    {expense?.receipt_image ?
                                                        <a href={`${FileUrl}/${expense?.receipt_image}`} className='text-success display-5' target="_blank"><i class="fa fa-download" aria-hidden="true"></i></a>
                                                        : "-"}
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div>
                                                    <b>Image 1</b>:
                                                    {expense?.submitters_img1 ?
                                                        <a href={`${FileUrl}/${expense?.submitters_img1}`} className='text-success display-5' target="_blank"><i class="fa fa-download" aria-hidden="true"></i></a>
                                                        : ""}
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div>
                                                    <b>Image 2</b>:
                                                    {expense?.submitters_img2 ?
                                                        <a href={`${FileUrl}/${expense?.submitters_img2}`} className='text-success display-5' target="_blank"><i class="fa fa-download" aria-hidden="true"></i></a>
                                                        : ""}
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div>
                                                    <b>Image 3</b>:
                                                    {expense?.submitters_img3 ?
                                                        <a href={`${FileUrl}/${expense?.submitters_img3}`} className='text-success display-5' target="_blank"><i class="fa fa-download" aria-hidden="true"></i></a>
                                                        : ""}
                                                </div>
                                            </div>
                                        </div>

                                        <hr />

                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <label htmlFor="is_approved">Is approved</label>
                                                    <select name="is_approved" id="is_approved" className={formclass(errors?.is_approved)} {...register("is_approved", { required: "Required" })}>
                                                        <option value={""}>--Select Status--</option>
                                                        <option value={"0"}>Pending</option>
                                                        <option value={"1"}>Approved</option>
                                                        <option value={"2"}>Rejected</option>
                                                    </select>
                                                    {displayError(errors?.is_approved?.message)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="form-group mb-0 col-md-2">
                                                <button type="submit" className="btn">Submit</button>
                                            </div>
                                            <div className="form-group mb-0 col-md-4">
                                                <button type="button" onClick={goBack} className="btn">Back</button>
                                            </div>       
                                        </div>

                                    </form>
                                    :
                                    displayLoader()
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default PreviewOfficeExpense