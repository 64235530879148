import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  memo,
  useRef,
} from "react";
import { deletesites, getempsitebyId } from "../../../config/config";
import ApiService from "../../../services/ApiService";
import { GetDate } from "../../../services/DateAndTimeService";
import { getToken } from "../../../services/useLocalStorage";
import { FileUrl,eyeButtonSvg } from "../../../config/file_url";
import { image2svg } from "../../../UtilsComponent/UtilFunctions";
import { Link } from "react-router-dom";
import { SiteRoute } from "../../../config/RouteConfig";
import { SwalAlert } from "../../../UtilsComponent/SwalDialog";
import toast from "react-hot-toast";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { paginationRows } from "../../../config/CommonVariables";
import {
  exportToCsv,
  loadingTemplate,
  notFoundTemplate,
  ActionButtonJsx,
  actionButtonObject,
} from "../../../services/FormCommon";

import {
  useSortBy,
  useTable,
  usePagination,
  useGlobalFilter,
  useRowSelect,
} from "react-table";
import { MyLoader } from "../../../UtilsComponent/MyLoader";
import jwt_decode from "jwt-decode";
import { ReactSVG } from "react-svg";
//sites_Expense
const EmpList = () => {
  var header = {
    Authorization: `Bearer ${getToken()}`,
    ["Content-type"]: "application/json",
  };
  const [sites, setSites] = useState([]);
  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(false);

  const hname = {
    created_at: "Date",
    full_name: "Client",
    inspect: "Inspected By",
    site_incharge: "Site Incharge",
    type_name: "Visit Category",
    title: "Visit Work",
    client_sign: "Client Sign",
    action: "action",
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "id",
        accessor: "id",
      },
      {
        Header: "Date",
        accessor: `${hname.created_at}`,
      },
      {
        Header: "Client",
        accessor: `${hname.full_name}`,
      },
      {
        Header: "Inspected By",
        accessor: `${hname.inspect}`,
      },
      {
        Header: "Site Incharge",
        accessor: `${hname.site_incharge}`,
      },
      {
        Header: "Visit Category",
        accessor: `${hname.type_name}`,
      },
      {
        Header: "Visit Work",
        accessor: `${hname.title}`,
      },
      {
        Header: "Client Sign",
        accessor: `${hname.client_sign}`,
        Cell: ({ row }) => (
          <div style={{ position: "relative" }}>
            {console.log(
              "Image source:",
              `${FileUrl}/${row.values[hname.client_sign]}`
            )}
            {row.values[hname.client_sign] ? (
              <React.Fragment>
                <a
                  href={`${FileUrl}/${row.values[hname.client_sign]}`}
                  style={{
                    position: "absolute",
                    right: "-8px",
                    top: "50%",
                  }}
                  target="_blank"
                >
                  <ReactSVG
                    src={`${eyeButtonSvg}`}
                    wrapper="span"
                    beforeInjection={(svg) => {
                      // svg.classList.add('svg-class-name')
                      svg.setAttribute("style", "width: 20px");
                    }}
                  />
                </a>
              </React.Fragment>
            ) : (
              "No Image"
            )}
          </div>
        ),
      },
    ],
    []
  );

  

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    page,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data: sites,
      initialState: { hiddenColumns: ["id"], pageIndex: 0, pageSize: 10 },
    },

    useGlobalFilter,
    useSortBy,
    usePagination
    // useRowSelect, // Add useRowSelect hook
    // hooks => {
    //   hooks.visibleColumns.push(columns => [
    //     // Add a checkbox column
    //     {
    //       id: 'selection',
    //       Header: ({ getToggleAllRowsSelectedProps }) => (
    //         <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
    //       ),
    //       Cell: ({ row }) => (
    //         <div>
    //           <input
    //             type="checkbox"
    //             {...row.getToggleRowSelectedProps()}
    //           />
    //         </div>
    //       ),
    //     },
    //     ...columns,
    //   ]);
    // }
  );



  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await ApiService(
        null,
        0,
        header,
        getempsitebyId.method,
        getempsitebyId.url
      );
      console.log("res", res);
      if (!res?.data?.error) {
        setLoading(false);

        const payload = res?.data?.data;
        // setSites(payload);
        if (Array.isArray(payload) && payload.length > 0) {
         

          const rows = [];

          payload.forEach((element) => {
            rows.push({
              id: element?.id,
              [hname.created_at]: element?.created_at
                ? GetDate(element?.created_at, "DD-MMM-YYYY")
                : "-",
              [hname.full_name]: element?.full_name,
              [hname.inspect]: element?.inspect,
              [hname.site_incharge]: element?.site_incharge,
              [hname.type_name]: element?.type_name,
              [hname.title]: element?.title,
              [hname.client_sign]: element?.client_sign,

              // [hname.action]:element?.id
            });
          });
          // Sort the rows in descending order based on the 'date' property
          const sortedRows = rows.sort(
            (a, b) => new Date(b[hname.created_at]) - new Date(a[hname.created_at])
          );

          setSites(sortedRows);

          // setSites(rows);
        }
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);

      console.log(err);
      toast.error("Something went wrong");
    }
  };

  const removeRow = (id) => {
    try {
      setSites((prevState) => {
        return prevState.filter((d) => {
          return parseInt(d?.id) != parseInt(id);
        });
      });
    } catch (err) {
      console.log(err);
    }
  };

  const removesites = async (id) => {
    try {
      setLoading(true);

      const res = await ApiService(
        null,
        id,
        header,
        deletesites.method,
        deletesites.url
      );
      if (!res?.data?.error) {
        setLoading(false);

        removeRow(id);
        toast.success(res?.data?.message);
      } else {
        setLoading(false);

        toast.error(res?.data?.message);
      }
    } catch (err) {
      setLoading(false);

      console.log(err);
      toast.error(
        err?.response?.data?.message
          ? err?.response?.data?.message
          : "Something went wrong"
      );
    }
  };

  const onRemovesites = (id) => {
    SwalAlert({})
      .then(async (result) => {
        if (result?.isConfirmed) {
          await removesites(id);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Unable to remove");
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="content__wrapper">
      <section className="page-content">
        <div className="page-title mobile-title">
          <h1 className="h4 mb-0">Site Inspection</h1>
          {/*<p className="mb-4">Online Process</p>*/}
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="zed__table card">
              <h5 className="text-shadow-yellow px-2 mb-4">
                All Site Inspection
              </h5>
              <div className="row">
                <div className="col-lg-3 col-12">
                  <GlobalFilter
                    filter={globalFilter}
                    setFilter={setGlobalFilter}
                  />
                </div>
              </div>
              <div className="table-responsive">
                {loading ? (
                  <MyLoader value={false} />
                ) : (
                  <table className="table datatable" {...getTableProps()}>
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render("Header")}
                              <span>
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? " 🔽"
                                    : " 🔼"
                                  : ""}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {/* {rows.map(row => { */}
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <td colSpan={5}>
                        <div className="pagination justify-content-end align-items-center">
                          <button
                            className="pagination_button"
                            onClick={() => gotoPage(0)}
                            disabled={!canPreviousPage}
                          >
                            {"<<"}
                          </button>
                          <button
                            className="pagination_button"
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                          >
                            {"<"}
                          </button>
                          <span>
                            Page{" "}
                            <strong>
                              {pageIndex + 1} of {pageOptions.length}
                            </strong>
                          </span>
                          <button
                            className="pagination_button"
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                          >
                            {">"}
                          </button>
                          <button
                            className="pagination_button"
                            onClick={() => gotoPage(pageCount - 1)}
                            disabled={!canNextPage}
                          >
                            {">>"}
                          </button>
                        </div>
                      </td>
                    </tfoot>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EmpList;

const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <span>
      <input
        className="form-control"
        type={"search"}
        style={{ margin: "5px" }}
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        placeholder="Search"
      />
    </span>
  );
};
