import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getEmployeeById, updateEmployee } from '../../../services/EmployeeService';
import { getToken } from '../../../services/useLocalStorage';
import useValidator from '../../../services/Validation';
import Loader from 'react-js-loader';
import toast from 'react-hot-toast';
import { EmpRoute } from '../../../config/RouteConfig';
import ApiService from '../../../services/ApiService';
import { getDepartments, getPosts, updateEmployeeById } from '../../../config/config';
import { useForm } from 'react-hook-form';
import { formclass, displayError, checkFile } from '../../../services/ValidationService';
import { alphabetSpaceWithDot, mailPattern, phonePattern } from '../../../services/Pattern';
import Multiselect from 'multiselect-react-dropdown';
import { ReactComponent as Image } from '../../../assets/svg/image.svg';
import file_url, { FileUrl } from '../../../config/file_url';
import { previewImage } from '../../../services/FormCommon';
//Employee
const EditProfile = () => {

    var header = { Authorization: `Bearer ${getToken()}`, ['Content-type']: 'application/json' };

    const navigate = useNavigate();
    const params = useParams();
    const goBack = () => {
        navigate(`/dashboard`);
    }

    const [values, setValues] = useState({
        email: '', first_name: '', last_name: '', phone: ''
    });

    const { register, formState: { errors }, setError, setValue, getValues, handleSubmit, reset, clearErrors } = useForm({
        mode: "onChange"
    });

    const [validator, showValidationMessage] = useValidator()
    const [loading, setLoading] = useState(false);
    const [saveOldFile, setSaveOldFile] = useState("");
    const [department, setDepartment] = useState([]);
    const [departValues, setDepartValues] = useState([]);
    const [post, setPost] = useState([]);
    const [isPreviewed, setIsPreviewed] = useState(false);


    const fetchEmployeeById = async (id) => {
        try {
            setLoading(true);
            const res = await getEmployeeById(id, getToken());
            if (!res.data.error) {
                const emp = res.data.data[0];
                const depart_values = res.data?.departments;
                var departments = [];
                var departId = [];
                if (depart_values && Array.isArray(depart_values)) {
                    for (let d of depart_values) {
                        departments.push({ id: d?.department_id, name: d?.name });
                        departId.push(d?.department_id);
                    }
                }

                setDepartValues(departments);

                reset({
                    id: emp.id,
                    first_name: emp?.first_name,
                    last_name: emp?.last_name,
                    phone: emp?.phone,
                    email: emp?.email,
                    departments: departId,
                    active: emp?.active,
                });

                if (emp?.profile_picture) {
                    setSaveOldFile(`${FileUrl}/${emp?.profile_picture}`);
                    setIsPreviewed(true);
                }

                setLoading(false);
            } else {
                setLoading(false);
                toast.error(res.data.message);
                navigate(`/dashboard`);
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
            toast.error(err.response.data.message);
            navigate(`/dashboard`);
        }
    }

    const fetchDepartment = async () => {
        try {
            setLoading(true);
            const res = await ApiService(null, 0, header, getDepartments.method, getDepartments.url);
            if (!res.data.error) {
                setLoading(false);
                if (res.data?.data) {
                    const payload = res.data?.data;
                    const values = [];
                    for (let p of payload) {
                        values.push({ id: p.id, name: p.name })
                    }
                    setDepartment(values);
                    fetchEmployeeById(params.id);
                }
            } else {
                setLoading(false);
                toast.error(res.data?.error);
            }
        }
        catch (err) {
            setLoading(false);
            toast.error(err.response?.data?.message);
        }
    }

    const fetchLisoner = async () => {
        try {
            const res = await ApiService(null, 0, header, getPosts.method, getPosts.url);
            if (!res.data.error) {
                if (res.data?.data) {
                    setPost(res.data?.data);
                }
            } else {
                toast.error(res.data?.error);
            }
        }
        catch (err) {
            toast.error(err.response?.data?.message);
        }
    }

    useEffect(() => {

        fetchLisoner();
        fetchDepartment();
    }, []);

    // useEffect(()=>{
    //     if(saveOldFile){
    //         displayImage(saveOldFile);
    //     }
    // },[saveOldFile]);

    const onSubmit = async (data) => {

        console.log(data);

        const formData = new FormData();

        for (let key of Object.keys(data)) 
        {
            if (key === "file") 
            {
                if(data?.file[0])
                {
                    formData.append("profile_picture", data?.file[0]);
                }
            }
            else if (key == "departments") 
            {
                formData.append(key, JSON.stringify(data[key]));
            }
            else 
            {
                formData.append(key, data[key]);
            }
        }

        try 
        {
            setLoading(true);
            var header = { Authorization: `Bearer ${getToken()}`, ['Content-Type']: 'multipart/form-data' };
            const res = await ApiService(formData, 0, header, updateEmployeeById.method, updateEmployeeById.url);
            if (!res.data?.error) 
            {
                toast.success(res.data?.message);
                setLoading(false);
                navigate(`/dashboard`);
            }
            else 
            {
                setLoading(false);
                toast.error(res.data?.message);
            }
        } 
        catch (err) 
        {
            setLoading(false);
            console.log(err);
            toast.error(err?.response?.data?.message);
        }

    }

    const onSelect = (list, item) => {
        console.log("list", list);
        console.log("item", item);

        const updated = [];

        if (list.length > 0) {
            clearErrors("departments");
        }

        list.forEach((v, i) => {
            updated.push(v.id);
        });

        setValue("departments", updated);
    }

    const onRemove = (list, item) => {
        console.log("list", list);
        console.log("item", item);
        const updated = [];

        if (!list.length > 0) {
            console.log("Called.....");
            setError("departments", { type: "custom", message: "Required" });
        } else {
            clearErrors("departments");
        }

        list.forEach((v, i) => {
            updated.push(v.id);
        });

        setValue("departments", updated);
    }

    /*Displaying old image*/
    const resetOldImage = (imageName) => {
        try {
            if (imageName) {
                document.getElementById("previewImage").src = `${imageName}`;
            }
        } catch (err) {
            console.log(err);
        }
    }

    return (

        <>
            <div className="content__wrapper">
                <section className="page-content">
                    <div className="page-title mobile-title">
                        <h1 className="h4 mb-0">Edit</h1>
                        <p className="mb-4">Profile Details</p>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card p-5">
                                <div className="card-header bg-transparent border-0 mb-5 p-0">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-md-6">
                                            <h6 className="title-line text-shadow-yellow mb-0 pb-3">Edit Profile </h6>
                                        </div>
                                      
                                    </div>
                                </div>

                                <div className="card-body p-0">
                                    {loading ? <Loader type="spinner-default" bgColor={"#000000"} title={"Please wait"} color={'#000000'} size={50} />
                                        :
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="row">
                                                <div className="form-group col-md-6">
                                                    <label for="">First Name*</label>
                                                    <input
                                                        type="text"
                                                        name="first_name"
                                                        id="first_name"
                                                        {...register("first_name", {
                                                            required: "Required",
                                                            pattern: {
                                                                value: alphabetSpaceWithDot,
                                                                message: "Invalid"
                                                            }
                                                        })}
                                                        className={formclass(errors?.first_name)}
                                                        placeholder='First name'
                                                    />
                                                    {displayError(errors?.first_name?.message)}
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label for="">Last Name*</label>
                                                    <input
                                                        type="text"
                                                        name="last_name"
                                                        id="last_name"
                                                        placeholder='Last name'
                                                        {...register("last_name", {
                                                            required: "Required",
                                                            pattern: {
                                                                value: alphabetSpaceWithDot,
                                                                message: "Invalid"
                                                            }
                                                        })}
                                                        className={formclass(errors?.last_name)}
                                                    />
                                                    {displayError(errors?.last_name?.message)}
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label for="">Personal Email Address*</label>
                                                    <input
                                                        type="text"
                                                        name="email"
                                                        id="email"
                                                        placeholder='email'
                                                        {...register("email", {
                                                            required: "Required",
                                                            pattern: {
                                                                value: mailPattern,
                                                                message: "Invalid email"
                                                            }
                                                        })}
                                                        className={formclass(errors?.email)}
                                                    />
                                                    {displayError(errors?.email?.message)}
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label for="">Personal Mobile Number*</label>
                                                    <input
                                                        type="text"
                                                        name="phone"
                                                        id="phone"
                                                        placeholder='Mobile no.'
                                                        {...register("phone", {
                                                            required: "Required",
                                                            pattern: {
                                                                value: phonePattern,
                                                                message: "Only 10 Digits phone no. is required"
                                                            }
                                                        })}
                                                        className={formclass(errors?.phone)}
                                                    />
                                                    {displayError(errors?.phone?.message)}
                                                </div>


                                                <div className="form-group col-md-6">
                                                    <label htmlFor="password">Update Password*</label>
                                                    <input
                                                        type="password"
                                                        name="password"
                                                        id="password"
                                                        placeholder='Password'
                                                        {...register("password", {
                                                        })}
                                                        className={formclass(errors?.password)}
                                                    />
                                                    {displayError(errors?.password?.message)}
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="confirm_password">Confirm Update password*</label>
                                                    <input
                                                        type="password"
                                                        name="confirm_password"
                                                        id="confirm_password"
                                                        placeholder='Confirm password'
                                                        {...register("confirm_password", {
                                                            validate: value => value == getValues("password") || "Confirm password doesn't match with password match"
                                                        })}
                                                        className={formclass(errors?.confirm_password)}
                                                    />
                                                    {displayError(errors?.confirm_password?.message)}
                                                </div>

                                                <div className="form-group col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="departments">Department</label>
                                                        <Multiselect
                                                            options={department}
                                                            id="departments"
                                                            displayValue="name"
                                                            onSelect={onSelect}
                                                            selectedValues={departValues}
                                                            onRemove={onRemove}
                                                            className={formclass(errors?.departments)}
                                                            {...register("departments", {
                                                                required: "Required"
                                                            })}
                                                        />
                                                    </div>
                                                    {errors?.departments?.message}
                                                </div>

                                                <div className="form-group col-md-5">
                                                    <label for="">Profile Image*</label>
                                                    <div className="custom__file-upload">
                                                    <input 
                                                        type="file" 
                                                        id="file" 
                                                        name="file" 
                                                        className="form-control"
                                                        accept='image/*' 
                                                        {...register("file",{
                                                            validate:(value)=>{
                                                                // checkFile(value[0].name) || "Invalid File"
                                                                if(typeof value[0]?.name!="undefined" && checkFile(value[0]?.name))
                                                                {
                                                                    const isOkay = previewImage(value[0],"#previewImage")
                                                                    setIsPreviewed(isOkay);
                                                                    return isOkay;
                                                                }
                                                                else
                                                                {
                                                                    if((typeof value[0]?.name=="undefined") || value[0]?.name=="")
                                                                    {
                                                                        if(saveOldFile)
                                                                        {
                                                                            resetOldImage(saveOldFile);
                                                                            setIsPreviewed(true);
                                                                        }
                                                                        else
                                                                        {
                                                                            setIsPreviewed(false);
                                                                        }
                                                                        return true;
                                                                    }
                                                                    else
                                                                    {
                                                                        setIsPreviewed(!removeImage("#previewImage"));
                                                                        return "Invalid file"
                                                                    }
                                                                }
                                                            }
                                                        })}
                                                    // onChange={(e)=>previewImage(e,"#previewImage")}
                                                    />
                                                       
                                                        <label for="file" className="custom__file-label">
                                                            <Image />
                                                            <span>upload your image here</span>
                                                        </label>
                                                    </div>
                                                </div>


                                                <div className="form-group col-md-1 mt-5">
                                                    <img src={`${saveOldFile}`} id="previewImage" alt="image" width={"100%"} hidden={!isPreviewed} />
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="form-group mb-0 col-md-2">
                                                    <button type="submit" className="btn">Submit</button>
                                                </div>
                                                <div className="form-group mb-0 col-md-4">
                                                    <button type="submit" onClick={goBack} className="btn">Back</button>
                                                </div>
                                            </div>
                                        </form>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


            {/*<div className="content__wrapper">
        <div className="page__header pt-3 position-relative">
            <div className="container-fluid">
                <h1>Edit Employee</h1>
            </div>
            <div className="container-fluid">
               <button type='button' onClick={goBack} className='btn'>Back</button>
            </div>
        </div>

       
        <section className="py-4">
            <div class="container-fluid">
                <div class="card p-4">
                
                {JSON.stringify(getValues())} 

                {!loading ? 

                <form onSubmit={handleSubmit(onSubmit)}>
                       
                        <div className="row">
                            <div className="col">
                                <label htmlFor="first_name">First name</label>
                                <input 
                                    type="text" 
                                    className={formclass(errors?.first_name)} 
                                    name="first_name" 
                                    id="first_name" 
                                    placeholder='First name'
                                    {...register("first_name",{
                                        required:"Required"
                                    })}
                                />
                                {displayError(errors?.first_name?.message)}
                            </div>
                            <div className="col">
                                <label htmlFor="last_name">Last name</label>
                                <input 
                                    type="text" 
                                    className={formclass(errors?.last_name)} 
                                    {...register("last_name",{
                                        required:"Required"
                                    })}  
                                    name="last_name" 
                                    id="last_name" 
                                    placeholder='Last name'/>
                                {displayError(errors?.last_name?.message)}
                            </div>
                            
                        </div>

                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label htmlFor="departments">Department</label>
                              <Multiselect 
                                options={department}
                                id="departments"
                                displayValue ="name"
                                onSelect={onSelect}
                                selectedValues={departValues}
                                onRemove={onRemove}
                                className={formclass(errors?.departments)}
                                {...register("departments",{
                                  required:"Required"
                                })}
                              />
                            </div>
                            {/* {errors?.departments?.message} 
                          </div>
                          <div className="col">
                            {displayError(errors?.departments?.message)}
                          </div>
                        </div>

                        

                        {/* <div className="row">
                          
                            <div className="col">
                                <label htmlFor="departments">Department</label>
                                <select 
                                    multiple={true} 
                                    name="departments" 
                                    className={formclass(errors?.departments)} 
                                    id="departments"
                                    {...register("departments",{
                                        required:"Required"
                                    })}
                                    value={departValues}
                                   
                                >
                                    <option value="">--Select Department--</option>
                                    {department && department.map((value,index)=>(
                                        <option value={value.id}>{value.name}</option>
                                        ))}
                                </select>
                                {displayError(errors?.departments?.message)}
                            </div>
                        </div>

                        

                        <div className="row">
                            <div className="col">
                                <label htmlFor="email">Email</label>
                                <input 
                                    type="text" 
                                    className={formclass(errors?.email)} 
                                    {...register("email",{
                                        required:"Required",
                                        pattern:{
                                            value:mailPattern,
                                            message:"Invalid email"
                                        }
                                    })}  
                                    name="email" 
                                    id="email" 
                                    placeholder='Email'
                                />
                                {displayError(errors?.email?.message)}
                            </div>
                            <div className="col">
                                 <label htmlFor="phone">Mobile no.</label>
                                <input 
                                    type="text" 
                                    className={formclass(errors?.phone)} 
                                    name="phone" 
                                    id="phone" 
                                    placeholder='Mobile no.'
                                    {...register("phone",{
                                        required:"Required",
                                        pattern:{
                                            value:phonePattern,
                                            message:"10 Digit phone no. is allowed"
                                        }
                                    })}  
                                />
                                {displayError(errors?.phone?.message)}
                            </div>
                        </div>

                       
                        
                        <div className="row mt-3">
                            <div className="col">
                                <button className='btn'>Submit</button>
                            </div>
                        </div>

                    </form>
                  :<Loader type="spinner-default" bgColor={"#000000"} title={"Please wait"} color={'#000000'} size={50}/>}
                </div>
            </div>
        </section>

    </div>*/}
        </>
    )
}

export default EditProfile;