import React, { useState, useEffect, useCallback } from "react";
import { getToken } from "../../../services/useLocalStorage";
import Loader from "react-js-loader";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { displayError, formclass } from "../../../services/ValidationService";
import { eyeButtonSvg, FileUrl } from "../../../config/file_url";
import ApiService from "../../../services/ApiService";
import {
  deletetantativePlan,
  addTentativePlan,
  tentativeplanbyid,
} from "../../../config/config";
import ArchitectureRoute from "../../../config/RouteConfig";
import { ReactSVG } from "react-svg";
import { useParams, useNavigate } from "react-router-dom";
import { SwalAlert } from "../../../UtilsComponent/SwalDialog";

//FileDetails For Project
const TentativePlan = () => {
  const params = useParams();
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    reset,
    trigger,
  } = useForm({
    mode: "onChange",
  });

  const goBack = () => {
    navigate(`/${ArchitectureRoute.list}`);
  };

  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState([{ label: "", image: null }]);
  const [tentativeid, setTentativeId] = useState(0);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newInputs = [...inputs];
    newInputs[index][name] = value;
    setInputs(newInputs);
  };

  const handleImageChange = (index, event) => {
    const file = event.target.files[0];
    const newInputs = [...inputs];
    newInputs[index].image = file;
    setInputs(newInputs);
  };

  const handleAddInput = () => {
    setInputs((prevInputs) => [...prevInputs, { label: "", image: null }]);
    console.log("Inputs after adding:", inputs);
  };

  // const handleRemoveInput = (index) => {
  //   const newInputs = [...inputs];
  //   newInputs.splice(index, 1);
  //   setInputs(newInputs);
  //   console.log("Inputs after removing:", inputs);
  // };
  const header = {
    Authorization: `Bearer ${getToken()}`,
    ["Content-Type"]: "application/json",
  };

  const onSubmit = useCallback(
    async (data) => {
      const formData = new FormData();

    
      // Append labels and images from inputs state to FormData
      inputs.forEach((input, index) => {
        formData.append(`labels[${index}]`, input.label); // Use array notation for multiple values
        if (input.image) {
          formData.append(`images[${index}]`, input.image); // Use array notation for multiple files
        }
      });

   
      formData.append("projectId", params.id);

      try {
        setLoading(true);
        const header = { Authorization: `Bearer ${getToken()}` };

        let res;
        // if (tentativeid == 0) {
        res = await ApiService(
          formData,
          0,
          header,
          addTentativePlan.method,
          addTentativePlan.url
        );
        // } else {
        //   res = await ApiService(
        //     formData, // Pass the selected data as the payload
        //   0, // Use the valid integer here
        //     header,
        //     updateTentativePlan.method, // Use the appropriate API endpoint for adding project details
        //     updateTentativePlan.url
        //   );
        // }

        if (!res.data.error) {
          setLoading(false);
          toast.success(res?.data?.message);
          if (data.is_save == 1) {
            // setSubmittable(1);
          }
        } else {
          toast.error(res?.data?.message ? res?.data?.message : "Server Error");
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        toast.error(
          err?.response?.data?.message
            ? err?.response?.data?.message
            : "Server Error"
        );
      }
    },
    [params?.id, inputs, tentativeid]
  );

  const ProjectData = async () => {
    try {
      setLoading(true);
      const header = { Authorization: `Bearer ${getToken()}` };
      const res = await ApiService(
        null,
        parseInt(params?.id),
        header,
        tentativeplanbyid.method,
        tentativeplanbyid.url
      );
      if (res?.data?.data && res.data.data.length > 0) {
        const FirstId = res.data.data[0]?.project_id;
        console.log("FirstId-->", FirstId);
        if (FirstId == params.id) {
          setTentativeId(FirstId);
        } else {
          setTentativeId(0);
        }
      }

      console.log("API 2   Response:", res);
      if (!res.data.error) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      if (err.response?.data.error) {
        toast.error(err.response.data.message);
      }
    }
  };

  // console.log("tensxgsv ==>", tentativeid);

  useEffect(() => {
    ProjectData();
  }, []);

  useEffect(() => {
    // Update inputs state when tentativeid changes
    if (tentativeid !== 0) {
      const fetchData = async () => {
        try {
          setLoading(true);
          const header = { Authorization: `Bearer ${getToken()}` };
          const res = await ApiService(
            null,
            parseInt(params?.id),
            header,
            tentativeplanbyid.method,
            tentativeplanbyid.url
          );
          if (!res.data.error) {
            const data = res.data.data;
            const newInputs = data.map((item) => ({
              label: item.label,
              image: item.image,
              id:item.id,
            }));
            setInputs(newInputs);
          } else {
            toast.error(
              res?.data?.message ? res?.data?.message : "Server Error"
            );
          }
        } catch (err) {
          if (err.response?.data.error) {
            toast.error(err.response.data.message);
          }
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [tentativeid]);

  const removeTantativeimage = async (id) => {
    try {
      const res = await ApiService(
        null,
        parseInt(id),
        header,
        deletetantativePlan.method,
        deletetantativePlan.url
      );
      if (!res?.data?.error) {
        window.location.reload();
        toast.success(res?.data?.message);
      } else {
        toast.error(res?.data?.message);
      }
    } catch (err) {
      console.log(err);
      toast.error(
        err?.response?.data?.message
          ? err?.response?.data?.message
          : "Something went wrong"
      );
    }
  };

  const handleRemoveInput = (id) => {
    SwalAlert({})
      .then(async (result) => {
        if (result.isConfirmed) {
          await removeTantativeimage(id);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      });
  };
  // console.log("inputs[index].id==>", inputs);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {inputs.map((input, index) => (
        <div key={index} className="row mb-3">
          
          <div className="col-md-3">
            <label htmlFor={`label${index}`}>Option {index + 1}</label>
            <input
              type="text"
              name="label"
              id={`label${index}`}
              value={input.label}
              onChange={(e) => handleInputChange(index, e)}
              className="form-control"
            />
          </div>
          <div className="col-md-6">
            <label htmlFor={`image${index}`}>Upload Image</label>
            <div className="input-group">
              <input
                type="file"
                name="image"
                id={`image${index}`}
                onChange={(e) => handleImageChange(index, e)}
                className="form-control w-50"
              />
              <a
                href={`${FileUrl}/${inputs[index].image}`}
                style={{
                  position: "absolute",
                  right: "-8px",
                  top: "22%",
                  marginRight: "-17px",
                }}
                target={"_blank"}
              >
                <ReactSVG
                  src={`${eyeButtonSvg}`}
                  wrapper="span"
                  beforeInjection={(svg) => {
                    // svg.classList.add('svg-class-name')
                    svg.setAttribute("style", "width: 20px");
                  }}
                />
              </a>
            </div>
          </div>
          <div className="col-md-3 mt-4">
            {/* <button
              type="button"
              className="btn btn-outline-danger "
              onClick={() => handleRemoveInput(inputs[index].id)}
            >
              Remove
            </button> */}
            <img
              src={`${FileUrl}/img/icons/delete_icon.svg`}
              className="in__svg pr-2 mt-3"
              alt="project"
              onClick={() => handleRemoveInput(inputs[index].id)} 
              />
          </div>
        </div>
      ))}
      <button
        type="button"
        className="btn btn-primary mr-3"
        onClick={handleAddInput}
      >
        Add
      </button>
      <button type="submit" className="btn btn-success ms-3">
        Submit
      </button>
    </form>
  );
};

export default TentativePlan;


// import React, { useState, useEffect, useCallback } from "react";
// import { getToken } from "../../../services/useLocalStorage";
// import Loader from "react-js-loader";
// import toast from "react-hot-toast";
// import { useForm } from "react-hook-form";
// import { displayError, formclass } from "../../../services/ValidationService";
// import { eyeButtonSvg, FileUrl } from "../../../config/file_url";
// import ApiService from "../../../services/ApiService";
// import {
//   deletetantativePlan,
//   addTentativePlan,
//   tentativeplanbyid,
// } from "../../../config/config";
// import ArchitectureRoute from "../../../config/RouteConfig";
// import { ReactSVG } from "react-svg";
// import { useParams, useNavigate } from "react-router-dom";
// import { SwalAlert } from "../../../UtilsComponent/SwalDialog";

// //FileDetails For Project
// const TentativePlan = () => {
//   const params = useParams();
//   const navigate = useNavigate();

//   const {
//     register,
//     formState: { errors },
//     handleSubmit,
//     getValues,
//     setValue,
//     reset,
//     trigger,
//   } = useForm({
//     mode: "onChange",
//   });

//   const goBack = () => {
//     navigate(`/${ArchitectureRoute.list}`);
//   };

//   const [loading, setLoading] = useState(false);
//   const [inputs, setInputs] = useState([{ label: "", image: null }]);
//   const [tentativeid, setTentativeId] = useState(0);

//   const handleInputChange = (index, event) => {
//     const { name, value } = event.target;
//     const newInputs = [...inputs];
//     newInputs[index][name] = value;
//     setInputs(newInputs);
//   };

//   const handleImageChange = (index, event) => {
//     const file = event.target.files[0];
//     const newInputs = [...inputs];
//     newInputs[index].image = file;
//     setInputs(newInputs);
//   };

//   const handleAddInput = () => {
//     setInputs((prevInputs) => [...prevInputs, { label: "", image: null }]);
//     console.log("Inputs after adding:", inputs);
//   };

//   // const handleRemoveInput = (index) => {
//   //   const newInputs = [...inputs];
//   //   newInputs.splice(index, 1);
//   //   setInputs(newInputs);
//   //   console.log("Inputs after removing:", inputs);
//   // };
//   const header = {
//     Authorization: `Bearer ${getToken()}`,
//     ["Content-Type"]: "application/json",
//   };

//   const onSubmit = useCallback(
//     async (data) => {
//       const formData = new FormData();

    
//       // Append labels and images from inputs state to FormData
//       inputs.forEach((input, index) => {
//         formData.append(`labels[${index}]`, input.label); // Use array notation for multiple values
//         if (input.image) {
//           console.log("Image for index", index, ":", input.image);
//           formData.append(`images[${index}]`, input.image); // Use array notation for multiple files
//         }
//       });

   
//       formData.append("projectId", params.id);

//       try {
//         setLoading(true);
//         const header = { Authorization: `Bearer ${getToken()}` };

//         let res;
//         // if (tentativeid == 0) {
//         res = await ApiService(
//           formData,
//           0,
//           header,
//           addTentativePlan.method,
//           addTentativePlan.url
//         );
//         // } else {
//         //   res = await ApiService(
//         //     formData, // Pass the selected data as the payload
//         //   0, // Use the valid integer here
//         //     header,
//         //     updateTentativePlan.method, // Use the appropriate API endpoint for adding project details
//         //     updateTentativePlan.url
//         //   );
//         // }

//         if (!res.data.error) {
//           setLoading(false);
//           toast.success(res?.data?.message);
//           if (data.is_save == 1) {
//             // setSubmittable(1);
//           }
//         } else {
//           toast.error(res?.data?.message ? res?.data?.message : "Server Error");
//           setLoading(false);
//         }
//       } catch (err) {
//         console.log(err);
//         toast.error(
//           err?.response?.data?.message
//             ? err?.response?.data?.message
//             : "Server Error"
//         );
//       }
//     },
//     [params?.id, inputs, tentativeid]
//   );

//   const ProjectData = async () => {
//     try {
//       setLoading(true);
//       const header = { Authorization: `Bearer ${getToken()}` };
//       const res = await ApiService(
//         null,
//         parseInt(params?.id),
//         header,
//         tentativeplanbyid.method,
//         tentativeplanbyid.url
//       );
//       if (res?.data?.data && res.data.data.length > 0) {
      
//         const FirstId = res.data.data[0]?.project_id;
//         console.log("FirstId-->", FirstId);
//         if (FirstId == params.id) {
//           setTentativeId(FirstId);
//         } else {
//           setTentativeId(0);
//         }
//       }

//       console.log("API 2   Response:", res);
//       if (!res.data.error) {
//         setLoading(false);
//       } else {
//         setLoading(false);
//       }
//     } catch (err) {
//       setLoading(false);
//       if (err.response?.data.error) {
//         toast.error(err.response.data.message);
//       }
//     }
//   };

//   // console.log("tensxgsv ==>", tentativeid);

//   useEffect(() => {
//     ProjectData();
//   }, []);

//   useEffect(() => {
//     // Update inputs state when tentativeid changes
//     if (tentativeid !== 0) {
//       const fetchData = async () => {
//         try {
//           setLoading(true);
//           const header = { Authorization: `Bearer ${getToken()}` };
//           const res = await ApiService(
//             null,
//             parseInt(params?.id),
//             header,
//             tentativeplanbyid.method,
//             tentativeplanbyid.url
//           );
//           if (!res.data.error) {
//             const data = res.data.data[0];
//             console.log("ressss-->",res)
//             const parsedProjectData = JSON.parse(data.project_data);
//             console.log("parsedProjectData-->",parsedProjectData)
//             const newInputs = parsedProjectData.map((item) => ({
//               label: item.label,
//               image: item.image,
//               id:item.id, 
//             }));
//             setInputs(newInputs);
//           } else {
//             toast.error(
//               res?.data?.message ? res?.data?.message : "Server Error"
//             );
//           }
//         } catch (err) {
//           if (err.response?.data.error) {
//             toast.error(err.response.data.message);
//           }
//         } finally {
//           setLoading(false);
//         }
//       };
//       fetchData();
//     }
//   }, [tentativeid]);

//   const removeTantativeimage = async (id) => {
//     try {
//       const res = await ApiService(
//         null,
//         parseInt(id),
//         header,
//         deletetantativePlan.method,
//         deletetantativePlan.url
//       );
//       if (!res?.data?.error) {
//         // setTodos(id);
//         toast.success(res?.data?.message);
//       } else {
//         toast.error(res?.data?.message);
//       }
//     } catch (err) {
//       console.log(err);
//       toast.error(
//         err?.response?.data?.message
//           ? err?.response?.data?.message
//           : "Something went wrong"
//       );
//     }
//   };

//   const handleRemoveInput = (id) => {
//     SwalAlert({})
//       .then(async (result) => {
//         if (result.isConfirmed) {
//           await removeTantativeimage(id);
//         }
//       })
//       .catch((err) => {
//         console.log(err);
//         toast.error("Something went wrong");
//       });
//   };
//   console.log("inputs[index].id==>", inputs);
//   return (
//     <form onSubmit={handleSubmit(onSubmit)}>
//       {inputs.map((input, index) => (
//         <div key={index} className="row mb-3">
//           <div className="col-md-3">
//             <label htmlFor={`label${index}`}>Option {index + 1}</label>
//             <input
//               type="text"
//               name="label"
//               id={`label${index}`}
//               value={input.label}
//               onChange={(e) => handleInputChange(index, e)}
//               className="form-control"
//             />
//           </div>
//           <div className="col-md-6">
//             <label htmlFor={`image${index}`}>Upload Image</label>
//             <div className="input-group">
//               <input
//                 type="file"
//                 name="image"
//                 id={`image${index}`}
//                 onChange={(e) => handleImageChange(index, e)}
//                 className="form-control w-50"
//               />
//               <input type="text" id="name" name="namew" value={inputs[index].image}/>
//               <a
//                 href={`${FileUrl}/${inputs[index].image}`}
//                 style={{
//                   position: "absolute",
//                   right: "-8px",
//                   top: "22%",
//                   marginRight: "-17px",
//                 }}
//                 target={"_blank"}
//               >
//                 <ReactSVG
//                   src={`${eyeButtonSvg}`}
//                   wrapper="span"
//                   beforeInjection={(svg) => {
//                     // svg.classList.add('svg-class-name')
//                     svg.setAttribute("style", "width: 20px");
//                   }}
//                 />
//               </a>
//             </div>
//           </div>
//           <div className="col-md-3 mt-4">
//             <button
//               type="button"
//               className="btn btn-outline-danger "
//               onClick={() => handleRemoveInput(inputs[index].id)}
//             >
//               Remove
//             </button>
//           </div>
//         </div>
//       ))}
//       <button
//         type="button"
//         className="btn btn-primary"
//         onClick={handleAddInput}
//       >
//         Add
//       </button>
//       <button type="submit" className="btn btn-success ms-3">
//         Submit
//       </button>
//     </form>
//   );
// };

// export default TentativePlan;










