import React, { useState, useEffect, useCallback } from "react";
import { getToken } from "../../../services/useLocalStorage";
import toast from "react-hot-toast";
import ApiService from "../../../services/ApiService";
import { addbuildingdetail, buildingdetailbyid,deletebuildingdetail } from "../../../config/config";
import { useParams, useNavigate } from "react-router-dom";
import { FileUrl } from "../../../config/file_url";
import { SwalAlert } from "../../../UtilsComponent/SwalDialog";

//FileDetails For Project
const BuildingDetails = (selectedBuilding) => {
  const params = useParams();

  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState([]);
  const [tabid, setTabId] = useState(1);
  const tabs = [
    {
      title: "Architecture",
    },
    {
      title: "Structure",
    },
    {
      title: "MEPF",
    },
  ];
  const tabIDs = [1, 2, 3];
  const handleTabClick = async (index) => {
    setActiveTab(index);

    // Get the ID of the clicked tab
    const selectedTabID = tabIDs[index];

    // Check the title of the clicked tab and log the corresponding ID
    if (tabs[index].title === "Architecture") {
      console.log("ID:", selectedTabID);
    } else if (tabs[index].title === "Structure") {
      console.log("ID:", selectedTabID);
    } else if (tabs[index].title === "MEPF") {
      console.log("ID:", selectedTabID);
    }
    setTabId(selectedTabID);
    // await fetchData();
  };

  console.log("tabisi==>", tabid);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleUpload = async () => {
    if (!file) {
      console.log("No file selected");
      return;
    }

    // Perform file upload operation
    const formData = new FormData();
    formData.append("building_image", file);

    // Define IDs for each tab
    const tabIDs = [1, 2, 3];

    // Get the ID of the selected tab
    const selectedTabID = tabIDs[activeTab];
    formData.append("building_id", selectedBuilding.buildingId);
    formData.append("type", selectedTabID);
    formData.append("project_id", params.id);

    try {
      setLoading(true);
      const header = { Authorization: `Bearer ${getToken()}` };

      let res;
      res = await ApiService(
        formData,
        0,
        header,
        addbuildingdetail.method,
        addbuildingdetail.url
      );

      if (!res.data.error) {
        setLoading(false);
        toast.success(res?.data?.message);
      } else {
        toast.error(res?.data?.message ? res?.data?.message : "Server Error");
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      toast.error(
        err?.response?.data?.message
          ? err?.response?.data?.message
          : "Server Error"
      );
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const header = {
          Authorization: `Bearer ${getToken()}`,
          ["Content-Type"]: "application/json",
        };
        const res = await ApiService(
          null,
          parseInt(params?.id),
          header,
          buildingdetailbyid.method,
          buildingdetailbyid.url
        );

        if (!res.data.error) {
          setLoading(false);
          const data = res.data.data;
          console.log("data2-->", data);

          const filteredImages = data.filter(
            (item) =>
              item.building_id === selectedBuilding.buildingId &&
              item.type === tabid
          );

          setImageUrl(filteredImages);
        } else {
          setLoading(false);
          toast.error(res.data.message);
        }
      } catch (err) {
        setLoading(false);
        console.log(err);
        if (err.response.data.error) {
          toast.error(err.response.data.message);
        }
      }
    };

    fetchData();
  }, [tabid, selectedBuilding.buildingId]);

  const handleDeleteImage = (id) => {
    SwalAlert({})
      .then(async (result) => {
        if (result?.isConfirmed) {
          await removeimage(id);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Unable to remove");
      });
  };
  var header = {
    Authorization: `Bearer ${getToken()}`,
    ["Content-type"]: "application/json",
  };

  const removeimage = async (id) => {
    console.log("delete id ==>",id)
    try {
      setLoading(true);

      const res = await ApiService(
        null,
        id,
        header,
        deletebuildingdetail.method,
        deletebuildingdetail.url
      );
      if (!res?.data?.error) {
        setLoading(false);

        removeRow(id);
        toast.success(res?.data?.message);
      } else {
        setLoading(false);

        toast.error(res?.data?.message);
      }
    } catch (err) {
      setLoading(false);

      console.log(err);
      toast.error(
        err?.response?.data?.message
          ? err?.response?.data?.message
          : "Something went wrong"
      );
    }
  };

  const removeRow = (id) => {
    try {
      setImageUrl((prevState) => {
        return prevState.filter((d) => {
          return parseInt(d?.id) != parseInt(id);
        });
      });
    } catch (err) {
      console.log(err);
    }
  };

  console.log("image_url", imageUrl);
  console.log("Image Sources:");
  imageUrl.forEach((url, index) => {
    console.log(`${index}: ${FileUrl}/${url.image_url}`);
  });
  
  return (
    <div class="grey-border rounded">
      <div class="card-header bg-white">
        <h5 class="mb-0 font-weight-normal">{selectedBuilding.buildingName}</h5>
      </div>
      <div class="card-body bg-white rounded-bottom p-3 building_card">
        <div className="tab-list">
          {tabs.map((tab, index) => (
            <button
              key={index}
              className={index === activeTab ? "active" : ""}
              onClick={() => handleTabClick(index)}
            >
              {tab.title}
            </button>
          ))}
        </div>
        <div className="tab-content">
          <input
            type="file"
            onChange={handleFileChange}
            className="form-control mt-5"
          />
          <button onClick={handleUpload} className="btn btn-primary">
            Upload
          </button>
          {imageUrl.map((url, index) => (
            <div key={index}>
              <img src={`${FileUrl}/${url.image_url}`} alt={`Image ${index}`} className="building_detail_img" />
              <button
                onClick={() => handleDeleteImage(url.id)}
                className="btn btn-danger"
              >
                Delete
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BuildingDetails;
