import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GetDate, todayDate } from '../../../services/DateAndTimeService';
import { getFileExpenseById, updateFileExpense } from '../../../services/FileExpenseService';
import { getRole, getToken } from '../../../services/useLocalStorage';
import useValidator from '../../../services/Validation';
import { GetFile } from '../../../services/FileService';
import { getOfficeExpenseById, updateOfficeExpense } from '../../../services/OfficeExpenseService';
import Loader from 'react-js-loader';
import toast from 'react-hot-toast';
import ApiService from '../../../services/ApiService';
import { fetchOfficeExpenseById, updateOfficeExpenseById, updateOfficeExpenseByIdForAdmin } from '../../../config/config';
import { login, OfficeExpenseRoute } from '../../../config/RouteConfig';
import { FileUrl } from '../../../config/file_url';
import { adminKey } from '../../../config/RoleConfig';


//Office Expense
const Edit = () => {

    const navigate = useNavigate();
    const params = useParams();

    const initialExpValue = {
        amount: "",
        description: "",
        types: "expense",
        date: todayDate(),
        receipt_image: "",
        submitters_img1: "",
        submitters_img2: "",
        submitters_img3: ""
    }

    const [expense, setExpense] = useState(() => initialExpValue);
    const [validator, showValidationMessage] = useValidator();

    const initialImageUrls = {
        receipt_image: "",
        submitters_img1: "",
        submitters_img2: "",
        submitters_img3: "",
    }

    const [ImageUrls, setImageUrls] = useState(() => initialImageUrls);
    const [loading, setLoading] = useState(false);

    const fetchOfficeExpense = async () => {
        try {
            setLoading(true);
            const header = { Authorization: `Bearer ${getToken()}`, ['Content-Type']: 'application/json' }
            const res = await ApiService(null, parseInt(params?.id), header, fetchOfficeExpenseById.method, fetchOfficeExpenseById.url);
            if (!res.data.error) {
                setLoading(false);
                const expense_data = res.data.data[0];
                setExpense((prevState) => {
                    return {
                        ...prevState,
                        amount: expense_data?.amount ? expense_data?.amount : "",
                        description: expense_data?.description,
                        date: expense_data?.date ? GetDate(expense_data?.date, "YYYY-MM-DD") : todayDate()
                    }
                });

                setImageUrls({
                    receipt_image: expense_data?.receipt_image ? expense_data?.receipt_image : "",
                    submitters_img1: expense_data?.submitters_img1 ? expense_data?.submitters_img1 : "",
                    submitters_img2: expense_data?.submitters_img2 ? expense_data?.submitters_img2 : "",
                    submitters_img3: expense_data?.submitters_img3 ? expense_data?.submitters_img3 : "",
                });

            } else {
                setLoading(false);
                toast.error(res.data.message);
                navigate(`/${login.list}`, { state: { from: location }, replace: true });
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
            if (err.response.data.error) {
                toast.error(err.response.data.message);
            }
        }
    }

    useEffect(() => {
        fetchOfficeExpense();
    }, []);

    const goBack = () => {
        navigate(`/${OfficeExpenseRoute.list}`);
    }

    const handleInput = (e) => {

        const { name, value } = e.target;

        showValidationMessage(true);

        setExpense((prevState) => {
            return { ...prevState, [name]: value }
        });
    }

    const onFileInput = (e) => {
        const file = e.target.files[0];
        const name = e.target.name;
        setExpense((prevState) => {
            return { ...prevState, [name]: file }
        });
    }


    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();

        for (const key of Object.keys(expense)) {
            formData.append(key, expense[key]);
        }

        formData.append("id", parseInt(params.id));

        for (var pair of formData.entries()) {
            console.log(pair[0], pair[1]);
        }

        try {
            setLoading(true);
            const header = { Authorization: `Bearer ${getToken()}`, ['Content-Type']: 'multipart/form-data' }
            var updateOfficeExpenseObj = { ...updateOfficeExpenseById };

            if (getRole() == adminKey) {
                updateOfficeExpenseObj = { ...updateOfficeExpenseByIdForAdmin }
            }

            const res = await ApiService(formData, 0, header, updateOfficeExpenseObj.method, updateOfficeExpenseObj.url);

            if (!res.data.error) {
                toast.success(res?.data?.message);
                navigate(`/${OfficeExpenseRoute.list}`);
                setLoading(false);
            }
            else {
                toast.error(res?.data?.message);
                setLoading(false);
            }
        }
        catch (err) {
            setLoading(false);
            console.log(err)
            if (err.response.data.error) {
                toast.error(err.response.data.message);
            }
        }
    }

    return (
        <div className="content__wrapper">

            <section className="page-content">
                <div className="page-title mobile-title">
                    <h1 className="h4 mb-0">Edit</h1>
                    <p className="mb-4">Office Expense</p>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card p-5">
                            <div className="card-header bg-transparent border-0 mb-5 p-0">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-md-6">
                                        <h6 className="title-line text-shadow-yellow mb-0 pb-3">Edit Office Expense</h6>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body p-0">
                                {!loading ?

                                    <form onSubmit={handleSubmit} className="padding_space">
                                        <div className="row">
                                            <div className="col">
                                                <label htmlFor="description">Description</label>
                                                <textarea name="description" className='form-control' onChange={handleInput} value={expense.description}></textarea>
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <label htmlFor="amount">Amount*</label>
                                                <input type="text" name="amount" className='form-control' id="amount" onChange={handleInput} value={expense.amount} placeholder='Amount' />
                                                {validator.message("amount", expense.amount, "required|numeric", { className: 'text-danger' })}
                                            </div>
                                            <div className="col">
                                                <label htmlFor="filename">Date*</label>
                                                <input type="date" name="date" className='form-control' onChange={handleInput} id="date" value={expense.date} />
                                                {validator.message("date", expense.date, "required", { className: "text-danger" })}
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-md-9">
                                                <label htmlFor="receipt">Add Receipt*</label>
                                                <input type="file" name="receipt_image" className='form-control' onChange={onFileInput} id="receipt_image" />
                                                <span className='text-danger'></span>
                                            </div>
                                            <div className="col-md-3 mt-5">
                                                {ImageUrls?.receipt_image &&
                                                    <a href={`${FileUrl}/${ImageUrls?.receipt_image}`} className='text-success display-5' target="_blank"><i class="fa fa-download" aria-hidden="true"></i></a>
                                                }
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <label htmlFor="submitters_img1">Add Submitters Image 1</label>
                                                <input type='file' name="submitters_img1" id="submitters_img1" onChange={onFileInput} className='form-control'></input>
                                                <span className='text-danger'></span>
                                            </div>
                                            <div className="col mt-5">
                                                {ImageUrls?.submitters_img1 &&
                                                    <a href={`${FileUrl}/${ImageUrls?.submitters_img1}`} className='text-success display-5' target="_blank"><i class="fa fa-download" aria-hidden="true"></i></a>
                                                }
                                            </div>
                                            <div className="col">
                                                <label htmlFor="submitters_img2">Add Submitters Image 2</label>
                                                <input type='file' name="submitters_img2" id="submitters_img2" onChange={onFileInput} className='form-control'></input>
                                                <span className='text-danger'></span>
                                            </div>
                                            <div className="col mt-5">
                                                {ImageUrls?.submitters_img2 &&
                                                    <a href={`${FileUrl}/${ImageUrls?.submitters_img2}`} className='text-success display-5' target="_blank"><i class="fa fa-download" aria-hidden="true"></i></a>
                                                }
                                            </div>
                                            <div className="col">
                                                <label htmlFor="submitters_img3">Add Submitters Image 3</label>
                                                <input type='file' name="submitters_img3" id="submitters_img3" onChange={onFileInput} className='form-control'></input>
                                                <span className='text-danger'></span>
                                            </div>
                                            <div className="col mt-5">
                                                {ImageUrls?.submitters_img3 &&
                                                    <a href={`${FileUrl}/${ImageUrls?.submitters_img3}`} className='text-success display-5' target="_blank"><i class="fa fa-download" aria-hidden="true"></i></a>
                                                }
                                            </div>
                                        </div>

                                        <div className="row mt-3">
                                            <div className="form-group mb-0 col-md-2">
                                                <button type="submit" className="btn">Submit</button>
                                            </div>
                                            <div className="form-group mb-0 col-md-4">
                                                <button type="button" onClick={goBack} className="btn">Back</button>
                                            </div>       
                                        </div>

                                    </form>
                                    :
                                    <Loader type="spinner-default" bgColor={"#000000"} title={"Please wait"} color={'#000000'} size={50} />
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </section>

           
        </div>
    )

}

export default Edit;