import React, { useState, useEffect, useCallback } from "react";
import { getToken } from "../../../services/useLocalStorage";
import Loader from "react-js-loader";
import toast from "react-hot-toast";

import ApiService from "../../../services/ApiService";
import useValidator from "../../../services/Validation";
import {
  getProjectTypes,
  getDepartments,
  fetchHod,
  getCategory,
  updatemstproject,
  getmstProject,
} from "../../../config/config";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { getCustomers } from "../../../services/CustomerService";
import Select from "react-select";
import { architectureRoute } from "../../../config/RouteConfig";

//FileDetails For Project
const editProject = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [custData, setCustData] = useState([]);
  const [department, setDepartment] = useState([]);
  const [hod, setHoD] = useState([]);
  const [showInExpense, setShowInExpense] = useState("");
  const [showInTodo, setShowInTodo] = useState("");

  const [selecteddepartment, setSelecteddepartment] = useState([]);
  const [validator, showValidationMessage] = useValidator({
    rules: {
      remarks: "required|max:255", // Add any necessary validation rules here
    },
  });
  const [category, setCategory] = useState([]);

  const [project, setProject] = useState({
    client_id: "",
    category_id: "",
    project_name: "",
    project_type: "",
    land_detail: "",
    address: "",
    project_date: "",
    hod: "",
    departments: [],
    show_in_expense: "",
    show_in_todo: "",
  });

  const handleSelect = (selectedOptions) => {
    setSelecteddepartment(selectedOptions);
  };

  const handleInput = (e) => {
    const { name, value } = e.target;

    showValidationMessage(true);

    setProject((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const goBack = () => {
    navigate(`/${architectureRoute.list}`);
  };

  const handleSubmit = async (e, data) => {
    e.preventDefault();
    try {
      setLoading(true);
      const departmentIds = selecteddepartment.map((dept) => dept.value);
      const updatedProject = {
        ...project,
        departments: departmentIds,
        id: params.id,
      };

      const res = await fetch(updatemstproject.url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedProject),
      });

      if (res.ok) {
        const responseData = await res.json();

        if (!responseData.error) {
          setLoading(false);
          toast.success(responseData.message);
          navigate(`/${architectureRoute.list}`);
        } else {
          setLoading(false);
          toast.error(responseData.message);
        }
      } else {
        setLoading(false);
        const errorData = await res.json();
        toast.error(
          errorData?.message
            ? errorData.message
            : "Something went wrong with the request"
        );
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
      toast.error(
        err?.message ? err.message : "Something went wrong with the request"
      );
    }
  };

  const ProjectData = async () => {
    try {
      setLoading(true);
      const header = {
        Authorization: `Bearer ${getToken()}`,
        ["Content-Type"]: "application/json",
      };
      const res = await ApiService(
        null,
        parseInt(params?.id),
        header,
        getmstProject.method,
        getmstProject.url
      );
      if (!res.data.error) {
        setLoading(false);
        const projectdata = res.data.data[0];

        const parsedDate = new Date(projectdata.project_date)
          .toISOString()
          .split("T")[0];

        const departmentIds = projectdata.departments
          ? projectdata.departments
              .split(",")
              .map((dept) => parseInt(dept.trim()))
          : [];
          setSelecteddepartment(departmentIds);
        setProject({
          ...projectdata,
          project_date: parsedDate,
          // departments: departmentIds,
        });
      } else {
        setLoading(false);
        toast.error(res.data.message);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      if (err.response.data.error) {
        toast.error(err.response.data.message);
      }
    }
  };

  useEffect(() => {
    fetchCustomers();
    fetchDepartment();
    fetchCategory();
    fetchHOD();
    ProjectData();
  }, []);

  useEffect(() => {
    const fetchDataFromAPI = async () => {
      try {
        // Replace this with your API endpoint
        const response = await axios.get(getProjectTypes.url, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        });

        setData(response.data.data); // Use response.data directly
      } catch (error) {
        console.error("Error fetching data from API", error);
      }
    };

    fetchDataFromAPI();
  }, []);

  const fetchCustomers = async () => {
    try {
      const res = await getCustomers(getToken());
      if (!res.data.error) {
        const custData = res.data?.data;
        custData ? setCustData(custData) : "";
      } else {
        toast.error(res.data?.message);
      }
    } catch (err) {
      console.log(err);
      if (err.response.request.status === 501) {
        toast.error("Internal server error");
      } else if (err.response.data?.error) {
        toast.error(err.response.data?.message);
      }
    }
  };

  const fetchDepartment = async () => {
    try {
      // const res = await GetEmployees(`${getToken()}`);
      const header = { Authorization: `Bearer ${getToken()}` };
      const res = await ApiService(
        null,
        0,
        header,
        getDepartments.method,
        getDepartments.url
      );
      if (!res.data.error) {
        const payload = res.data?.data;

        if (Array.isArray(payload) && payload.length) {
          setDepartment(payload);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchCategory = async () => {
    try {
      // const res = await GetEmployees(`${getToken()}`);
      const header = { Authorization: `Bearer ${getToken()}` };
      const res = await ApiService(
        null,
        0,
        header,
        getCategory.method,
        getCategory.url
      );
      if (!res.data.error) {
        const payload = res.data?.data;

        if (Array.isArray(payload) && payload.length) {
          setCategory(payload);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchHOD = async () => {
    try {
      // const res = await GetEmployees(`${getToken()}`);
      const header = { Authorization: `Bearer ${getToken()}` };
      const res = await ApiService(
        null,
        0,
        header,
        fetchHod.method,
        fetchHod.url
      );
      if (!res.data.error) {
        const payload = res.data?.data;

        if (Array.isArray(payload) && payload.length) {
          setHoD(payload);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleExpenseChange = (e) => {
    setShowInExpense(e.target.value);
    setProject((prevState) => ({
      ...prevState,
      show_in_expense: e.target.value, // Update project state as well
    }));
  };

  const handleTodoChange = (e) => {
    setShowInTodo(e.target.value);
    setProject((prevState) => ({
      ...prevState,
      show_in_todo: e.target.value, // Update project state as well
    }));
  };

  useEffect(() => {
    if (project.show_in_expense) {
      setShowInExpense(project.show_in_expense);
    }
    if (project.show_in_todo) {
      setShowInTodo(project.show_in_todo);
    }
  }, [project.show_in_expense, project.show_in_todo]);

  const departmentData = department.map((departments) => ({
    label: `${departments.name}`,
    value: departments.id,
  }));

  return (
    <>
      <div class="content__wrapper">
        <section class="page-content">
          <div class="page-title mobile-title">
            <h1 class="h4 mb-0">Add</h1>
            <p class="mb-4">Project Details</p>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="card p-5">
                <div className="card-header bg-transparent border-0 mb-5 p-0">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-md-6">
                      <h6 className="title-line text-shadow-yellow mb-0 pb-3">
                        Add Project Details
                      </h6>
                    </div>
                    <div className="col-md-2 text-right status__selection-col">
                      <div className="status__selection">
                        <div className="status__selection-wrapper form-group">
                          <input
                            type="date"
                            className="form-control"
                            id="project_date"
                            name="project_date"
                            onChange={handleInput}
                            value={
                              project.project_date ? project.project_date : ""
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body p-0">
                  {!loading ? (
                    <form onSubmit={handleSubmit}>
                      <input
                        name="hiddenInput"
                        value="hiddenValue"
                        type="hidden"
                        id="0"
                      />
                      <fieldset id="fieldset">
                        <div className="row">
                          <div class="form-group col-md-6 col-sm-6">
                            <label htmlFor="client_id">Client Name*</label>
                            <select
                              className="form-control custom-select"
                              name="client_id"
                              onChange={handleInput}
                              value={project.client_id}
                            >
                              <option value="">--Select--</option>
                              {custData.map((row) => (
                                <option value={row.id}>
                                  {row.first_name} {row.last_name}
                                </option>
                              ))}
                            </select>
                            <span className="text-danger">
                              {validator.message(
                                "client_id",
                                project.client_id,
                                "required"
                              )}
                            </span>
                          </div>

                          <div class="form-group col-md-6 col-sm-6">
                            <label htmlFor="category_id">Category*</label>
                            <select
                              className="form-control custom-select"
                              name="category_id"
                              onChange={handleInput}
                              value={project.category_id}
                            >
                              <option value="">--Select--</option>
                              {category.map((row) => (
                                <option value={row.id}>{row.name}</option>
                              ))}
                            </select>
                            {validator.message(
                              "category_id",
                              project.category_id,
                              "required"
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6">
                            <label htmlFor="project_name">Project Name</label>
                            <input
                              type="text"
                              name="project_name"
                              id="project_name"
                              className="form-control"
                              placeholder="Enter Name."
                              onChange={handleInput}
                              value={project.project_name}
                            />
                            {validator.message(
                              "project_name",
                              project.project_name,
                              "required"
                            )}
                          </div>
                          <div class="form-group col-md-6 col-sm-6">
                            <label htmlFor="project_type">Project Type*</label>
                            <select
                              className="form-control custom-select"
                              name="project_type"
                              onChange={handleInput}
                              value={project.project_type}
                            >
                              <option value="">--Select--</option>
                              {data.map((row) => (
                                <option value={row.id}>{row.title}</option>
                              ))}
                            </select>
                            {validator.message(
                              "project_type",
                              project.project_type,
                              "required"
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6">
                            <label>Land Detail</label>
                            <textarea
                              className="form-control"
                              placeholder="Enter Land Detail"
                              name="land_detail"
                              id="land_detail"
                              rows={3}
                              onChange={handleInput}
                              value={project.land_detail}
                            />
                            {validator.message(
                              "land_detail",
                              project.land_detail,
                              "required"
                            )}
                          </div>

                          <div className="form-group col-md-6">
                            <label>Address</label>
                            <textarea
                              className="form-control"
                              placeholder="Enter Address"
                              name="address"
                              id="address"
                              rows={3}
                              onChange={handleInput}
                              value={project.address}
                            />
                            {validator.message(
                              "address",
                              project.address,
                              "required"
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6">
                            <div className="form-group">
                              <label htmlFor="departments">Department</label>

                              <Select
                                options={departmentData}
                                isMulti
                                placeholder="Select Team"
                                name="department"
                                id="department"
                                // value={selecteddepartment}
                                value={selecteddepartment.map((id) => ({
                                  value: id,
                                  label: department.find((d) => d.id === id)
                                    ?.name, 
                                }))}
                                onChange={handleSelect}
                              />
                              {/* {validator.message(
                                    "departments",
                                    project.departments,
                                    "required"
                                )} */}
                            </div>
                          </div>
                          <div className="form-group col-md-6">
                            <label htmlFor="hod">HOD</label>
                            <select
                              name="hod"
                              id="hod"
                              className="form-control"
                              onChange={handleInput}
                              value={project.hod}
                            >
                              <option value={""}>--Select HOD--</option>
                              {hod.map((hod) => (
                                <option value={hod.id}>
                                  {" "}
                                  {hod.first_name} {hod.last_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group col-md-6">
                            <label>Show in Expense</label>
                            <input
                              type="radio"
                              value="1"
                              checked={showInExpense === "1"}
                              onChange={handleExpenseChange}
                            />
                            Yes
                            <input
                              type="radio"
                              value="2"
                              checked={showInExpense === "2"}
                              onChange={handleExpenseChange}
                            />
                            No
                          </div>
                          <div className="form-group col-md-6">
                            <label>Show in Todo</label>
                            <input
                              type="radio"
                              value="1"
                              checked={showInTodo === "1"}
                              onChange={handleTodoChange}
                            />
                            Yes
                            <input
                              type="radio"
                              value="2"
                              checked={showInTodo === "2"}
                              onChange={handleTodoChange}
                            />
                            No
                          </div>
                        </div>

                        <div className="row mt-3">
                          <div className="form-group mb-0 col-md-2">
                            <button type="submit" className="btn">
                              Submit
                            </button>
                          </div>
                          <div className="form-group mb-0 col-md-4">
                            <button
                              type="button"
                              onClick={goBack}
                              className="btn"
                            >
                              Back
                            </button>
                          </div>
                        </div>
                      </fieldset>
                    </form>
                  ) : (
                    <Loader
                      type="spinner-default"
                      bgColor={"#000000"}
                      title={"Please wait"}
                      color={"#000000"}
                      size={50}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default editProject;
