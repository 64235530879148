const URL = process.env.REACT_APP_API_URL;
const application_url = "https://webapp.zaidexceldesign.com";
module.exports = {
    FileUrl : `${application_url}/public`,
    SvgUrl : `${application_url}/public`,
    host_name : `${application_url}`,
    
    defaultuserpic:"default/DefaultImage.png",
    zaidPdfHeaderPic:'img/logo_dark.png',
    zaidPdfFooterPic:'img/Letterhead(Bottom).jpg',
    zaidPdfHeaderBorder:'img/HeaderBorder.png',

    closeButtonSvg:`${application_url}/svgs/close.svg`,
    eyeButtonSvg:`${application_url}/svgs/eye.svg`,
    dustbinButton:`${application_url}/svgs/dustbin.svg`,
    siteinspect :`${application_url}/svgs/siteinspect.svg`

}