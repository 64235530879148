import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  memo,
  useRef,
} from "react";
import {
  datatableHeight,
  paginationRows,
} from "../../../config/CommonVariables";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  exportToCsv,
  loadingTemplate,
  notFoundTemplate,
  actionButtonObject,
  ActionButtonJsx,
} from "../../../services/FormCommon";
import { SwalAlert } from "../../../UtilsComponent/SwalDialog";
import toast from "react-hot-toast";
import { Link, useParams, useNavigate } from "react-router-dom";
import ApiService from "../../../services/ApiService";
import {
  deleteFileExpense,
  deleteFileExpenseForAdmin,
  generatePdfOfTeamExpense,
  getDepositExpenseByTeamId,
  getFileExpenseByFileId,
  getDepositExpenseByTeamIdForPdf,
} from "../../../config/config";
import { GetDate, todayDate } from "../../../services/DateAndTimeService";
import {
  DepositExpenseRoute,
  EmpRoute,
  FileExpenseRoute,
  OfficeExpenseRoute,
} from "../../../config/RouteConfig";
//import { getToken } from '../../../services/useLocalStorage';
import { useForm } from "react-hook-form";
import { FileUrl } from "../../../config/file_url";
import {
  useSortBy,
  useTable,
  usePagination,
  useGlobalFilter,
  useRowSelect,
} from "react-table";
import { MyLoader } from "../../../UtilsComponent/MyLoader";
import {
  getRole,
  getToken,
  getUserRoles,
  isEmp,
  shouldAllowedOfRoles,
  superAccess,
  Cordinator,
} from "../../../services/useLocalStorage";
import { checkFileExpense } from "../../../config/config";
import {
  adminKey,
  empKey,
  cordinator,
  subadmin,
} from "../../../config/RoleConfig";

const TeamExpenseReport = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [expenses, setExpenses] = useState([]);
  const [extra_expenses, setExtraExpenses] = useState([]);

  const header = {
    Authorization: `Bearer ${getToken()}`,
    ["Content-Type"]: "application/json",
  };

  const [dateRange, setDateRange] = useState({ start_date: "", end_date: "" });

  const [additionalInfo, setAdditionalInfo] = useState({
    team: "",
    total_deposit: 0,
    total_expense: 0,
    total_balance: 0,
  });

  const [loading, setLoading] = useState(false);

  const path = localStorage.getItem("wallet");

  const hname = {
    type_id: "type_id",
    type: "Type",
    date: "Date",
    amount: "Amount",
    description: "Description",
    file: "File",
    is_approved: "Status",
    action: "action",
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "id",
        accessor: "id",
      },
      {
        Header: "type_id",
        accessor: `${hname.type_id}`,
      },
      {
        Header: "Type",
        accessor: `${hname.type}`,
      },
      {
        Header: "Date",
        accessor: `${hname.date}`,
      },
      {
        Header: "File",
        accessor: `${hname.file}`,
      },
      {
        Header: "Amount",
        accessor: `${hname.amount}`,
      },
      {
        Header: "Description",
        accessor: `${hname.description}`,
      },
      // {
      //     Header: 'Team',
      //     accessor: `${hname.team_fullname}`
      // },
      //   {
      //       Header: 'Status',
      //       accessor: `${hname.status}`
      //   },

      {
        Header: "Status",
        accessor: `${hname.status}`,
        Cell: ({ row }) => {
          const [status, setStatus] = useState(row.original[hname.status]);

          const updateStatusInDatabase = async (id, data) => {
            console.log("data===>", data);
            try {
              const header = {
                Authorization: `Bearer ${getToken()}`,
                ["Content-Type"]: "application/json",
              };
              // console.log("data2===>", data);
              // console.log("id2-=-=->", id);

              const res = await ApiService(
                { is_approved: data, id: parseInt(id) },
                // parseInt(id) ,

                0,
                header,
                checkFileExpense.method,
                checkFileExpense.url
              );

              if (!res?.data?.error) {
                toast.success(res?.data?.message);
                //  navigate(path);
                console.log("res141---->", res);
              } else {
                toast.error(res?.data?.message);
                //  navigate(path);
                console.log("res548----->", res);
              }
            } catch (err) {
              toast.error(
                err?.response?.data?.message
                  ? err?.response?.data?.message
                  : "Something went wrong"
              );
              console.log(err);
            }
          };

          const handleStatusChange = (event, id) => {
            const newStatus = event.target.value;
            setStatus(newStatus);
            // const id = row.original.id;

            updateStatusInDatabase(id, newStatus);
            console.log("newStatus===>", newStatus);
            console.log("id1--->", id);
          };
          if (superAccess([adminKey, subadmin])) {
            return (
              <select
                name="is_approved"
                id="is_approved"
                value={status}
                onChange={(event) => handleStatusChange(event, row.original.id)}
                className="form-control"
                htmlFor="is_approved"
              >
                <option value={"0"}>Pending</option>
                <option value={"1"}>Approved</option>
                <option value={"2"}>Rejected</option>
              </select>
            );
          } else {
            const stat =
              status === 1 ? "Approved" : status === 0 ? "Pending" : "Rejected";
            return <span>{stat}</span>;
          }
        },
      },
    ],
    []
  );

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "Edit",
        Header: "",
        Cell: ({ row }) => (
          <React.Fragment>
            {/* <button id={`${row?.values?.id}_click`} onClick={()=>alert("Okay")}>Test</button> */}
            <div className="dropdown">
              <button
                className="btn btn-secondary btn_action_menu"
                id={`${row?.value?.id}_click`}
                type="button"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <b>
                  <ActionButtonJsx />
                </b>
              </button>
              <div className="dropdown-menu">
                {getActionRender(row?.values?.type_id, row?.values?.id)}
              </div>
            </div>
          </React.Fragment>
        ),
      },
    ]);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    page,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data: expenses,
      initialState: {
        hiddenColumns: ["id", "type_id"],
        pageIndex: 0,
        pageSize: 10,
        sortBy: [{ id: "date", desc: true }], // Sort by "date" column in descending order
      },
    },
    tableHooks,
    useGlobalFilter,
    useSortBy,
    usePagination
    // useRowSelect, // Add useRowSelect hook
    // hooks => {
    //   hooks.visibleColumns.push(columns => [
    //     // Add a checkbox column
    //     {
    //       id: 'selection',
    //       Header: ({ getToggleAllRowsSelectedProps }) => (
    //         <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
    //       ),
    //       Cell: ({ row }) => (
    //         <div>
    //           <input
    //             type="checkbox"
    //             {...row.getToggleRowSelectedProps()}
    //           />
    //         </div>
    //       ),
    //     },
    //     ...columns,
    //   ]);
    // }
  );

  // console.log("expense===>",expenses)

  const clearFilters = useCallback(() => {
    setDateRange({ start_date: "", end_date: "" });
    setExpenses((prevState) => [...extra_expenses]);
  }, []);

  const handleButtonClick = () => {
    localStorage.setItem("expanse", "expanse");
  };
  const handleFiles = () => {
    localStorage.setItem("files", "files");
  };

  const handlePreview = () => {
    localStorage.setItem("preview", "preview");
  };

  const getActionRender = (expense_type, id) => {
    let dom = <></>;

    if (superAccess()) {
      switch (expense_type) {
        case 1:
          dom = (
            <>
              <Link
                className="dropdown-item"
                to={`/${DepositExpenseRoute.edit}/${id}`}
                onClick={handleButtonClick}
              >
                {actionButtonObject.edit}
              </Link>
            </>
          );
          break;
        case 2:
          dom = (
            <>
              <Link
                className="dropdown-item"
                to={`/${FileExpenseRoute.preview}/${id}`}
                onClick={handlePreview}
              >
                {actionButtonObject.preview_expense}
              </Link>
              <Link
                className="dropdown-item"
                to={`/${FileExpenseRoute.edit}/${id}`}
                onClick={handleFiles}
              >
                {actionButtonObject.edit}
              </Link>
              <a
                className="dropdown-item"
                role={"button"}
                onClick={() => onRemoveExpense(id)}
              >
                {actionButtonObject.delete}
              </a>
            </>
          );
          break;
        case 3:
          dom = (
            <>
              <Link
                className="dropdown-item"
                to={`/${OfficeExpenseRoute.preview}/${id}`}
              >
                {actionButtonObject.preview_expense}
              </Link>
              <Link
                className="dropdown-item"
                to={`/${OfficeExpenseRoute.edit}/${id}`}
              >
                {actionButtonObject.edit}
              </Link>
              <a
                className="dropdown-item"
                role={"button"}
                onClick={() => onRemoveExpense(id)}
              >
                {actionButtonObject.delete}
              </a>
            </>
          );
          break;
        default:
          dom = <></>;
      }
    }
    return dom;
  };

  const getExpenseTypeInWord = (num) => {
    let str = "";
    switch (num) {
      case 1:
        str = "Deposit";
        break;
      case 2:
        str = "File Expense";
        break;
      case 3:
        str = "Office Expense";
        break;
      default:
        str = "";
    }
    return str;
  };

  const isNotNullOrNotUndefined = (value = "") => {
    try {
      return !(
        typeof value == "undefined" ||
        value == null ||
        value.length == 0
      );
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const fetchFileExpenseByFileId = useCallback(async () => {
    try {
      setLoading(true);
      const res = await ApiService(
        null,
        params?.id,
        header,
        getDepositExpenseByTeamId.method,
        getDepositExpenseByTeamId.url
      );
      if (!res?.data?.error) {
        setLoading(false);

        const payload = res?.data?.data;

        setAdditionalInfo({
          team: isNotNullOrNotUndefined(res?.data?.team_data[0]?.team_fullname)
            ? res?.data?.team_data[0]?.team_fullname
            : "",
          total_deposit: isNotNullOrNotUndefined(res?.data?.total_deposit)
            ? res?.data?.total_deposit
            : 0,
          total_expense: isNotNullOrNotUndefined(res?.data?.total_expense)
            ? res?.data?.total_expense
            : 0,
          total_balance:
            isNotNullOrNotUndefined(res?.data?.total_expense) ||
            isNotNullOrNotUndefined(res?.data?.total_deposit)
              ? res?.data?.total_deposit - res?.data?.total_expense
              : 0,
        });

        if (Array.isArray(payload) && payload.length > 0) {
          const rows = [];

          payload.forEach((element) => {
            rows.push({
              id: element?.id,
              [hname.type_id]: parseInt(element?.expense_type),
              [hname.type]: getExpenseTypeInWord(element?.expense_type),
              [hname.date]: element?.date
                ? GetDate(element?.date, "DD-MM-YYYY")
                : "-",
              [hname.file]: element?.file_name,
              [hname.amount]:
                getExpenseTypeInWord(element?.expense_type) == "Deposit" ? (
                  <span class="text-success">{element?.amount}</span>
                ) : (
                  <span class="text-danger">{element?.amount}</span>
                ),
              [hname.description]: element?.description,
              [hname.status]: element?.is_approved,
              // element?.is_approved == 1
              //   ? "Approved"
              //   : element?.is_approved == 0
              //   ? "Pending"
              //   : "Rejected",
              [hname.action]: element,
            });
          });

          setExpenses(rows);
          setExtraExpenses(rows);
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);

      console.log(err);

      toast.error(
        err?.response?.data?.message
          ? err?.response?.data?.message
          : "Something went wrong"
      );
    }
  }, []);

  useEffect(() => {
    fetchFileExpenseByFileId();
  }, []);

  const removeExpense = async (id) => {
    try {
      setLoading(true);

      const res = await ApiService(
        null,
        parseInt(id),
        header,
        deleteFileExpenseForAdmin.method,
        deleteFileExpenseForAdmin.url
      );
      if (!res?.data?.error) {
        setLoading(false);

        toast.success(res?.data?.message);
        setList(id);
      } else {
        setLoading(false);

        toast.error(res?.data?.message);
      }
    } catch (err) {
      setLoading(false);

      console.log(err);
    }
  };

  const onRemoveExpense = (id) => {
    SwalAlert({})
      .then(async (result) => {
        if (result?.isConfirmed) {
          if (parseInt(id) != NaN) {
            await removeExpense(id);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setList = (id) => {
    setExpenses((prevState) => {
      return prevState.filter((v) => {
        return v?.id != id;
      });
    });

    setExtraExpenses((prevState) => {
      return prevState.filter((v) => {
        return v?.id != id;
      });
    });
  };

  const redirectToPdfPage = () => {
    let urlLink = `https://api.zaidexceldesign.com/php/index.php?emp_id=${params?.id}`;
    // `/${EmpRoute.deposit_expense_report_pdf}/${params?.id}`;

    if (dateRange.start_date && dateRange.end_date) {
      urlLink = `${urlLink}&start_date=${dateRange.start_date}&end_date=${dateRange.end_date}`;
      window.open(urlLink);
      // console.log("urlK===>",urlLink)
    } else {
      window.open(urlLink);
    }
  };

  const getDataByDateRange = async (start_date, end_date) => {
    // let urlString = `https://api.zaidexceldesign.com/php/index.php?emp_id=${params?.id}`;
    let urlString = `${getDepositExpenseByTeamIdForPdf.url}/${params?.id}`;
    urlString = `${urlString}?start_date=${start_date}&end_date=${end_date}`;

    setLoading(true);

    const res = await ApiService(
      null,
      0,
      header,
      getDepositExpenseByTeamIdForPdf.method,
      urlString
    );

    if (!res?.data?.error) {
      setLoading(false);

      const payload = res?.data?.data;

      if (Array.isArray(payload) && payload.length > 0) {
        const rows = [];

        payload.forEach((element) => {
          rows.push({
            id: element?.id,
            [hname.type_id]: parseInt(element?.expense_type),
            [hname.type]: getExpenseTypeInWord(element?.expense_type),
            [hname.date]: element?.date
              ? GetDate(element?.date, "DD-MM-YYYY")
              : "-",
            [hname.file]: element?.file_name,
            [hname.amount]: element?.amount,
            [hname.description]: element?.description,
            [hname.status]: element.is_approved,
            // element?.is_approved == 1
            //   ? "Approved"
            //   : element?.is_approved == 0
            //   ? "Pending"
            //   : "Rejected",
            [hname.action]: element,
          });
        });

        setExpenses(rows);
      } else {
        setExpenses([]);
      }
    } else {
      setLoading(false);

      setExpenses([]);
    }
  };

  useEffect(() => {
    if (dateRange.start_date && dateRange.end_date) {
      getDataByDateRange(dateRange.start_date, dateRange.end_date);
    } else {
      if (!dateRange.start_date && !dateRange.end_date) {
        setExpenses((prevState) => [...extra_expenses]);
      }
    }
  }, [dateRange]);
  console.log("getDataByDateRange===>", expenses);

  return (
    <div className="content__wrapper">
      <section className="page-content">
        <div className="page-title mobile-title">
          <h1 className="h4 mb-0">Deposit Expense Report</h1>
          {/*<p className="mb-4">Online Process</p>*/}
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="zed__table card">
              <h5 className="text-shadow-yellow px-2 mb-4">
                Deposit Expense Report
              </h5>
              {superAccess([adminKey, subadmin]) && (
                <div className="form-row">
                  <div className="col">
                    <div className="form-group">
                      <input
                        type="date"
                        name="start_date"
                        id="start_date"
                        onChange={(e) =>
                          setDateRange((p) => ({
                            ...p,
                            start_date: e.target.value,
                          }))
                        }
                        value={dateRange.start_date}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <input
                        type="date"
                        name="end_date"
                        id="end_date"
                        onChange={(e) =>
                          setDateRange((p) => ({
                            ...p,
                            end_date: e.target.value,
                          }))
                        }
                        value={dateRange.end_date}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      {expenses.length > 0 ? (
                        <button className="btn" onClick={redirectToPdfPage}>
                          TO PDF
                        </button>
                      ) : (
                        <button className="btn" disabled>
                          TO PDF
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group reset_btn text-right">
                      <button className="btn" onClick={clearFilters}>
                        <img
                          src={`${FileUrl}/img/icons/refresh.svg`}
                          className="in__svg pr-2"
                          alt="project"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <div className="row diposite_row">
                <div className="col-lg-3">
                  <div className="diposite_box">
                    <h6>Team : </h6>
                    <p>{additionalInfo.team}</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="diposite_box">
                    <h6>Total Deposit : </h6>
                    <p>{additionalInfo.total_deposit}</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="diposite_box">
                    <h6>Total Expense :</h6>
                    <p>{additionalInfo.total_expense}</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="diposite_box">
                    <h6>Total Balance : </h6>
                    <p>{additionalInfo.total_balance}</p>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                {loading ? (
                  <MyLoader value={false} />
                ) : (
                  <table
                    className="table datatable diposit_table"
                    {...getTableProps()}
                  >
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render("Header")}
                              <span>
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? " 🔽"
                                    : " 🔼"
                                  : ""}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {/* {rows.map(row => { */}
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <td colSpan={7}>
                        <div className="pagination justify-content-end align-items-center">
                          <button
                            className="pagination_button"
                            onClick={() => gotoPage(0)}
                            disabled={!canPreviousPage}
                          >
                            {"<<"}
                          </button>
                          <button
                            className="pagination_button"
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                          >
                            {"<"}
                          </button>
                          <span>
                            Page{" "}
                            <strong>
                              {pageIndex + 1} of {pageOptions.length}
                            </strong>
                          </span>
                          <button
                            className="pagination_button"
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                          >
                            {">"}
                          </button>
                          <button
                            className="pagination_button"
                            onClick={() => gotoPage(pageCount - 1)}
                            disabled={!canNextPage}
                          >
                            {">>"}
                          </button>
                        </div>
                      </td>
                    </tfoot>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TeamExpenseReport;

const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <span>
      <input
        className="form-control"
        type={"search"}
        style={{ margin: "5px" }}
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        placeholder="Search"
      />
    </span>
  );
};
