import React,{useEffect,useState} from 'react';
import { useNavigate,useParams } from 'react-router-dom';
import { GetDate, todayDate } from '../../../services/DateAndTimeService';
import { getFileExpenseById, updateFileExpense } from '../../../services/FileExpenseService';
import { getRole, getToken } from '../../../services/useLocalStorage';
import useValidator from '../../../services/Validation';
import { GetFile } from '../../../services/FileService';
import { FileUrl } from '../../../config/file_url';
import Loader from 'react-js-loader';
import toast from 'react-hot-toast';
import ApiService from '../../../services/ApiService';
import { fetchFileExpenseById, updateFileExpenseById, updateFileExpenseByIdForAdmin } from '../../../config/config';
import { FileExpenseRoute } from '../../../config/RouteConfig';
import { adminKey } from '../../../config/RoleConfig';

//Expense
const Edit = () => {
    const navigate = useNavigate();  
    const params = useParams();
  
    const initialExpValue = {
      amount : "",
      mst_file_id : "",
      description :"",
      date:'',
      receipt_image:"",
      submitters_img1: "",
      submitters_img2:"",
      submitters_img3:""
    }

    const initialImageUrls = {
        receipt_image : "",
        submitters_img1 : "",
        submitters_img2 : "",
        submitters_img3 : "",
    }

    const [expense,setExpense] = useState(()=>initialExpValue);
    const [file, setFile] = useState([]);
    const [ImageUrls,setImageUrls] = useState(()=>initialImageUrls);
    const [loading,setLoading] = useState(false);

    const [validator, showValidationMessage] = useValidator();

    const handleButtonClick = () =>{
        const files = localStorage.getItem("files");
        const path = localStorage.getItem("wallet");

        if(files && path){
            localStorage.removeItem("files");
            navigate(path);
        }else{
            navigate("/file-expenses");
        }
    }

    const fetchFiles = async () => {
        try{
            setLoading(true);
            const res = await GetFile(`${getToken()}`);
            if(!res.data.error){
                setLoading(false);
                setFile(res.data.data);
            }else{
                setLoading(false);
                toast.error(res.data.message);
                navigate(`/${FileExpenseRoute.list}`);

            }
        }catch(err){
            setLoading(false);
            console.log(err);
            if(err.response.data.error){
                toast.error(err.response.data.message);
                navigate(`/${FileExpenseRoute.list}`);
            }
        }
    };

    const fetchFileExpense = async () =>{
        try{
            setLoading(true);
            // const res = await getFileExpenseById(parseInt(params.id),`${getToken()}`);
            const header = {Authorization:`Bearer ${getToken()}`,['Content-Type']:'application/json'}
            const res =  await ApiService(null,parseInt(params?.id),header,fetchFileExpenseById.method,fetchFileExpenseById.url);
            if(!res.data.error){
                setLoading(false);
                const data = res.data.data[0];
                console.log(data);
                setExpense((prevState)=>{
                    return {
                        ...prevState,
                        amount : data.amount,
                        mst_file_id : data.mst_file_id,
                        description :data.description,
                        date:GetDate(data.date,"YYYY-MM-DD") ? GetDate(data.date,"YYYY-MM-DD") : todayDate()
                    }
                });

                setImageUrls({
                    receipt_image : (data.receipt_image && data.receipt_image!="null")?data.receipt_image:"",
                    submitters_img1 : (data.submitters_img1 && data.submitters_img1!="null")?data.submitters_img1:"",
                    submitters_img2 : (data.submitters_img2 && data.submitters_img2!="null")?data.submitters_img2:"",
                    submitters_img3 : (data.submitters_img3 && data.submitters_img3!="null")?data.submitters_img3:""
                });
                
            }else{
                setLoading(false);
                toast.error(res.data.message);
                navigate(`/${FileExpenseRoute.list}`);

            }
        }catch(err){
            setLoading(false);
            console.log(err);
            if(err.response.data.error){
                toast.error(err.response.data.message);
                navigate(`/${FileExpenseRoute.list}`);
            }
        }
    }

    useEffect(() => {
        fetchFiles();
        fetchFileExpense();
    }, []); 

    const goBack = () =>{
        navigate(`/${FileExpenseRoute.list}`);
    }
    
    const handleInput = (e)=>{
    
        const {name,value} = e.target;
    
        showValidationMessage(true);
    
        setExpense((prevState)=>{
            return {...prevState,[name]:value}
        });
    }

    const onFileInput = (e) =>{
        const file = e.target.files[0];
        const name = e.target.name;
        setExpense((prevState)=>{
            return {...prevState,[name]:file}
        });
    }


    const handleSubmit = async (e) =>{
        e.preventDefault();
    
        console.log("it is hit now")
        const formData = new FormData();
    
        for(const key of Object.keys(expense))
        {
            formData.append(key,expense[key]);
        }

        formData.append("id",parseInt(params.id));
        
        for(var pair of formData.entries())
        {
            console.log(pair[0],pair[1]);
        }
    
        
        try
        {
            setLoading(true);
            const header = {Authorization:`Bearer ${getToken()}`,['Content-Type']:'multipart/form-data'}
            var updateFileObject = {...updateFileExpenseById};

            if(getRole()==adminKey)
            {
                updateFileObject = {...updateFileExpenseByIdForAdmin};
            }

            const res = await ApiService(formData,0,header,updateFileObject.method,updateFileObject.url);
            if(!res.data?.error)
            {
                toast.success(res.data?.message);
                setLoading(false);
                navigate(`/${FileExpenseRoute.list}`);
            }
            else
            {
                setLoading(false);
                toast.error(res.data?.message);
                navigate(`/${FileExpenseRoute.list}`);
            }
        }
        catch(err){
            setLoading(false);
            console.log(err);
            toast.error(err.response.data?.message);
        }
    
    }
    


    return (
        <div className="content__wrapper">
            <section className="page-content">
                <div className="page-title mobile-title">
                    <h1 className="h4 mb-0">Edit File</h1>
                    <p className="mb-4">Expense</p>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card p-5">
                            <div className="card-header bg-transparent border-0 mb-5 p-0">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-md-6">
                                        <h6 className="title-line text-shadow-yellow mb-0 pb-3">Edit File Expense</h6>
                                    </div>
                                </div>
                            </div>
                             
                            <div className="card-body p-0">
                            {!loading?
                        <form onSubmit={handleSubmit} className="padding_space">
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="description">Description</label>
                                    <textarea name="description" className='form-control' onChange={handleInput} value={expense.description}></textarea>
                                    {validator.message("description",expense.description,"required",{className:"text-danger"})}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="amount">Amount*</label>
                                    <input type="text" name="amount" className='form-control' id="amount" onChange={handleInput} value={expense.amount} placeholder='Amount'  />
                                    {validator.message("amount", expense.amount, "required|numeric", { className: 'text-danger' })}
                                </div>
                                <div className="col">
                                    <label htmlFor="filename">Date*</label>
                                    <input type="date" name="date" className='form-control' onChange={handleInput} id="date" value={expense.date}/>
                                    {validator.message("date",expense.date,"required",{className:"text-danger"})}
                                </div>
                                <div className="col">
                                    <label htmlFor="filename">File Name</label>
                                    <select name='mst_file_id' id="mst_file_id" className='form-control' onChange={handleInput} value={expense.mst_file_id}>
                                            <option value="">Select File Name</option>
                                            {file.map((file)=>(
                                                <option value={file.id}>{file.file_name}</option>
                                            ))}
                                    </select>
                                    {validator.message("File", expense.mst_file_id, "required", { className: 'text-danger' })}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-9">
                                    <label htmlFor="receipt">Add Receipt*</label>
                                    <input type="file" name="receipt_image" className='form-control' style={{height:'auto'}} onChange={onFileInput} id="receipt_image"/>
                                    <span className='text-danger'></span>
                                </div>
                                <div className="col-md-3 mt-5">
                                    {ImageUrls.receipt_image && 
                                       <a href={`${FileUrl}/${ImageUrls.receipt_image}`} className='text-success display-5' target="_blank"><i class="fa fa-download" aria-hidden="true"></i></a> 
                                    }
                                </div>
                            </div>
    
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="submitters_img1">Add Submitters Image 1</label>
                                    <input type='file' name="submitters_img1" id="submitters_img1" style={{height:'auto'}} onChange={onFileInput} className='form-control'></input>
                                    <span className='text-danger'></span>
                                </div>
                                <div className="col mt-5">
                                    {ImageUrls.submitters_img1 && 
                                       <a href={`${FileUrl}/${ImageUrls.submitters_img1}`} className='text-success display-5' target="_blank"><i class="fa fa-download" aria-hidden="true"></i></a> 
                                    }
                                </div>
                                <div className="col">
                                    <label htmlFor="submitters_img2">Add Submitters Image 2</label>
                                    <input type='file' name="submitters_img2" id="submitters_img2" style={{height:'auto'}} onChange={onFileInput} className='form-control'></input>
                                    <span className='text-danger'></span>
                                </div>
                                <div className="col mt-5">
                                    {ImageUrls.submitters_img2 && 
                                       <a href={`${FileUrl}/${ImageUrls.submitters_img2}`} className='text-success display-5' target="_blank"><i class="fa fa-download" aria-hidden="true"></i></a> 
                                    }
                                </div>
                                <div className="col">
                                    <label htmlFor="submitters_img3">Add Submitters Image 3</label>
                                    <input type='file' name="submitters_img3" id="submitters_img3" style={{height:'auto'}} onChange={onFileInput} className='form-control'></input>
                                    <span className='text-danger'></span>
                                </div>
                                <div className="col mt-5">
                                    {ImageUrls.submitters_img3 && 
                                       <a href={`${FileUrl}/${ImageUrls.submitters_img3}`} className='text-success display-5' target="_blank"><i class="fa fa-download" aria-hidden="true"></i></a> 
                                    }
                                </div>
                            </div>
    
                            <div className="row mt-3">
                                <div className="form-group mb-0 col-md-2">
                                    <button type="submit" className="btn" >Submit</button>
                                </div>
                                <div className="form-group mb-0 col-md-4">
                                    <button type="button" onClick={handleButtonClick} className="btn">Back</button>
                                </div>            
                            </div>
    
                        </form>
                    :<Loader type="spinner-default" bgColor={"#000000"} title={"Please wait"} color={'#000000'} size={50}/>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
      )

}

export default Edit;